import React, { useState, useContext } from "react";
import { Layout, Grid, Avatar, Typography } from "antd";
import { MenuOutlined, UserOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import RoleSwitching from "../components/RoleSwitching";
import { isPermitted } from "../utils/PermissionManager";
import { useLocation } from "react-router";
const { Header } = Layout;
const { Text } = Typography;

export default function Navbar(props) {
  const { settrigger, setshow } = props.state;
  const profile = localStorage.getItem("profile");
  let profileData = profile ? JSON.parse(profile) : null;
  const roles = useSelector((state) => state.roles.roles);
  const location = useLocation();

  return (
    <>
      {location.pathname !== "/login" && (
        <Header
          style={{
            background: "#edd48f",
            padding: "0px 2rem",
            position: "fixed",
            width: "100%",
            boxShadow: "0 1px 16px rgba(0,0,0,.1)",
            borderBottom: "1px solid rgba(0,0,0,0.08)",
            zIndex: "1",
            height: "60px",
            color: "white",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: "100%",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {true && (
                <MenuOutlined
                  style={{
                    fontSize: "18px",
                    padding: "0.5rem",
                    borderRadius: "2px",
                    color: "black",
                    marginRight: "0.5rem",
                  }}
                  onClick={() => {
                    setshow((state) => !state);
                    settrigger((state) => !state);
                  }}
                />
              )}
              <a
                href="/"
                style={{
                  height: "40px",
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <img
                  src={require("../assets/wholesale_logo.svg").default}
                  style={{ height: "28px" }}
                  alt=""
                />
                {/* <span
                  style={{
                    margin: 0,
                    textTransform: "capitalize",
                    lineHeight: "16px",
                    color: "#000",
                    fontSize: "13px",
                  }}
                >
                  {getPriorityRole()}
                </span> */}
              </a>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div className="hidePhone">
                {isPermitted(roles, ["admin", "switching"]) && (
                  <RoleSwitching />
                )}
              </div>
              <Link to="/profile" className="logout">
                <Avatar
                  style={{ backgroundColor: "#444", cursor: "pointer" }}
                  icon={<UserOutlined />}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    marginLeft: "0.5rem",
                  }}
                >
                  <Text style={{ lineHeight: "18px", fontWeight: "bold" }}>
                    {profileData && profileData.name}
                  </Text>
                  <Text style={{ lineHeight: "18px", fontSize: "12px" }}>
                    {profileData && profileData.phone}
                  </Text>
                </div>
              </Link>
            </div>
          </div>
        </Header>
      )}
    </>
  );
}
