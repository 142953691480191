export const status = [
  {
    id: 1,
    name: "Pending Payment",
    description: "Pending Payment",
    created_at: null,
    updated_at: null,
  },
  {
    id: 2,
    name: "Partially Paid",
    description: "Partially Paid",
    created_at: null,
    updated_at: null,
    role: ["accounts"],
  },
  {
    id: 3,
    name: "Paid to Supplier",
    description: "Paid to Supplier",
    created_at: null,
    updated_at: null,
    role: ["purchasing"],
  },
  {
    id: 4,
    name: "Purchasing",
    description: "Purchasing",
    created_at: null,
    updated_at: null,
    role: ["purchasing"],
  },
  {
    id: 5,
    name: "Purchase Completed",
    description: "Purchase Completed",
    created_at: null,
    updated_at: null,
  },
  {
    id: 27,
    name: "AGL Purchase Completed",
    description: "AGL Purchase Completed",
    created_at: null,
    updated_at: null,
  },
  {
    id: 6,
    name: "Need Repurchase",
    description: "Need Repurchase",
    created_at: null,
    updated_at: null,
    role: ["purchasing", "tracking"],
  },
  {
    id: 7,
    name: "Received in China Warehouse ",
    description: "Received in China Warehouse ",
    created_at: null,
    updated_at: null,
  },
  {
    id: 8,
    name: "On the Way to China Seaport",
    description: "On the Way to China Seaport",
    created_at: null,
    updated_at: null,
  },
  {
    id: 9,
    name: "On the Way to China Airport",
    description: "On the Way to China Airport",
    created_at: null,
    updated_at: null,
  },
  {
    id: 10,
    name: "Received in China Seaport",
    description: "Received in China Seaport",
    created_at: null,
    updated_at: null,
  },
  {
    id: 11,
    name: "Received in China Airport",
    description: "Received in China Airport",
    created_at: null,
    updated_at: null,
  },
  {
    id: 12,
    name: "On the Way to Bd Seaport",
    description: "On the Way to Bd Seaport",
    created_at: null,
    updated_at: null,
  },
  {
    id: 13,
    name: "On the Way to Bd Airport",
    description: "On the Way to Bd Airport",
    created_at: null,
    updated_at: null,
  },
  {
    id: 14,
    name: "Received in Bd Seaport",
    description: "Received in Bd Seaport",
    created_at: null,
    updated_at: null,
  },
  {
    id: 15,
    name: "Received in Bd Airport",
    description: "Received in Bd Airport",
    created_at: null,
    updated_at: null,
  },
  {
    id: 16,
    name: "On the Way to Bd Warehouse",
    description: "On the Way to Bd Warehouse",
    created_at: null,
    updated_at: null,
  },
  {
    id: 17,
    name: "Received in Sorting Hub",
    description: "Received in Sorting Hub",
    created_at: null,
    updated_at: null,
  },
  {
    id: 18,
    name: "Collect From Bd Warehouse",
    description: "Collect From Bd Warehouse",
    created_at: null,
    updated_at: null,
  },
  {
    id: 32,
    name: "Waiting for customer response",
    description: "Waiting for customer response",
    created_at: null,
    updated_at: null,
  },
  {
    id: 19,
    name: "Processing for Delivery",
    description: "Processing for Delivery",
    created_at: null,
    updated_at: null,
  },

  {
    id: 20,
    name: "On the Way to Delivery",
    description: "On the Way to Delivery",
    created_at: null,
    updated_at: null,
  },
  {
    id: 21,
    name: "Completed",
    description: "Completed",
    created_at: null,
    updated_at: null,
    role: ["accounts"],
  },
  {
    id: 22,
    name: "Processing for Refund",
    description: "Processing for Refund",
    created_at: null,
    updated_at: null,
    role: ["purchasing", "accounts"],
  },
  {
    id: 23,
    name: "Refunded",
    description: "Refunded",
    created_at: null,
    updated_at: null,
    role: ["accounts"],
  },
  {
    id: 24,
    name: "Cancelled",
    description: "Cancelled",
    created_at: null,
    updated_at: null,
    role: ["accounts"],
  },
  {
    id: 25,
    name: "Waiting for Seller Response",
    description: "Waiting for Seller Response",
    created_at: null,
    updated_at: null,
    role: ["tracking"],
  },
  {
    id: 26,
    name: "Customer Declined to Receive Product",
    description: "Customer Declined to Receive Product",
    created_at: null,
    updated_at: null,
    role: ["tracking", "delivery"],
  },
  {
    id: 32,
    name: "Waiting for Customer Response",
    description: "Waiting for Customer Response",
    created_at: null,
    updated_at: null,
    role: ["tracking", "delivery"],
  },
];
