import { Tag } from "antd";
import Text from "antd/lib/typography/Text";
import React from "react";

export default function CustomerBadge({ name, badge, id, column }) {
  let colors = {
    new: "#9e1068",
    priority: "#a8071a",
    joined: "#006d75",
    disconnected: "#d48806",
    regular: "#237804",
  };
  return (
    <div>
      {id ? (
        <a href={`/customer/${id}`}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: column ? "column" : "row",
            }}
          >
            <Text
              style={{
                marginRight: "0.5rem",
                textTransform: "capitalize",
                textAlign: "center",
                marginBottom: column ? "0.25rem" : 0,
              }}
            >
              {name}
            </Text>
            {badge && (
              <Tag
                style={{
                  padding: "2px 6px",
                  background: colors[badge] ? colors[badge] : "#666",
                  color: "white",
                  fontSize: 10,
                  lineHeight: "15px",
                  textTransform: "capitalize",
                }}
              >
                {badge}
              </Tag>
            )}
          </div>
        </a>
      ) : (
        <div>
          <Text style={{ marginRight: "0.5rem" }}>{name}</Text>
          {badge && (
            <Tag
              style={{
                padding: "2px 6px",
                background: colors[badge] ? colors[badge] : "#666",
                color: "white",
                fontSize: 10,
                lineHeight: "15px",
                textTransform: "capitalize",
              }}
            >
              {badge}
            </Tag>
          )}
        </div>
      )}
    </div>
  );
}
