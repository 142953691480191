import React from "react";
import { Layout, Menu } from "antd";
import {
  HomeFilled,
  ClockCircleOutlined,
  DingdingOutlined,
  CheckCircleOutlined,
  EnvironmentOutlined,
  LogoutOutlined,
  GoldOutlined,
  UserOutlined,
  TagsOutlined,
  TeamOutlined,
  WalletOutlined,
  MoneyCollectOutlined,
  DotChartOutlined,
  ContainerOutlined,
  CarOutlined,
  DollarCircleOutlined,
  CrownOutlined,
  ApartmentOutlined,
  ApiOutlined,
  ExceptionOutlined,
  GiftOutlined,
  DatabaseOutlined,
  WindowsOutlined,
  UsergroupAddOutlined,
  AreaChartOutlined,
  UngroupOutlined,
  Loading3QuartersOutlined,
  ShoppingCartOutlined,
  CustomerServiceOutlined,
  DeploymentUnitOutlined,
  OneToOneOutlined,
  CodepenOutlined,
  StockOutlined,
  FileZipOutlined,
  AntDesignOutlined,
  ExportOutlined,
  FallOutlined,
  PhoneOutlined,
  SettingOutlined,
  SlidersOutlined,
  AlignCenterOutlined,
  NodeIndexOutlined,
  RocketOutlined,
  BorderlessTableOutlined,
  UserSwitchOutlined,
  AuditOutlined,
  LineChartOutlined,
  BorderOuterOutlined,
} from "@ant-design/icons";
import { useHistory, Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { isPermitted } from "../utils/PermissionManager";
import { logoutAdmin } from "../Actions/authAction";
const { Sider } = Layout;
const { SubMenu } = Menu;

export default function SideBar(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const path = history.location.pathname;
  const location = useLocation();
  const { show, setshow } = props.state;
  const roles = useSelector((state) => state.roles.roles);
  return (
    <>
      {location.pathname !== "/login" && (
        <Sider
          trigger={null}
          breakpoint="md"
          onBreakpoint={(broken) => {
            setshow(broken);
          }}
          className="sideBar"
          width="220px"
          style={{
            overflow: "auto",
            height: "calc(100vh - 60px)",
            position: "fixed",
            top: "60px",
            zIndex: 1,
            left: 0,
            bottom: 0,
            paddingTop: "0.5rem",
            backgroundColor: "white",
            fontWeight: "bold",
            borderRight: "1px solid rgba(0,0,0,.1)",
          }}
          collapsedWidth="0"
          collapsed={show}
        >
          <Menu
            theme="light"
            mode="inline"
            defaultSelectedKeys={[path.toString()]}
            // defaultOpenKeys={["student"]}
          >
            <Menu.ItemGroup
              key="g1"
              title={<b style={{ color: "grey" }}>Menu</b>}
            >
              {isPermitted(roles, ["admin"]) && (
                <Menu.Item
                  key="/"
                  icon={
                    <HomeFilled style={{ fontSize: "14px", width: "18px" }} />
                  }
                >
                  <Link to={"/"}>Dashboard</Link>
                </Menu.Item>
              )}
              <Menu.Item
                key="/orders"
                icon={
                  <GoldOutlined style={{ fontSize: "14px", width: "18px" }} />
                }
              >
                <Link to={"/orders"}>Orders</Link>
              </Menu.Item>

              <Menu.Item
                key="/customers"
                icon={
                  <TeamOutlined style={{ fontSize: "16px", width: "18px" }} />
                }
              >
                <Link to={"/customers"}>Customers</Link>
              </Menu.Item>

              {isPermitted(roles, ["accounts", "admin", "store_manager"]) && (
                <SubMenu
                  key="accounts"
                  icon={<MoneyCollectOutlined />}
                  title="Accounts"
                >
                  {isPermitted(roles, [
                    "admin",
                    "accounts",
                    "store_manager",
                  ]) && (
                    <Menu.Item
                      key="/cash-counter"
                      icon={
                        <WalletOutlined
                          style={{ fontSize: "18px", width: "18px" }}
                        />
                      }
                    >
                      <Link to={"/cash-counter?page=1&tab=pending"}>
                        Cash Counter
                      </Link>
                    </Menu.Item>
                  )}

                  {isPermitted(roles, ["admin", "accounts"]) && (
                    <Menu.Item
                      key="/balance"
                      icon={
                        <MoneyCollectOutlined
                          style={{ fontSize: "18px", width: "18px" }}
                        />
                      }
                    >
                      <Link to={"/balance"}>Balance</Link>
                    </Menu.Item>
                  )}

                  {isPermitted(roles, ["admin", "accounts"]) && (
                    <Menu.Item
                      key="/transactions"
                      icon={
                        <TagsOutlined
                          style={{ fontSize: "18px", width: "18px" }}
                        />
                      }
                    >
                      <Link to={"/transactions"}>Transactions</Link>
                    </Menu.Item>
                  )}
                  {isPermitted(roles, ["admin", "accounts"]) && (
                    <Menu.Item
                      key="/bank-slip"
                      icon={
                        <ContainerOutlined
                          style={{ fontSize: "18px", width: "18px" }}
                        />
                      }
                    >
                      <Link to={"/bank-slip?page=1&tab=1&status=pending"}>
                        Bank Slip
                      </Link>
                    </Menu.Item>
                  )}
                  {isPermitted(roles, ["admin", "accounts"]) && (
                    <Menu.Item
                      key="/agent/balance"
                      icon={
                        <DollarCircleOutlined
                          style={{ fontSize: "18px", width: "18px" }}
                        />
                      }
                    >
                      <Link to={"/agent/balance"}>Agent Balance</Link>
                    </Menu.Item>
                  )}
                  {isPermitted(roles, ["admin", "accounts"]) && (
                    <Menu.Item
                      key="/agent/commission-withdraw"
                      icon={
                        <ExportOutlined
                          style={{ fontSize: "16px", width: "18px" }}
                        />
                      }
                    >
                      <Link to={"/agent/commission-withdraw"}>
                        Agent Withdraw
                      </Link>
                    </Menu.Item>
                  )}
                </SubMenu>
              )}

              <Menu.Item
                key="/processing-customers"
                icon={
                  <Loading3QuartersOutlined
                    style={{ fontSize: "18px", width: "18px" }}
                  />
                }
              >
                <Link to={"/processing-customers"}>Processing Customers</Link>
              </Menu.Item>

              {isPermitted(roles, ["admin", "accounts", "chro", "crm"]) && (
                <Menu.Item
                  key="/refund"
                  icon={
                    <DollarCircleOutlined
                      style={{ fontSize: "18px", width: "18px" }}
                    />
                  }
                >
                  <Link to={"/refund"}>Refund</Link>
                </Menu.Item>
              )}

              {/* {isPermitted(roles, ["crm", "admin", "cro", "chro"]) && (
                <SubMenu key="crm" icon={<MoneyCollectOutlined />} title="CRM">
                  {isPermitted(roles, [
                    "admin",
                    "customer_care",
                    "cro",
                    "crm",
                    "chro",
                  ]) && (
                    <Menu.Item
                      key="/crm-call"
                      icon={
                        <PhoneOutlined
                          style={{ fontSize: "16px", width: "18px" }}
                        />
                      }
                    >
                      <Link to={"/crm-call"}>CRM Call</Link>
                    </Menu.Item>
                  )}

                </SubMenu>
              )} */}

              {isPermitted(roles, ["admin", "crm"]) && (
                <SubMenu
                  key="my-crm"
                  icon={<CustomerServiceOutlined />}
                  title="My CRM"
                >
                  <Menu.Item
                    key="/my-crm"
                    icon={
                      <ApiOutlined
                        style={{ fontSize: "16px", width: "18px" }}
                      />
                    }
                  >
                    <Link to={"/my-crm"}>Overview</Link>
                  </Menu.Item>

                  <Menu.Item
                    key="/my-crm/customers"
                    icon={
                      <UserSwitchOutlined
                        style={{ fontSize: "16px", width: "18px" }}
                      />
                    }
                  >
                    <Link to={"/my-crm/customers"}>Customers</Link>
                  </Menu.Item>
                  <Menu.Item
                    key="/my-crm/top-customers"
                    icon={
                      <CrownOutlined
                        style={{ fontSize: "16px", width: "18px" }}
                      />
                    }
                  >
                    <Link to={"/my-crm/top-customers"}>Monthly Customers</Link>
                  </Menu.Item>
                  <Menu.Item
                    key="/my-crm/purchase"
                    icon={
                      <DotChartOutlined
                        style={{ fontSize: "16px", width: "18px" }}
                      />
                    }
                  >
                    <Link to={"/my-crm/purchase"}>Purchase</Link>
                  </Menu.Item>
                  <Menu.Item
                    key="/my-crm/orders"
                    icon={
                      <BorderOuterOutlined
                        style={{ fontSize: "16px", width: "18px" }}
                      />
                    }
                  >
                    <Link to={"/my-crm/orders"}>Orders</Link>
                  </Menu.Item>
                  <Menu.Item
                    key="/my-crm/shipments"
                    icon={
                      <UserSwitchOutlined
                        style={{ fontSize: "16px", width: "18px" }}
                      />
                    }
                  >
                    <Link to={"/my-crm/shipments"}>Shipments</Link>
                  </Menu.Item>
                  <Menu.Item
                    key="/my-crm/order-report"
                    icon={
                      <LineChartOutlined
                        style={{ fontSize: "16px", width: "18px" }}
                      />
                    }
                  >
                    <Link to={"/my-crm/order-report"}>Order Report</Link>
                  </Menu.Item>
                  <Menu.Item
                    key="/my-crm/tickets"
                    icon={
                      <AuditOutlined
                        style={{ fontSize: "16px", width: "18px" }}
                      />
                    }
                  >
                    <Link to={"/my-crm/tickets"}>Tickets</Link>
                  </Menu.Item>

                  <Menu.Item
                    key="/my-crm/call-logs"
                    icon={
                      <ApartmentOutlined
                        style={{ fontSize: "16px", width: "18px" }}
                      />
                    }
                  >
                    <Link to={"/my-crm/call-logs"}>Call Logs</Link>
                  </Menu.Item>

                  <Menu.Item
                    key="/my-crm/incentives"
                    icon={
                      <DollarCircleOutlined
                        style={{ fontSize: "16px", width: "18px" }}
                      />
                    }
                  >
                    <Link to={"/my-crm/incentives"}>Incentives</Link>
                  </Menu.Item>
                </SubMenu>
              )}

              {isPermitted(roles, ["admin", "chro"]) && (
                <SubMenu
                  key="report"
                  icon={<ExceptionOutlined />}
                  title="Report"
                >
                  {isPermitted(roles, ["admin", "chro"]) && (
                    <Menu.Item
                      key="/order-report"
                      icon={
                        <AreaChartOutlined
                          style={{ fontSize: "18px", width: "18px" }}
                        />
                      }
                    >
                      <Link to={"/order-report"}>Order</Link>
                    </Menu.Item>
                  )}
                  {isPermitted(roles, ["admin"]) && (
                    <Menu.Item
                      key="/customer-report"
                      icon={
                        <UsergroupAddOutlined
                          style={{ fontSize: "18px", width: "18px" }}
                        />
                      }
                    >
                      <Link to={"/customer-report"}>Customer</Link>
                    </Menu.Item>
                  )}
                  {isPermitted(roles, ["admin"]) && (
                    <Menu.Item
                      key="/profit-report"
                      icon={
                        <DollarCircleOutlined
                          style={{ fontSize: "18px", width: "18px" }}
                        />
                      }
                    >
                      <Link to={"/profit-report"}>Profit</Link>
                    </Menu.Item>
                  )}
                  {isPermitted(roles, ["admin", "chro"]) && (
                    <Menu.Item
                      key="/purchasing-report"
                      icon={
                        <ShoppingCartOutlined
                          style={{ fontSize: "18px", width: "18px" }}
                        />
                      }
                    >
                      <Link to={"/purchasing-report"}>Purchasing</Link>
                    </Menu.Item>
                  )}

                  {isPermitted(roles, ["admin", "chro"]) && (
                    <Menu.Item
                      key="/sorting-report"
                      icon={
                        <AlignCenterOutlined
                          style={{ fontSize: "18px", width: "18px" }}
                        />
                      }
                    >
                      <Link to={"/sorting-report"}>Sorting Report</Link>
                    </Menu.Item>
                  )}

                  {isPermitted(roles, ["admin", "chro"]) && (
                    <Menu.Item
                      key="/order-process-report"
                      icon={
                        <FallOutlined
                          style={{ fontSize: "18px", width: "18px" }}
                        />
                      }
                    >
                      <Link to={"/order-process-report"}>Order Process</Link>
                    </Menu.Item>
                  )}
                  {isPermitted(roles, ["admin", "cro", "chro"]) && (
                    <Menu.Item
                      key="/crm-report"
                      icon={
                        <LineChartOutlined
                          style={{ fontSize: "16px", width: "18px" }}
                        />
                      }
                    >
                      <Link to={"/crm-report"}>CRM Report</Link>
                    </Menu.Item>
                  )}
                  {isPermitted(roles, ["admin", "chro"]) && (
                    <Menu.Item
                      key="/customer-order-timeline-report"
                      icon={
                        <SlidersOutlined
                          style={{ fontSize: "18px", width: "18px" }}
                        />
                      }
                    >
                      <Link to={"/customer-order-timeline-report"}>
                        Customer Order
                      </Link>
                    </Menu.Item>
                  )}
                  {isPermitted(roles, [
                    "admin",
                    "accounts",
                    "chro",
                    "chief_marketing",
                  ]) && (
                    <Menu.Item
                      key="/advance-hub"
                      icon={
                        <BorderlessTableOutlined
                          style={{ fontSize: "18px", width: "18px" }}
                        />
                      }
                    >
                      <Link to={"/advance-hub"}>Advance</Link>
                    </Menu.Item>
                  )}
                </SubMenu>
              )}

              {isPermitted(roles, ["purchasing", "admin", "tracking"]) && (
                <SubMenu
                  key="purchasing"
                  icon={<MoneyCollectOutlined />}
                  title="Purchasing"
                >
                  {isPermitted(roles, ["admin", "purchasing"]) && (
                    <Menu.Item
                      key="/purchasing"
                      icon={
                        <DingdingOutlined
                          style={{ fontSize: "18px", width: "18px" }}
                        />
                      }
                    >
                      <Link
                        to={
                          isPermitted(roles, ["chief_purchasing", "admin"])
                            ? "/purchasing"
                            : "/purchasing?page=1&tab=2"
                        }
                      >
                        Purchasing
                      </Link>
                    </Menu.Item>
                  )}
                  {isPermitted(roles, ["admin", "purchasing", "tracking"]) && (
                    <Menu.Item
                      key="/order-checklist"
                      icon={
                        <CheckCircleOutlined
                          style={{ fontSize: "18px", width: "18px" }}
                        />
                      }
                    >
                      <Link to={"/order-checklist"}>Checklist</Link>
                    </Menu.Item>
                  )}
                  {isPermitted(roles, ["admin", "tracking", "purchasing"]) && (
                    <Menu.Item
                      key="/tracking"
                      icon={
                        <EnvironmentOutlined
                          style={{ fontSize: "18px", width: "18px" }}
                        />
                      }
                    >
                      <Link to={"/tracking"}>Tracking</Link>
                    </Menu.Item>
                  )}
                </SubMenu>
              )}

              {isPermitted(roles, [
                "delivery",
                "admin",
                "store_manager",
                "store",
              ]) && (
                <SubMenu key="delivery" icon={<CarOutlined />} title="Delivery">
                  {isPermitted(roles, [
                    "admin",
                    "delivery",
                    "store_manager",
                    "store",
                    "tracking",
                  ]) && (
                    <Menu.Item
                      key="/sorting"
                      icon={
                        <DotChartOutlined
                          style={{ fontSize: "18px", width: "18px" }}
                        />
                      }
                    >
                      <Link to={"/sorting"}>Sorting</Link>
                    </Menu.Item>
                  )}
                  {isPermitted(roles, [
                    "admin",
                    "delivery",
                    "store_manager",
                    "store",
                  ]) && (
                    <Menu.Item
                      key="/invoice"
                      icon={
                        <ClockCircleOutlined
                          style={{ fontSize: "18px", width: "18px" }}
                        />
                      }
                    >
                      <Link to={"/invoice"}>Invoice</Link>
                    </Menu.Item>
                  )}
                  {isPermitted(roles, [
                    "admin",
                    "delivery",
                    "store_manager",
                    "store",
                    "tracking",
                  ]) && (
                    <Menu.Item
                      key="/shipment"
                      icon={
                        <DatabaseOutlined
                          style={{ fontSize: "18px", width: "18px" }}
                        />
                      }
                    >
                      <Link to={"/shipment"}>Shipment</Link>
                    </Menu.Item>
                  )}

                  {isPermitted(roles, [
                    "admin",
                    "delivery",
                    "store_manager",
                    "store",
                  ]) && (
                    <Menu.Item
                      key="/courier"
                      icon={
                        <CarOutlined
                          style={{ fontSize: "18px", width: "18px" }}
                        />
                      }
                    >
                      <Link
                        to={"/courier?page=1&tab=pending&courier=sundarban"}
                      >
                        Courier
                      </Link>
                    </Menu.Item>
                  )}
                </SubMenu>
              )}

              {/* {isPermitted(roles, ["admin", "marketing"]) && (
                <SubMenu
                  key="target"
                  icon={<MoneyCollectOutlined />}
                  title="Target"
                >
                  {isPermitted(roles, ["admin", "marketing"]) && (
                    <Menu.Item
                      key="/targets"
                      icon={
                        <MoneyCollectOutlined
                          style={{ fontSize: "18px", width: "18px" }}
                        />
                      }
                    >
                      <Link to={"/targets"}>Target Record</Link>
                    </Menu.Item>
                  )}
                  {isPermitted(roles, ["admin", "marketing"]) && (
                    <Menu.Item
                      key="/marketing-logs"
                      icon={
                        <FileZipOutlined
                          style={{ fontSize: "18px", width: "18px" }}
                        />
                      }
                    >
                      <Link to={"/marketing-logs"}>Success Record</Link>
                    </Menu.Item>
                  )}
                  {isPermitted(roles, ["admin", "marketing"]) && (
                    <Menu.Item
                      key="/marketing-target-status"
                      icon={
                        <StockOutlined
                          style={{ fontSize: "18px", width: "18px" }}
                        />
                      }
                    >
                      <Link to={"/marketing-target-status"}>Target Status</Link>
                    </Menu.Item>
                  )}
                </SubMenu>
              )} */}

              {/* {isPermitted(roles, ["admin", "purchasing", "tracking"]) && ( */}

              {/* )} */}

              {/* {isPermitted(roles, ["admin", "marketing"]) && (
                <Menu.Item
                  key="/seminars"
                  icon={
                    <DeploymentUnitOutlined
                      style={{ fontSize: "18px", width: "18px" }}
                    />
                  }
                >
                  <Link to={"/seminars"}>Seminars</Link>
                </Menu.Item>
              )} */}
              {/* {isPermitted(roles, ["admin", "marketing"]) && (
                <Menu.Item
                  key="/field-visits"
                  icon={
                    <OneToOneOutlined
                      style={{ fontSize: "18px", width: "18px" }}
                    />
                  }
                >
                  <Link to={"/field-visits"}>Field Visits</Link>
                </Menu.Item>
              )} */}
              {/* {isPermitted(roles, ["admin", "marketing"]) && (
                <Menu.Item
                  key="/office-visits"
                  icon={
                    <CodepenOutlined
                      style={{ fontSize: "18px", width: "18px" }}
                    />
                  }
                >
                  <Link to={"/office-visits"}>Office Visits</Link>
                </Menu.Item>
              )}
              {isPermitted(roles, ["admin", "marketing"]) && (
                <Menu.Item
                  key="/campaigns"
                  icon={
                    <NotificationOutlined
                      style={{ fontSize: "18px", width: "18px" }}
                    />
                  }
                >
                  <Link to={"/campaigns"}>Campaigns</Link>
                </Menu.Item>
              )} */}

              {isPermitted(roles, ["admin", "chro", "agent"]) && (
                <SubMenu
                  key="agent"
                  icon={
                    <AntDesignOutlined
                      style={{ fontSize: "18px", width: "18px" }}
                    />
                  }
                  title="Agent"
                >
                  {isPermitted(roles, ["admin", "chief_marketing", "chro"]) && (
                    <Menu.Item
                      key="/agent"
                      icon={
                        <AntDesignOutlined
                          style={{ fontSize: "18px", width: "18px" }}
                        />
                      }
                    >
                      <Link to={"/agent"}>Agents</Link>
                    </Menu.Item>
                  )}
                  {isPermitted(roles, ["admin", "chief_marketing", "chro"]) && (
                    <Menu.Item
                      key="/agent/orders"
                      icon={
                        <WindowsOutlined
                          style={{ fontSize: "18px", width: "18px" }}
                        />
                      }
                    >
                      <Link to={"/agent/orders"}>Agent Orders</Link>
                    </Menu.Item>
                  )}
                  {isPermitted(roles, ["admin", "chief_marketing", "chro"]) && (
                    <Menu.Item
                      key="/agent/order-report"
                      icon={
                        <ApartmentOutlined
                          style={{ fontSize: "18px", width: "18px" }}
                        />
                      }
                    >
                      <Link to={"/agent/order-report"}>Agent Order Report</Link>
                    </Menu.Item>
                  )}
                  {isPermitted(roles, ["admin", "chief_marketing", "chro"]) && (
                    <Menu.Item
                      key="/agent/zones"
                      icon={
                        <EnvironmentOutlined
                          style={{ fontSize: "18px", width: "18px" }}
                        />
                      }
                    >
                      <Link to={"/agent/zones"}>Zones</Link>
                    </Menu.Item>
                  )}
                </SubMenu>
              )}

              <SubMenu
                key="support"
                icon={<CustomerServiceOutlined />}
                title="Support"
              >
                {isPermitted(roles, ["admin", "chro"]) && (
                  <Menu.Item
                    key="/support-category"
                    icon={
                      <ApiOutlined
                        style={{ fontSize: "16px", width: "18px" }}
                      />
                    }
                  >
                    <Link to={"/support-category"}>Category</Link>
                  </Menu.Item>
                )}
                <Menu.Item
                  key="/tickets"
                  icon={
                    <ApartmentOutlined
                      style={{ fontSize: "16px", width: "18px" }}
                    />
                  }
                >
                  <Link to={"/tickets"}>Tickets</Link>
                </Menu.Item>
              </SubMenu>

              {isPermitted(roles, ["admin"]) && (
                <Menu.Item
                  key="/products"
                  icon={
                    <WindowsOutlined
                      style={{ fontSize: "14px", width: "18px" }}
                    />
                  }
                >
                  <Link to={"/products"}>Products</Link>
                </Menu.Item>
              )}

              {isPermitted(roles, ["admin", "marketing"]) && (
                <Menu.Item
                  key="/coupon"
                  icon={
                    <GiftOutlined style={{ fontSize: "16px", width: "18px" }} />
                  }
                >
                  <Link to={"/coupon"}>Coupon</Link>
                </Menu.Item>
              )}
              {isPermitted(roles, ["admin"]) && (
                <Menu.Item
                  key="/cases"
                  icon={
                    <UngroupOutlined
                      style={{ fontSize: "16px", width: "18px" }}
                    />
                  }
                >
                  <Link to={"/cases"}>Cases</Link>
                </Menu.Item>
              )}
            </Menu.ItemGroup>
            {isPermitted(roles, ["admin"]) && (
              <Menu.ItemGroup
                key="g2"
                title={<b style={{ color: "grey" }}>Advance</b>}
              >
                <Menu.Item
                  key="/roles"
                  icon={
                    <CheckCircleOutlined
                      style={{ fontSize: "14px", width: "18px" }}
                    />
                  }
                >
                  <Link to={"/roles"}>Roles</Link>
                </Menu.Item>
                {isPermitted(roles, ["admin"]) && (
                  <Menu.Item
                    key="/admins"
                    icon={
                      <UserOutlined
                        style={{ fontSize: "16px", width: "18px" }}
                      />
                    }
                  >
                    <Link to={"/admins"}>Admins</Link>
                  </Menu.Item>
                )}
              </Menu.ItemGroup>
            )}

            <Menu.ItemGroup
              key="g3"
              title={<b style={{ color: "grey" }}>Authentication</b>}
            >
              <Menu.Item
                key="/profile"
                icon={
                  <UserOutlined style={{ fontSize: "14px", width: "18px" }} />
                }
              >
                <Link to={"/profile"}>Profile</Link>
              </Menu.Item>
              {isPermitted(roles, ["admin"]) && (
                <Menu.Item
                  key="/settings"
                  icon={
                    <SettingOutlined
                      style={{ fontSize: "14px", width: "18px" }}
                    />
                  }
                >
                  <Link to={"/settings"}>Settings</Link>
                </Menu.Item>
              )}
              <Menu.Item
                key="/logout"
                onClick={() => {
                  dispatch(logoutAdmin());
                }}
                icon={
                  <LogoutOutlined style={{ fontSize: "16px", width: "18px" }} />
                }
              >
                Logout
              </Menu.Item>
            </Menu.ItemGroup>
          </Menu>
        </Sider>
      )}
    </>
  );
}
