import React, { lazy, Suspense, useEffect, useState } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import url from "../utils/url";
import moment from "moment";
import { Button, Modal, Tag } from "antd";
const MainContent = lazy(() => import("../layouts/MainContent"));

export default function AdminRoute(props) {
  const { show, trigger } = props.state;
  const [tickets, setTickets] = useState(null);
  const location = useLocation();
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location]);

  useEffect(() => {
    if (!location?.pathname.includes("/ticket")) {
      let item = localStorage.getItem("ticket_popup");
      let closed = item;
      let dateA = moment(new Date());
      let dateB = moment(new Date(closed));
      let sec = dateA.diff(dateB, "seconds");
      if (sec > 120) {
        axios
          .get(url + `/admin/ticket-check`)
          .then((res) => {
            let tickets = res.data;
            if (tickets.length > 0) {
              setTickets(tickets);
            } else {
              setTickets(null);
            }
          })
          .catch((err) => {
            setTickets(null);
          });
      } else {
        setTickets(null);
      }
    }
  }, [location.pathname]);

  const auth = useSelector((state) => state.auth);
  if (auth.isAuthenticated) {
    let content = (
      <React.Fragment>
        <Suspense fallback={<div className="pageProgress"></div>}>
          <MainContent state={{ show, trigger }}>{props.Component}</MainContent>
        </Suspense>
        <Modal
          title="Resolve Tickets"
          visible={tickets}
          width={window.innerWidth > 768 ? "50%" : "80%"}
          footer={null}
          bodyStyle={{
            padding: 0,
          }}
        >
          <div
            style={{
              height: "60vh",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <img
                src={require("../assets/warning.png").default}
                style={{ width: "256px" }}
                alt=""
              />
            </div>
            <div style={{ flex: 1 }}>
              {tickets?.map((el) => (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "1.5rem",
                    borderBottom: "1px solid #eee",
                    padding: "1rem",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <Tag color="red">{el.id}</Tag>

                    <span>{el.message?.slice(0, 100)}</span>
                  </div>
                  <a href={`/ticket/${el.id}`} target="_blank" rel="noreferrer">
                    <Button type="danger">Resolve</Button>
                  </a>
                </div>
              ))}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                margin: "1rem",
              }}
            >
              <Button
                size="large"
                type="primary"
                onClick={() => {
                  localStorage.setItem("ticket_popup", new Date());
                  setTickets(null);
                }}
              >
                Skip For Two Min
              </Button>
            </div>
          </div>
        </Modal>
      </React.Fragment>
    );
    return content;
  } else {
    return <Redirect to={"/login"} />;
  }
}
