import {
  Button,
  Col,
  DatePicker,
  Descriptions,
  Form,
  Input,
  notification,
  Row,
  Select,
  Switch,
} from "antd";
import React, { useState, useEffect } from "react";
import moment from "moment";
import CustomerBadge from "./CustomerBadge";
import { addAdminCsCall, callCaseRecord, getSeminars } from "../api/api";

export default function CallFeedback({ data, afterFunction, type }) {
  const [loading, setLoading] = useState(false);
  const [form2] = Form.useForm();
  const [seminars, setSeminars] = useState(null);
  const [editCustomer, setEditCustomer] = useState(false);
  const [seminarSelected, setSeminarSelected] = useState(false);

  const dateFormat = "DD/MM/YYYY";
  const serverDateFormat = "YYYY-MM-DD HH:mm:ss";

  useEffect(() => {
    form2.resetFields();
    setSeminarSelected(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    getSeminars({ status: "upcoming" }, setSeminars, () => {});
  }, []);

  const [feedbacks, setFeedbacks] = useState([
    "Positive",
    "Negative",
    "Convinced",
    "Will Order",
    "Busy",
    "Not satisfied",
    "Didn't pick call",
    "Switched off",
    "Potential Customer",
    "Testing",
    "Interested Onesalemart",
  ]);
  const [callType, setCallType] = useState(["incoming", "outgoing"]);

  const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
      message: message,
      description: description,
    });
  };

  const onFailedSubmit = (error) => {
    openNotificationWithIcon(
      "error",
      "Failed to submit",
      "You have missed some important information. Please fill those up to complete action."
    );
  };
  return (
    <div>
      {data && seminars && (
        <Form
          name="basic"
          layout="vertical"
          form={form2}
          onFinish={(values) => {
            console.log(values);
            let formData = {
              feedback: values.call_feedback,
              summary: values.call_summary,
              user_id: data?.id,
            };
            if (data.order_id) {
              formData.order_id = data.order_id;
            }
            if (values.seminar_id) {
              formData.seminar_id = values.seminar_id;
            }
            if (values.seminar_status) {
              formData.seminar_status = values.seminar_status;
            }
            if (values.name) {
              formData.name = values.name;
            }
            if (values.city) {
              formData.city = values.city;
            }
            if (values.district) {
              formData.district = values.district;
            }
            if (values.address) {
              formData.address = values.address;
            }
            if (values.duration) {
              formData.duration = values.duration;
            }
            if (values.call_type) {
              formData.call_type = values.call_type;
            }
            if (values.field_date) {
              formData.field_date = moment(values.field_date).format(
                serverDateFormat
              );
            }
            if (values.office_invite_date) {
              formData.office_invite_date = moment(
                values.office_invite_date
              ).format(serverDateFormat);
            }
            setLoading(true);
            addAdminCsCall(formData, (result) => {
              form2.resetFields();
              setLoading(false);
              if (result) {
                openNotificationWithIcon(
                  "success",
                  "Success",
                  "Updated successfully"
                );
              } else {
                openNotificationWithIcon("error", "Failed", "Failed to update");
              }
            });

            afterFunction(false);
          }}
          onFinishFailed={onFailedSubmit}
        >
          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }}>
              <Descriptions
                bordered
                size="small"
                column={3}
                style={{ marginBottom: "1rem" }}
              >
                <Descriptions.Item label="Name" span={3}>
                  {data.name ? (
                    <CustomerBadge
                      name={data.name}
                      id={data.id}
                      badge={data.wsc_badge}
                    />
                  ) : (
                    "-"
                  )}
                </Descriptions.Item>
                <Descriptions.Item label="Phone" span={2}>
                  {data?.phone}
                </Descriptions.Item>
                {data && data.orderCount && (
                  <Descriptions.Item label="Orders" span={1}>
                    {data.orderCount}
                  </Descriptions.Item>
                )}
                <Descriptions.Item label="Registration Date" span={1}>
                  {data?.created_at
                    ? moment(new Date(data.created_at)).format("DD/MM/YYYY")
                    : "-"}
                </Descriptions.Item>
                <Descriptions.Item label="City" span={1}>
                  {data?.city ? data.city : "-"}
                </Descriptions.Item>
                <Descriptions.Item label="District" span={1}>
                  {data?.district ? data.district : "-"}
                </Descriptions.Item>
                <Descriptions.Item label="Balance" span={1}>
                  ৳ {data?.balance ? data.balance : 0}
                </Descriptions.Item>
              </Descriptions>
            </Col>
            <Col xs={{ span: 12 }}>
              <Form.Item
                name="call_feedback"
                label={`Feedback`}
                rules={[
                  {
                    required: true,
                    message: "This field is required !",
                  },
                ]}
              >
                <Select placeholder="Select Feedback">
                  {feedbacks.map((el) => (
                    <Select.Option value={el.toLowerCase()}>{el}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <Form.Item
                name="call_type"
                label={`Call Type`}
                rules={[
                  {
                    required: true,
                    message: "This field is required !",
                  },
                ]}
              >
                <Select placeholder="Select call type">
                  {callType.map((el) => (
                    <Select.Option value={el.toLocaleLowerCase()}>
                      {el}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 12 }}>
              <Form.Item name="duration" label={`Call Duration`}>
                <Input placeholder="Duration" />
              </Form.Item>
            </Col>

            {/* {type !== "crm" && ( */}
            <>
              <Col xs={{ span: 24 }}>
                <Switch
                  style={{ margin: "0.5rem 0 1rem 0rem" }}
                  checkedChildren="Edit Customer"
                  unCheckedChildren="Edit Customer"
                  checked={editCustomer}
                  onChange={(e) => {
                    setEditCustomer(e);
                  }}
                />
              </Col>
            </>
            {/* )} */}
            {editCustomer && (
              <Col xs={{ span: 24 }}>
                <Form.Item name="name" label={`Customer Name`}>
                  <Input placeholder="Name" />
                </Form.Item>
              </Col>
            )}
            {editCustomer && (
              <Col xs={{ span: 6 }}>
                <Form.Item name="city" label={`City`}>
                  <Input placeholder="City" />
                </Form.Item>
              </Col>
            )}
            {editCustomer && (
              <Col xs={{ span: 6 }}>
                <Form.Item name="district" label={`District`}>
                  <Input placeholder="District" />
                </Form.Item>
              </Col>
            )}
            {editCustomer && (
              <Col xs={{ span: 12 }}>
                <Form.Item name="address" label={`Address`}>
                  <Input placeholder="Address" />
                </Form.Item>
              </Col>
            )}
            <Col xs={{ span: 24 }}>
              <Form.Item
                name="call_summary"
                label={`Call Summary`}
                rules={[
                  {
                    required: true,
                    message: "This field is required !",
                  },
                ]}
              >
                <Input.TextArea rows={4} placeholder="Call Summary" />
              </Form.Item>
            </Col>
          </Row>
          <div style={{ display: "flex" }}>
            <Form.Item>
              <Button type="primary" htmlType="submit" disabled={loading}>
                Submit
              </Button>
            </Form.Item>
            <Button
              onClick={() => {
                form2.resetFields();
              }}
              style={{ marginLeft: "16px" }}
            >
              Return
            </Button>
          </div>
        </Form>
      )}
    </div>
  );
}
