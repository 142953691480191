import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import url from "../utils/url";

export const loginAdmin = (data, history, loginAfterFunction) => (dispatch) => {
  axios
    .post(url + "/admin/verify", data)
    .then((res) => {
      console.log(res.data);
      if (res.data && res.data.success) {
        const { token, name, phone, roles, user_id, department } =
          res.data.success;
        localStorage.setItem("agl", token);
        let roleArray = [];
        roles.forEach((element) => {
          roleArray.push(element.name.toLowerCase());
        });
        localStorage.setItem(
          "profile",
          JSON.stringify({
            name: name,
            phone: phone,
            roles: roleArray,
            id: user_id,
            department: department,
          })
        );
        setAdminRole(roles);
        setAuthToken(token);
        dispatch(setAdmin(true));
        window.location = "/";
      }
    })
    .catch((err) => {
      loginAfterFunction(false);
    });
};

export const logoutAdmin = () => (dispatch) => {
  localStorage.removeItem("agl");
  localStorage.removeItem("profile");
  setAuthToken(null);
  dispatch(setAdmin(false));
};

export const setAdmin = (isAuthenticated) => {
  return {
    type: "SET_ADMIN_AUTH",
    payload: isAuthenticated,
  };
};
export const setRole = (isAuthenticated) => {
  return {
    type: "SET_ADMIN_AUTH",
    payload: isAuthenticated,
  };
};
export const setAdminRole = (data) => {
  return {
    type: "SET_ADMIN_ROLES",
    payload: data,
  };
};
