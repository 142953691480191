import React, { useEffect, useState } from "react";
import {
  Table,
  Button,
  Typography,
  Tag,
  Drawer,
  Form,
  notification,
  Row,
  Col,
  Input,
  InputNumber,
  Upload,
  message,
  Image,
  Modal,
  Select,
  Space,
  DatePicker,
} from "antd";
import { Link, useHistory } from "react-router-dom";
import {
  getCrmManagers,
  getMyCrmCustomers,
  getTopCrmCustomers,
  getUsers,
} from "../api/api";
import moment from "moment";
import Spinner from "../components/Spinner";
import { isPermitted } from "../utils/PermissionManager";
import { useSelector } from "react-redux";
import PhoneNumber from "../components/PhoneNumber";
import queryString from "query-string";
import CustomerBadge from "../components/CustomerBadge";
import CallFeedback from "../components/CallFeedback";
import CallLogs from "../components/CallLogs";
const { Column } = Table;
const { Text } = Typography;
const { Search } = Input;

export default function CrmTopCustomers() {
  const [users, setUsers] = useState(null);
  let date = moment(new Date());
  const [filter, setFilter] = useState({
    from_month: date.format("M"),
    to_month: date.format("M"),
    from_year: date.format("YYYY"),
    to_year: date.format("YYYY"),
  });
  const [callData, setCallData] = useState(null);
  const [callLog, setCallLog] = useState(null);
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const page = history.location.search.slice(6)
    ? parseInt(history.location.search.slice(6))
    : 1;

  const [manager, setManager] = useState(false);
  const roles = useSelector((state) => state.roles.roles);

  useEffect(() => {
    getCrmManagers(null, setManager);
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setUsers(null);
    setLoading(true);
    let mFilter = filter
      ? filter
      : {
          page: page,
        };
    getTopCrmCustomers(mFilter, setUsers);
  }, [page, filter]);

  let badge = "";

  const serverDateFormat = "YYYY-MM-DD";

  return (
    <div>
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "1rem",
            background: "#fff",
            padding: "16px 24px",
            alignItems: "center",
            borderRadius: 4,
          }}
        >
          <span style={{ fontSize: "16px" }}>
            CRM Monthly Customers{" "}
            {users ? (
              <Tag color="black" style={{ marginLeft: "1rem" }}>
                {users.length}
              </Tag>
            ) : (
              ""
            )}
          </span>
          <div>
            <Search
              placeholder="Customer Phone Number"
              onSearch={(value) => {
                if (value) {
                  setFilter({
                    phone: value,
                  });
                } else {
                  setFilter(null);
                }
              }}
              allowClear
              enterButton="Search"
              style={{ width: "360px", marginRight: "8px" }}
            />
            {isPermitted(roles, ["admin", "cro"]) && (
              <Select
                placeholder="Select user"
                onSelect={(e) => {
                  let mFilter = filter ? { ...filter } : {};
                  mFilter.crm_manager_id = e;
                  setFilter(mFilter);
                }}
                style={{ width: "200px" }}
              >
                {manager &&
                  manager.data &&
                  manager.data.map((p, k) => (
                    <Select.Option value={p.id} key={p.id}>
                      {p.name}
                    </Select.Option>
                  ))}
              </Select>
            )}
          </div>
        </div>
        <div style={{ flex: 1, background: "#fff", padding: "1rem" }}>
          <DatePicker.RangePicker
            style={{ marginTop: "0.5rem", width: "400px" }}
            format="MM/YYYY"
            picker="month"
            onChange={(e) => {
              let fromDate = new Date(e[0]);
              let toDate = new Date(e[1]);
              let mFilter = { ...filter };
              mFilter.from_month = moment(fromDate).format("M");
              mFilter.to_month = moment(toDate).format("M");
              mFilter.from_year = moment(fromDate).format("YYYY");
              mFilter.to_year = moment(toDate).format("YYYY");
              setFilter(mFilter);
            }}
          />
        </div>
        {users ? (
          <Table
            dataSource={users}
            rowKey={(record) =>
              badge === "unpaid" || badge === "new"
                ? record.user?.id
                : record.id
            }
            pagination={false}
          >
            <Column
              title="Name"
              dataIndex="name"
              width={"300px"}
              align="center"
              render={(text, record, index) => (
                <CustomerBadge
                  name={record.user?.name}
                  badge={record.user?.wsc_badge}
                  id={record.user?.id}
                />
              )}
            />
            <Column
              title="Phone"
              align="center"
              dataIndex="phone"
              render={(text, record, index) => (
                <PhoneNumber phone={record.user?.phone} popup={true} />
              )}
            />

            <Column
              title={"Order Count"}
              align="center"
              dataIndex="balance"
              render={(text, record, index) => (
                <div>
                  {record.orders ? (
                    <Text color="red">{record.orders}</Text>
                  ) : (
                    "0"
                  )}
                </div>
              )}
            />
            <Column
              title={"Order Amount"}
              align="center"
              dataIndex="balance"
              render={(text, record, index) => (
                <div>
                  {record.amount ? (
                    <Tag color="red">৳ {record.amount}</Tag>
                  ) : (
                    "-"
                  )}
                </div>
              )}
            />

            <Column
              title="Action"
              key="action"
              render={(text, record) => (
                <Space>
                  <Button
                    type="primary"
                    onClick={() => {
                      setCallData(record);
                    }}
                  >
                    Call
                  </Button>
                  <Button
                    type="default"
                    onClick={() => {
                      setCallLog(record);
                    }}
                  >
                    Call Logs
                  </Button>

                  <Link
                    to={`/customer/${
                      badge === "unpaid" || badge === "new"
                        ? record.user?.id
                        : record.id
                    }`}
                  >
                    <Button type="primary">Details</Button>
                  </Link>
                </Space>
              )}
            />
          </Table>
        ) : (
          <div>
            <Spinner />
          </div>
        )}

        <Drawer
          title={"Feedback"}
          visible={callData}
          width={"800px"}
          onClose={() => {
            setCallData(null);
          }}
          footer={null}
        >
          {callData && (
            <CallFeedback
              data={callData.user}
              type="crm"
              afterFunction={() => {
                setCallData(null);
              }}
            />
          )}
        </Drawer>

        <Drawer
          title={"Call Logs"}
          visible={callLog}
          width={"60%"}
          onClose={() => {
            setCallLog(null);
          }}
        >
          {callLog && (
            <CallLogs
              user_id={callLog.id ? callLog.id : callLog.user_id}
              afterFunction={() => {
                setCallData(null);
              }}
            />
          )}
        </Drawer>
      </div>
    </div>
  );
}
