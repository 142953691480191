import React, { useEffect, useState } from "react";
import { Card, Tag, Drawer, Descriptions } from "antd";
import { getCrmPurchasingStats, getPurchasingStats } from "../api/api";
import { TeamOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { isPermitted } from "../utils/PermissionManager";

export default function PurchasingStats({ filter, crm }) {
  const [stats, setStats] = useState(false);
  const [customStats, setCustomStats] = useState({
    totalCustomer: 0,
    totalRmb: 0,
  });
  const [drawer, setDrawer] = useState(false);
  const roles = useSelector((state) => state.roles.roles);

  console.log(stats);

  useEffect(() => {
    if (!crm) {
      if (filter && filter.to && filter.from) {
        getPurchasingStats(setStats, `?from=${filter.from}&to=${filter.to}`);
      } else {
        getPurchasingStats(setStats, "");
      }
    } else {
      if (filter?.crm_manager_id) {
        if (filter && filter.to && filter.from) {
          getCrmPurchasingStats(
            setStats,
            `?from=${filter.from}&to=${filter.to}&crm_manager_id=${filter.crm_manager_id}`
          );
        } else {
          getCrmPurchasingStats(
            setStats,
            `?crm_manager_id=${filter?.crm_manager_id}`
          );
        }
      } else {
        getCrmPurchasingStats(setStats, ``);
      }
    }
  }, [filter, crm]);

  useEffect(() => {
    let totalCustomer = 0;
    let totalRmb = 0;
    if (stats) {
      stats.all.forEach((el) => {
        totalCustomer = totalCustomer + el.user_count;
        totalRmb = totalRmb + parseFloat(el.rmb_needed);
      });

      setCustomStats({
        totalCustomer: totalCustomer,
        totalRmb: totalRmb,
      });
    }
  }, [stats]);

  return (
    <div>
      {stats && (
        <div
          className="purchasingStats"
          style={{
            display: "grid",
            gap: "0.5rem",
            gridTemplateColumns: "repeat(auto-fit, minmax(8rem, 1fr))",
            marginBottom: "0.5rem",
          }}
        >
          <Card
            size="small"
            bodyStyle={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span className="statSpan">{stats ? stats.total_order : 0}</span>
            <h4>Total Order</h4>
            <div>
              <Tag
                color={"magenta"}
                icon={<TeamOutlined />}
                style={{ fontSize: "12px", fontWeight: 500 }}
              >
                {stats ? stats?.total_user : 0}
              </Tag>
              <Tag
                color={"volcano"}
                style={{ fontSize: "12px", fontWeight: 500 }}
              >
                ¥ {parseInt(customStats.totalRmb).toLocaleString()}
              </Tag>
            </div>
          </Card>

          {stats.all.map((el) => (
            <Card
              key={el.status.name}
              size="small"
              bodyStyle={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span className="statSpan">{el.total_orders}</span>
              <h4 style={{ textAlign: "center" }}>{el.status.name}</h4>
              <div>
                <Tag
                  color={"magenta"}
                  icon={<TeamOutlined />}
                  style={{ fontSize: "12px", fontWeight: 500 }}
                >
                  {el.user_count}
                </Tag>
                <Tag
                  color={"volcano"}
                  style={{ fontSize: "12px", fontWeight: 500 }}
                >
                  ¥ {parseInt(el.rmb_needed).toLocaleString()}
                </Tag>
              </div>
            </Card>
          ))}
          {isPermitted(roles, ["admin"]) && (
            <Card
              size="small"
              bodyStyle={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span className="statSpan">Grand Total</span>
              <div style={{ marginTop: 6 }}>
                <Tag
                  color={"volcano"}
                  style={{ fontSize: "12px", fontWeight: 500 }}
                >
                  ৳{" "}
                  {stats && stats.total_amount
                    ? parseInt(stats.total_amount).toLocaleString()
                    : 0}
                </Tag>
              </div>
            </Card>
          )}
          {isPermitted(roles, ["admin"]) && (
            <Card
              size="small"
              bodyStyle={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span className="statSpan" style={{ textAlign: "center" }}>
                Total RMB
              </span>
              <div style={{ marginTop: 6 }}>
                <Tag
                  color={"magenta"}
                  style={{ fontSize: "12px", fontWeight: 500 }}
                >
                  ¥ {parseInt(customStats.totalRmb).toLocaleString()}
                </Tag>
                <Tag
                  color={"volcano"}
                  style={{ fontSize: "12px", fontWeight: 500 }}
                >
                  ৳ {parseInt(customStats.totalRmb * 14.5).toLocaleString()}
                </Tag>
              </div>
            </Card>
          )}
          {isPermitted(roles, ["admin", "chief_purchasing"]) && (
            <Card
              size="small"
              bodyStyle={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
              onClick={() => setDrawer(1)}
            >
              <span
                className="statSpan"
                style={{ textAlign: "center", marginBottom: 0 }}
              >
                View Status
              </span>
            </Card>
          )}
        </div>
      )}

      <Drawer
        title="Purchasing"
        visible={drawer}
        width={"30%"}
        bodyStyle={{ backgroundColor: "#f1f1f1" }}
        onClose={() => {
          setDrawer(0);
        }}
      >
        {isPermitted(roles, ["admin", "chief_purchasing"]) && stats && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {stats &&
              stats.purchase_stats &&
              stats.purchase_stats.map((l, k) => (
                <Card style={{ marginBottom: "1rem" }}>
                  <Descriptions
                    size="small"
                    // bordered
                    column={3}
                    key={k}
                  >
                    <Descriptions.Item span={3}>
                      <div style={{ padding: "0.5rem 0" }}>{l.name}</div>
                    </Descriptions.Item>
                    <Descriptions.Item>
                      <Tag color="red">Pending : {l.pending}</Tag>
                    </Descriptions.Item>
                    <Descriptions.Item>
                      <Tag color="green">Purchasing: {l.purchasing}</Tag>
                    </Descriptions.Item>
                    <Descriptions.Item>
                      <Tag color="black">Completed: {l.completed}</Tag>
                    </Descriptions.Item>
                  </Descriptions>
                </Card>
              ))}
          </div>
        )}
      </Drawer>
    </div>
  );
}
