import React, { useState } from "react";
import {
  Select,
  Table,
  Typography,
  notification,
  Button,
  Tag,
  Drawer,
  Descriptions,
  Card,
} from "antd";
import { Link, useHistory } from "react-router-dom";
import Spinner from "../components/Spinner";
import moment from "moment";
import { assignOrderPurchaseManager } from "../api/api";
import { DollarOutlined } from "@ant-design/icons";
import Status from "./Status";
const { Option } = Select;
const { Column } = Table;
const { Text } = Typography;

export default function PurchaseOrderList({
  orders,
  users,
  type,
  pageNum,
  tab,
  prevFilter,
}) {
  const [Loading, setLoading] = useState(false);
  const [order, setOrder] = useState(null);
  const [selected, setSelected] = useState(false);
  let purchasingEmp = [];
  const history = useHistory();

  if (users) {
    purchasingEmp = users.data.filter((el) => {
      if (el.role.length > 0) {
        let index = el.role.findIndex((item) => item.name === "purchasing");
        if (index >= 0) {
          return true;
        }
        return false;
      } else {
        return false;
      }
    });
  }
  const assignPurchaseManager = (id, order_id) => {
    setLoading(true);
    let data = {
      order_id: order_id,
      admin_id: id,
    };
    assignOrderPurchaseManager(data, afterPerform);
  };

  const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
      message: message,
      description: description,
    });
  };

  const afterPerform = (result) => {
    setLoading(false);
    if (result) {
      openNotificationWithIcon(
        "success",
        "Success",
        "Manager updated successfully"
      );
    } else {
      openNotificationWithIcon("error", "Failed", "Manager update failed");
    }
  };

  console.log(orders);

  return (
    <div>
      <div>
        {orders && orders.data ? (
          <div>
            <Table
              dataSource={orders.data}
              rowKey={(record) => record.id}
              rowSelection={{
                selectedRowKeys: [selected],
              }}
              pagination={{
                current: pageNum,
                pageSize: orders.meta ? orders.meta.per_page : 24,
                total: orders.meta ? orders.meta.total : 1,
                showSizeChanger: false,
              }}
              onChange={(page, pageSize) => {
                history.push(`/purchasing?page=${page.current}&tab=${tab}`);
              }}
              bordered
            >
              <Column
                title="Date"
                key="date"
                render={(text, record, index) => (
                  <div style={{ width: "100px" }}>
                    <Text
                      style={{
                        cursor: "pointer",
                        textTransform: "capitalize",
                      }}
                    >
                      {moment(new Date(record.created_at)).format(
                        "DD MMM, YYYY"
                      )}
                    </Text>
                  </div>
                )}
              />
              <Column
                title="Order ID"
                key="id"
                render={(text, record, index) => (
                  <div onClick={() => setSelected(record.id)}>
                    <a
                      href={`/order/${record.order_code}`}
                      rel="noreferrer"
                      target="_blank"
                    >
                      #{record.order_code ? record.order_code : record.id}
                    </a>
                  </div>
                )}
              />
              <Column
                title="Payment Method"
                key="payment_method"
                align="center"
                render={(text, record) => (
                  <div>
                    {record.payments.map((p, k) => (
                      <Status
                        key={p.id}
                        icon={<DollarOutlined />}
                        onClick={() => setOrder(record)}
                        name={p.payment_method}
                        type="payment_method"
                      />
                    ))}
                  </div>
                )}
              />
              <Column
                title="Name"
                key="name"
                render={(text, record) => (
                  <div>
                    <Link to={`/customer/${record.user_id}`}>
                      {record.user_name}
                    </Link>
                  </div>
                )}
              />
              <Column
                title="Phone"
                key="phone"
                align="center"
                render={(text, record) => (
                  <div>
                    <Text style={{ cursor: "pointer" }}>
                      {record.user_phone
                        ? record.user_phone
                        : record.optional_phone
                        ? record.optional_phone
                        : "--"}
                    </Text>
                  </div>
                )}
              />
              <Column
                title="Status"
                key="status"
                align="center"
                render={(text, record) => (
                  <Status name={record.status.name} type="order_status" />
                )}
              />
              <Column
                title="Source"
                key="source"
                align="center"
                render={(text, record) => (
                  <Status name={record.source} type="source" />
                )}
              />
              <Column
                title="Price"
                key="price"
                align="center"
                render={(text, record) => (
                  <div>
                    <Text
                      style={{
                        textTransform: "capitalize",
                        whiteSpace: "nowrap",
                      }}
                    >
                      ৳ {record.grand_total}
                    </Text>
                  </div>
                )}
              />
              <Column
                align="center"
                title="Shipping"
                key="price"
                render={(text, record) => (
                  <div>
                    <Tag
                      style={{ textTransform: "capitalize" }}
                      color={record.shipping_method === "By Sea" && "blue"}
                    >
                      {record.shipping_method}
                    </Tag>
                  </div>
                )}
              />
              {type === "cpm" && (
                <Column
                  title="Manager"
                  align="center"
                  key="action"
                  render={(text, record) => (
                    <Select
                      style={{ width: "160px" }}
                      placeholder="Select Manager"
                      loading={Loading}
                      defaultValue={
                        record.manager ? parseInt(record.manager.id) : []
                      }
                      onSelect={(e) => assignPurchaseManager(e, record.id)}
                    >
                      {purchasingEmp.map((p, k) => (
                        <Option value={p.id} key={p.id}>
                          {p.name}
                        </Option>
                      ))}
                    </Select>
                  )}
                />
              )}
            </Table>
            <Drawer
              title={
                order
                  ? `Transactions #${
                      order.order_code ? order.order_code : order.id
                    }`
                  : ""
              }
              visible={order}
              width={"40%"}
              onClose={() => {
                setOrder(null);
              }}
            >
              {order &&
                order.payments.map((p, k) => (
                  <Card
                    style={{ marginBottom: "1rem" }}
                    size="small"
                    bordered={false}
                    key={p.id}
                  >
                    <Descriptions bordered column={2}>
                      <Descriptions.Item label="Payment Method">
                        <Status
                          key={p.id}
                          icon={<DollarOutlined />}
                          name={p.payment_method}
                          type="payment_method"
                        />
                      </Descriptions.Item>
                      <Descriptions.Item label="Payment Date">
                        {moment(p.created_at).format("DD MMM YYYY, hh:mm A")}
                      </Descriptions.Item>
                      <Descriptions.Item label="Order ID">
                        <Button type="link" size="small">
                          #{p.order_code ? p.order_code : p.order_id}
                        </Button>
                      </Descriptions.Item>
                      <Descriptions.Item label="Amount">
                        <Tag color="red">৳ {p.amount}</Tag>
                      </Descriptions.Item>
                      {p.txn_id && (
                        <Descriptions.Item label="TXN ID" span={2}>
                          {p.txn_id}
                        </Descriptions.Item>
                      )}
                      {p.bank_txn_id && (
                        <Descriptions.Item label="Bank TXN ID">
                          {p.txn_id}
                        </Descriptions.Item>
                      )}
                      <Descriptions.Item label="Status">
                        <Tag
                          color="red"
                          style={{ textTransform: "capitalize" }}
                        >
                          {p.status}
                        </Tag>
                      </Descriptions.Item>
                      <Descriptions.Item label="Note">
                        {p.note ? p.note : "-"}
                      </Descriptions.Item>
                    </Descriptions>
                  </Card>
                ))}
            </Drawer>
          </div>
        ) : (
          <Spinner />
        )}
      </div>
    </div>
  );
}
