import axios from "axios";
import getError from "../utils/GetError";
import url from "../utils/url";

export const adminSendOtp = (data, afterAsync) => {
  axios
    .post(url + `/admin/login`, data)
    .then((res) => {
      console.log(res.data);
      afterAsync(true);
    })
    .catch((err) => {
      console.log(err.response);
      afterAsync(false);
    });
};

export const getOrdersByPage = (page, setData, setLoading, filter) => {
  axios
    .post(url + `/admin/customer-order?page=${page}`, filter)
    .then((res) => {
      setData(res.data);
      setLoading(false);
    })
    .catch((err) => {
      console.log(err.response);
      setData([]);
      setLoading(false);
    });
};

export const getTransactionsByPage = (page, setData, setLoading, filter) => {
  axios
    .post(url + `/admin/master-transactions?page=${page}`, filter)
    .then((res) => {
      setData(res.data);
      setLoading(false);
    })
    .catch((err) => {
      console.log(err.response);
      setData([]);
      setLoading(false);
    });
};
export const getOrdersByPageForPrintInvoice = (
  page,
  setData,
  setLoading,
  filter
) => {
  axios
    .post(url + `/admin/print-invoice`, filter)
    .then((res) => {
      setData(res.data);
      setLoading(false);
    })
    .catch((err) => {
      console.log(err.response);
      setData([]);
      setLoading(false);
    });
};
export const getMasterInvoicePrintable = (filter, setData) => {
  axios
    .get(url + `/admin/courier/${filter}`)
    .then((res) => {
      console.log(res.data);
      setData(res.data);
    })
    .catch((err) => {
      console.log(err.response);
      setData([]);
    });
};

export const getPurchasingOrdersByPage = (
  page,
  setData,
  setLoading,
  filter
) => {
  axios
    .post(url + `/admin/purchase/search?page=${page}`, filter)
    .then((res) => {
      setData(res.data);
      setLoading(false);
    })
    .catch((err) => {
      console.log(err.response);
      setData([]);
      setLoading(false);
    });
};
export const getPurchasingOrdersFilter = (page, setData, setLoading) => {
  axios
    .post(url + `/admin/purchase/filter?page=${page}`, { saved: true })
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData([]);
      setLoading(false);
    });
};
export const getTrackingOrdersByPage = (page, setData, setLoading, filter) => {
  console.log(filter);
  axios
    .post(url + `/admin/tracking/search?page=${page}`, filter)
    .then((res) => {
      setData(res.data);
      setLoading(false);
    })
    .catch((err) => {
      console.log(err.response);
      setData([]);
      setLoading(false);
    });
};

export const getSortingOrdersByPage = (page, setData, setLoading, filter) => {
  console.log(filter);
  axios
    .post(url + `/admin/sorting/search?page=${page}`, filter)
    .then((res) => {
      console.log(res.data);
      setData(res.data);
      setLoading(false);
    })
    .catch((err) => {
      console.log(err.response);
      setData([]);
      setLoading(false);
    });
};

export const getOrderById = (id, setData, setLoading) => {
  axios
    .get(url + `/admin/orders/${id}`)
    .then((res) => {
      setData(res.data);
      setLoading(false);
    })
    .catch((err) => {
      setData(null);
      setLoading(false);
    });
};
export const getCourierAreas = (setData) => {
  axios
    .get(url + `/admin/redx/areas`)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData(null);
    });
};
export const getEcourierAreas = (setData) => {
  axios
    .get(url + `/admin/ecourier/all-areas`)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData(null);
    });
};
export const getShipmentOrderByCustomer = (data, setData) => {
  axios
    .post(url + `/admin/shipment/user-orders`, data)
    .then((res) => {
      setData(res.data.data);
    })
    .catch((err) => {
      setData(null);
    });
};
export const getCouriers = (page, setData, filter) => {
  axios
    .post(url + `/admin/courier-filter?page=${page}`, filter)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData(null);
    });
};
export const getCounterPayable = (setData, invoice) => {
  axios
    .get(url + `/admin/accountant/cash-counter/${invoice}`)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData(null);
    });
};

export const updateCourierStatus = (data, afterPerform) => {
  axios
    .post(url + `/admin/courier-cancel-return`, data)
    .then((res) => {
      console.log(res.data);
      afterPerform(true, "Courier Status");
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false, "Courier Status ");
    });
};
export const completeCourier = (data, afterPerform) => {
  axios
    .post(url + `/admin/courier-complete`, data)
    .then((res) => {
      console.log(res.data);
      afterPerform(true, "Courier Status");
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false, "Courier Status ");
    });
};

export const getStats = (setData) => {
  axios
    .get(url + `/admin/stats`)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData(null);
    });
};

export const getCrmStats = (setData, filter) => {
  let filters = filter ? "?" + new URLSearchParams(filter).toString() : "";
  axios
    .get(url + `/admin/crm/order-stats` + filters)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData(null);
    });
};
export const getCustomerStats = (setData) => {
  axios
    .get(url + `/admin/report/customers-wsc-badge`)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData(null);
    });
};
export const getCustomerById = (id, setData) => {
  axios
    .get(url + `/admin/customers/${id}`)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData(null);
    });
};
export const getCustomerOrder = (id, setData, page) => {
  axios
    .post(url + `/admin/customer-order?page=${page}`, { user_id: id })
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      console.log(err.response);
      setData(null);
    });
};
export const getCustomerOrderFilter = (id, setData, page, filter) => {
  axios
    .post(url + `/admin/report/customer-order-filter?page=${page}`, {
      user_id: id,
      filter: filter,
    })
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      console.log(err.response);
      setData(null);
    });
};
export const getCustomerBalanceTransaction = (id, setData, page) => {
  let pageNum = page ? page : 1;
  axios
    .post(url + `/admin/customer-balance-transaction?page=${pageNum}`, {
      user_id: id,
    })
    .then((res) => {
      console.log(res);
      setData(res.data);
    })
    .catch((err) => {
      console.log(err);
      setData(null);
    });
};
export const getCustomerOrderStats = (id, setData) => {
  axios
    .get(url + `/admin/report/customer-order-stats/${id}`)
    .then((res) => {
      console.log(res);
      setData(res.data);
    })
    .catch((err) => {
      console.log(err);
      setData(null);
    });
};

export const getCustomerOrderChart = (id, setData) => {
  axios
    .post(url + `/admin/report/customer-order-chart`, {
      user_id: id,
    })
    .then((res) => {
      console.log(res);
      setData(res.data);
    })
    .catch((err) => {
      console.log(err);
      setData(null);
    });
};
export const getCustomerOrderCategory = (id, setData, filter) => {
  axios
    .post(url + `/admin/report/customer-order-type`, {
      user_id: id,
    })
    .then((res) => {
      console.log(res);
      setData(res.data);
    })
    .catch((err) => {
      console.log(err);
      setData(null);
    });
};
export const getCustomerOderTransaction = (id, setData, page) => {
  let pageNum = page ? page : 1;
  axios
    .post(url + `/admin/customer-order-payments?page=${pageNum}`, {
      user_id: id,
    })
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      console.log(err.response);
      setData(null);
    });
};

export const addTrackingNote = (data, afterPerform) => {
  axios
    .post(url + `/admin/tracking/order-note`, data)
    .then((res) => {
      console.log(res.data);
      afterPerform(true, "Order Note ");
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false, "Order Note ");
    });
};
export const addToCourier = (data, afterPerform) => {
  axios
    .post(url + `/admin/courier`, data)
    .then((res) => {
      if (res.data.invoice_code) {
        afterPerform(true, res.data.invoice_code);
      }
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false, "Order");
    });
};
export const submitCourier = (id, data, afterPerform) => {
  axios
    .put(url + `/admin/courier/${id}`, data)
    .then((res) => {
      afterPerform(true, "Courier ");
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false, "Courier ");
    });
};
export const callCaseRecord = (id, data, afterPerform) => {
  axios
    .put(url + `/admin/cs/case-record/${id}`, data)
    .then((res) => {
      afterPerform(true, "Record ");
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false, "Record ");
    });
};
export const addAdminCsCall = (data, afterPerform) => {
  axios
    .post(url + `/admin/cs/calls`, data)
    .then((res) => {
      afterPerform(true, "Record ");
    })
    .catch((err) => {
      afterPerform(false, "Record ");
    });
};
export const approveBankSlipDeposit = (id, afterPerform) => {
  axios
    .get(url + `/admin/accountant/approve-deposit/${id}`)
    .then((res) => {
      afterPerform(true, "Bank Slip ");
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false, "Bank Slip ");
    });
};
export const reviewPayment = (data, afterPerform) => {
  axios
    .post(url + `/admin/accountant/review-payment`, data)
    .then((res) => {
      afterPerform(true, "");
    })
    .catch((err) => {
      afterPerform(false, getError(err));
    });
};
export const approveAgentBankSlipDeposit = (id, afterPerform) => {
  axios
    .get(url + `/admin/agent/approve-deposit/${id}`)
    .then((res) => {
      afterPerform(true);
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false, getError(err));
    });
};
export const denyAgentBankSlipDeposit = (id, afterPerform) => {
  axios
    .get(url + `/admin/agent/deny-deposit/${id}`)
    .then((res) => {
      afterPerform(true);
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false, getError(err));
    });
};
export const denyBankSlipDeposit = (id, afterPerform) => {
  axios
    .get(url + `/admin/accountant/deny-deposit/${id}`)
    .then((res) => {
      afterPerform(true, "Bank Slip ");
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false, "Bank Slip ");
    });
};
export const approveOrderBankSlipDeposit = (id, afterPerform) => {
  axios
    .get(url + `/admin/accountant/approve-payment/${id}`)
    .then((res) => {
      afterPerform(true, "Bank Slip ");
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false, "Bank Slip ");
    });
};
export const denyOrderBankSlipDeposit = (id, afterPerform) => {
  axios
    .get(url + `/admin/accountant/deny-payment/${id}`)
    .then((res) => {
      afterPerform(true, "Bank Slip ");
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false, "Bank Slip ");
    });
};

export const getUsers = (page, setData) => {
  axios
    .get(url + `/admin/users?page=${page}`)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData([]);
    });
};

export const getCrmManagers = (role, setData) => {
  let filter = role ? `?role=${role}` : `?role=${"crm"}`;
  axios
    .get(url + `/admin/users` + filter)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData([]);
    });
};

export const getAdminsFilter = (setData, filter) => {
  let filters = filter ? "?" + new URLSearchParams(filter).toString() : "";
  axios
    .get(url + `/admin/users` + filters)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData({ data: [] });
    });
};

export const getDepartments = (page, setData) => {
  axios
    .get(url + `/admin/departments`)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData([]);
    });
};
export const getCustomers = (page, setData, setLoading, filter) => {
  let sort = filter ? filter : "";
  let pageNum = filter ? 0 : page;
  console.log(sort);
  axios
    .get(url + `/admin/customers?page=${pageNum + sort}`)
    .then((res) => {
      console.log(res.data);
      setData(res.data);
    })
    .catch((err) => {
      setData({ data: [] });
    });
};
export const getCustomersBySlab = (page, setData, setLoading, filter) => {
  let filters = filter ? "?" + new URLSearchParams(filter).toString() : "";
  axios
    .get(url + `/admin/customers` + filters)
    .then((res) => {
      setData(res.data);
      setLoading(false);
    })
    .catch((err) => {
      setData({ data: [] });
    });
};
export const getCrmCustomers = (filter, setData) => {
  let filters = filter ? "?" + new URLSearchParams(filter).toString() : "";
  axios
    .get(url + `/admin/cs/customers` + filters)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData({ data: [] });
    });
};
export const getMyCrmCustomers = (filter, setData) => {
  let filters = filter ? "?" + new URLSearchParams(filter).toString() : "";
  axios
    .get(url + `/admin/crm/customers` + filters)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData({ data: [] });
    });
};
export const getProcessingCustomers = (filter, setData) => {
  let filters = filter ? "?" + new URLSearchParams(filter).toString() : "";
  axios
    .get(url + `/admin/customer-with-processing-orders` + filters)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData({ data: [] });
    });
};

export const getTopCrmCustomers = (filter, setData) => {
  let filters = filter ? "?" + new URLSearchParams(filter).toString() : "";
  axios
    .get(url + `/admin/crm/top-customers` + filters)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData({ data: [] });
    });
};
export const getCrmPurchase = (filter, setData) => {
  let filters = filter ? "?" + new URLSearchParams(filter).toString() : "";
  axios
    .get(url + `/admin/crm/purchase` + filters)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData(null);
    });
};

export const getMyCrmOrders = (filter, setData) => {
  let filters = filter ? "?" + new URLSearchParams(filter).toString() : "";
  axios
    .get(url + `/admin/crm/customer-orders` + filters)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData({ data: [] });
    });
};

export const getCsCustomersStats = (setData) => {
  // let filters = filter ? "?" + new URLSearchParams(filter).toString() : "";
  axios
    .get(url + `/admin/cs/stats`)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData({ data: [] });
    });
};

export const getSettings = (filter, setData) => {
  let filters = filter ? "?" + new URLSearchParams(filter).toString() : "";
  axios
    .get(url + `/admin/settings` + filters)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData({ data: [] });
    });
};
export const updateSetting = (id, data, afterPerform) => {
  axios
    .put(url + `/admin/settings/${id}`, data)
    .then((res) => {
      afterPerform(true, "");
    })
    .catch((err) => {
      afterPerform(false, getError(err));
    });
};
export const getCustomersByBadge = (page, setData, setLoading, filter) => {
  let filters = filter ? "?" + new URLSearchParams(filter).toString() : "";
  axios
    .get(url + `/admin/customer-filter` + filters)
    .then((res) => {
      setData(res.data);
      setLoading(false);
    })
    .catch((err) => {
      setData({ data: [] });
    });
};
export const getPriorityCustomers = (page, setData, filter) => {
  let sort = filter ? filter : "";
  let pageNum = filter ? 0 : page;
  axios
    .get(url + `/admin/priority/customers?page=${pageNum + sort}`)
    .then((res) => {
      console.log(res.data);
      setData(res.data);
    })
    .catch((err) => {
      setData({ data: [] });
    });
};
export const getCustomerProduct = (id, setData) => {
  axios
    .get(url + `/admin/report/customer-order-product?user_id=${id}`)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData({ data: [] });
    });
};
export const getProducts = (page, setData, setLoading, filter) => {
  let sort = filter ? filter : "";
  let pageNum = filter ? 0 : page;
  console.log(sort);
  axios
    .get(url + `/admin/products?page=${pageNum + sort}`)
    .then((res) => {
      if (res.data) setData(res.data);
    })
    .catch((err) => {
      setData({ data: [] });
    });
};
export const getBestSellingProducts = (page, setData) => {
  let pageNum = page ? page : 1;
  axios
    .get(url + `/product/best-seller?page=${pageNum}`)
    .then((res) => {
      if (res.data) setData(res.data);
    })
    .catch((err) => {
      setData({ data: [] });
    });
};
export const getProduct = (id, setData) => {
  axios
    .get(url + `/admin/products/${id}`)
    .then((res) => {
      if (res.data && res.data.data) setData(res.data.data);
    })
    .catch((err) => {
      setData(null);
    });
};
export const unlockOrder = (id, afterPerform) => {
  axios
    .get(url + `/admin/unlock-order/${id}`)
    .then((res) => {
      afterPerform(true);
    })
    .catch((err) => {
      afterPerform(false);
    });
};
export const getRefunds = (setData) => {
  axios
    .get(url + `/admin/accountant/user-refunds`)
    .then((res) => {
      console.log(res.data);
      setData(res.data);
    })
    .catch((err) => {
      setData({ data: [] });
    });
};
export const getBankBalanceDeposit = (page, setData, setLoading, filter) => {
  let pageNum = page;
  axios
    .post(url + `/admin/accountant/balance-deposits?page=${pageNum}`, filter)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData({ data: [] });
    });
};
export const bankSlipPaymentUpdate = (id, data, afterPerform) => {
  axios
    .put(url + `/admin/accountant/update-payment/${id}`, data)
    .then((res) => {
      afterPerform(true, "");
    })
    .catch((err) => {
      afterPerform(false, getError(err));
    });
};
export const bankSlipDepositUpdate = (id, data, afterPerform) => {
  axios
    .put(url + `/admin/accountant/update-deposit/${id}`, data)
    .then((res) => {
      afterPerform(true, "");
    })
    .catch((err) => {
      afterPerform(false, getError(err));
    });
};
export const getBankPaymentDeposit = (page, setData, setLoading, filter) => {
  let pageNum = page;
  axios
    .post(url + `/admin/accountant/bank-payments?page=${pageNum}`, filter)
    .then((res) => {
      console.log(res.data);
      setData(res.data);
    })
    .catch((err) => {
      setData({ data: [] });
    });
};
export const getCoupons = (page, setData, agent_id) => {
  let mUrl = `/admin/coupon?page=${page}`;
  if (agent_id) {
    mUrl = mUrl + `&agent_id=${agent_id}`;
  }
  axios
    .get(url + mUrl)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData([]);
    });
};
export const getCampaigns = (page, setData) => {
  axios
    .get(url + `/admin/campaigns?page=${page}`)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData([]);
    });
};
export const getSeminars = (filter, setData) => {
  let filters = filter ? "?" + new URLSearchParams(filter).toString() : "";
  axios
    .get(url + `/admin/seminars` + filters)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData([]);
    });
};
export const getSeminarUsers = (filter, setData) => {
  let filters = filter ? "?" + new URLSearchParams(filter).toString() : "";
  axios
    .get(url + `/admin/seminar-users` + filters)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData([]);
    });
};
export const getFieldVisits = (filter, setData) => {
  let filters = filter ? "?" + new URLSearchParams(filter).toString() : "";
  axios
    .get(url + `/admin/field-visits` + filters)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData([]);
    });
};

export const getAdvance = (filter, setData) => {
  let filters = filter ? "?" + new URLSearchParams(filter).toString() : "";
  axios
    .get(url + `/admin/account/balances` + filters)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData([]);
    });
};
export const getAdvanceLogs = (filter, setData) => {
  let filters = filter ? "?" + new URLSearchParams(filter).toString() : "";
  axios
    .get(url + `/admin/account/logs` + filters)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData([]);
    });
};

export const receiveAdvance = (data, afterPerform) => {
  axios
    .post(url + `/admin/account/credit-balance`, data)
    .then((res) => {
      afterPerform(true, "success");
    })
    .catch((err) => {
      afterPerform(false, getError(err));
      // setData({ data: [] });
    });
};
export const debitBalance = (data, afterPerform) => {
  axios
    .post(url + `/admin/account/debit-balance`, data)
    .then((res) => {
      afterPerform(true, "success");
    })
    .catch((err) => {
      afterPerform(false, getError(err));
      // setData({ data: [] });
    });
};

export const getPotentialCustomer = (tab, filter, setData) => {
  let filters = filter ? "?" + new URLSearchParams(filter).toString() : "";
  if (tab) {
    filters =
      filters.length > 0
        ? filters + "&" + tab + "=1"
        : filters + "?" + tab + "=1";
  }
  axios
    .get(url + `/admin/marketing/potential-customers` + filters)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData([]);
    });
};
export const getCampaignCustomer = (filter, setData) => {
  let filters = filter ? "?" + new URLSearchParams(filter).toString() : "";
  axios
    .get(url + `/admin/marketing/customer-campaign` + filters)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData([]);
    });
};
export const getChecklists = (filter, setData) => {
  let filters = filter ? "?" + new URLSearchParams(filter).toString() : "";
  axios
    .get(url + `/admin/purchase/my-order-checklist` + filters)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData([]);
    });
};
export const getZones = (filter, setData) => {
  let filters = filter ? "?" + new URLSearchParams(filter).toString() : "";
  axios
    .get(url + `/admin/agent/zones` + filters)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData([]);
    });
};
export const getAgents = (filter, setData) => {
  let filters = filter ? "?" + new URLSearchParams(filter).toString() : "";
  axios
    .get(url + `/admin/agent/agents` + filters)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData([]);
    });
};
export const getAgentBalance = (filter, setData) => {
  let filters = filter ? "?" + new URLSearchParams(filter).toString() : "";
  axios
    .get(url + `/admin/agent/balance` + filters)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData([]);
    });
};
export const getAgentWithdrawRequests = (filter, setData) => {
  let filters = filter ? "?" + new URLSearchParams(filter).toString() : "";
  axios
    .get(url + `/admin/agent/withdraw-requests` + filters)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData([]);
    });
};
export const getTargets = (filter, setData) => {
  let filters = filter ? "?" + new URLSearchParams(filter).toString() : "";
  axios
    .get(url + `/admin/marketing/targets` + filters)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData([]);
    });
};
export const getMarketingLogs = (filter, setData) => {
  let filters = filter ? "?" + new URLSearchParams(filter).toString() : "";
  axios
    .get(url + `/admin/report/marketing-logs` + filters)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData([]);
    });
};
export const getMarketingTargetStatus = (filter, setData) => {
  let filters = filter ? "?" + new URLSearchParams(filter).toString() : "";
  axios
    .get(url + `/admin/report/marketing-target-status` + filters)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData([]);
    });
};
export const getOfficeVisits = (filter, setData) => {
  let filters = filter ? "?" + new URLSearchParams(filter).toString() : "";
  axios
    .get(url + `/admin/office-visits` + filters)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData([]);
    });
};
export const getSeminarById = (id, setData) => {
  axios
    .get(url + `/admin/seminars/${id}`)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData([]);
    });
};
export const getCampaignProducts = (id, page, setData) => {
  axios
    .get(url + `/campaigns/${id}?page=${page}`)
    .then((res) => {
      console.log(res.data);
      setData(res.data);
    })
    .catch((err) => {
      setData({ data: [] });
    });
};
export const getCampaignProductsByCategory = (data, page, setData) => {
  axios
    .post(url + `/admin/campaign-category-product?page=${page}`, data)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData({ data: [] });
    });
};
export const getReceivedCartonList = (id, setData) => {
  // fetch(url + `/admin/shipbaz/get-shipment-received-cartons/${id}`)
  //   .then((response) => response.json())
  //   .then((data) => setData(data));

  axios
    .get(url + `/admin/shipbaz/get-shipment-received-cartons/${id}`)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData({ data: [] });
    });
};

export const getRoles = (setData, afterPerform) => {
  axios
    .get(url + `/admin/roles`)
    .then((res) => {
      console.log(res.data);
      setData(res.data);
      if (afterPerform && res.data.roles && res.data.roles.length > 0) {
        let id = res.data.roles[0].id;
        afterPerform(id);
      }
    })
    .catch((err) => {
      setData([]);
    });
};
export const getPermissions = (setData) => {
  axios
    .get(url + `/admin/permissions`)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData([]);
    });
};
export const getRoleById = (id, setData) => {
  axios
    .get(url + `/admin/roles/${id}`)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData([]);
    });
};
export const getFees = (page, setData) => {
  axios
    .get(url + `/admin/fee?page=${page}`)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData([]);
    });
};
export const getCampaignCategories = (id, setData) => {
  axios
    .get(url + `/admin/campaign-categories/${id}`)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData([]);
    });
};
export const getPurchasingStats = (setData, filter) => {
  axios
    .get(url + `/admin/purchase/stats` + filter)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData(null);
    });
};

export const getCrmPurchasingStats = (setData, filter) => {
  axios
    .get(url + `/admin/crm/purchase-stats` + filter)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData(null);
    });
};

export const getDeliveryOrders = (page, setData) => {
  axios
    .get(url + `/admin/delivery/stats?page=${page}`)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData([]);
    });
};
export const getShipmentOrders = (filter, setData) => {
  axios
    .post(url + `/admin/shipment/orders`, filter)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData([]);
    });
};
export const getCrmShipmentOrders = (filter, setData) => {
  axios
    .post(url + `/admin/crm/shipment-orders`, filter)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData([]);
    });
};
export const getShipmentList = (setData) => {
  axios
    .get(url + `/admin/shipment/list`)
    .then((res) => {
      setData(res.data.reverse());
    })
    .catch((err) => {
      console.log(err.response);
      setData([]);
    });
};
export const getPurchasingOrders = (page, setData) => {
  axios
    .get(url + `/admin/purchase/orders?page=${page}`)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData([]);
    });
};
export const getTrackingOrders = (filter, setData) => {
  let filters = filter ? "?" + new URLSearchParams(filter).toString() : "";
  axios
    .get(url + `/admin/tracking/orders` + filters)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData([]);
    });
};

export const getCrmTickets = (filter, setData) => {
  let filters = filter ? "?" + new URLSearchParams(filter).toString() : "";
  axios
    .get(url + `/admin/crm/tickets` + filters)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData([]);
    });
};

export const getMyPurchasingOrders = (page, setData) => {
  axios
    .get(url + `/admin/purchase/my-orders?page=${page}`)
    .then((res) => {
      console.log(res.data);
      setData(res.data);
    })
    .catch((err) => {
      setData([]);
    });
};
export const getMyCompletedOrders = (page, setData) => {
  axios
    .get(url + `/admin/purchase/my-completed-orders?page=${page}`)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData([]);
    });
};
export const getCampaignOrders = (page, setData) => {
  axios
    .get(url + `/admin/purchase/campaign-orders?page=${page}`)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData([]);
    });
};
export const getPurchasingCustomerList = (page, setData, filter) => {
  axios
    .post(url + `/admin/purchase/customers?page=${page}`, filter)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData([]);
    });
};
export const getSupportCategories = (page, setData) => {
  axios
    .get(url + `/admin/support/category?page=${page}`)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData([]);
    });
};
export const getSupportStats = (setData, isCrm) => {
  let value = "";
  if (isCrm) {
    value = "?crm=true";
  }

  axios
    .get(url + `/admin/support/stats` + value)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData([]);
    });
};
export const getTickets = (page, setData, dept) => {
  let ext = `/admin/support/tickets?page=${page}`;
  if (dept) {
    ext = `/admin/support/tickets?page=${page}${dept}`;
  }
  axios
    .get(url + ext)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData([]);
    });
};
export const getTicketById = (id, setData) => {
  axios
    .get(url + `/admin/support/tickets/${id}`)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData([]);
    });
};

export const addTicketMessage = (data, afterPerform) => {
  axios
    .post(url + `/admin/support/conversation`, data)
    .then((res) => {
      afterPerform(true, "success");
    })
    .catch((err) => {
      afterPerform(false, getError(err));
    });
};
export const approveAgentCommission = (id, data, afterPerform) => {
  axios
    .post(url + `/admin/agent/approve-withdraw/${id}`, data)
    .then((res) => {
      afterPerform(true, "success");
    })
    .catch((err) => {
      afterPerform(false, getError(err));
    });
};
export const denyAgentCommission = (id, data, afterPerform) => {
  axios
    .post(url + `/admin/agent/deny-withdraw/${id}`, data)
    .then((res) => {
      afterPerform(true, "success");
    })
    .catch((err) => {
      afterPerform(false, getError(err));
    });
};

export const updateAdminUser = (data, page, setData, afterPerform) => {
  axios
    .post(url + `/admin/update-admin-profile`, data)
    .then((res) => {
      getUsers(page, setData);
      afterPerform(true);
      console.log(res.data);
    })
    .catch((err) => {
      afterPerform(false);
    });
};
export const updateAgent = (id, data, afterPerform) => {
  axios
    .post(url + `/admin/agent/agents/${id}`, data)
    .then((res) => {
      afterPerform(true);
      console.log(res.data);
    })
    .catch((err) => {
      afterPerform(false);
    });
};
export const updateShippingRate = (order_id, data, afterPerform) => {
  axios
    .post(url + `/admin/purchase/update-shipping-rate/${order_id}`, data)
    .then((res) => {
      afterPerform(true);
    })
    .catch((err) => {
      afterPerform(false, getError(err));
    });
};
export const swapDeliveryStatus = (orderIDs, currentStatus, afterPerform) => {
  console.log("hit");
  axios
    .post(url + `/admin/sorting/switch`, {
      status_id: currentStatus,
      order_ids: orderIDs,
    })
    .then((res) => {
      console.log(res.data);
      if (res.data) {
        afterPerform(currentStatus === 19 ? 18 : 19);
      }
    })
    .catch((err) => {
      afterPerform(false);
    });
};
export const updateTracking = (id, data, afterPerform) => {
  axios
    .put(url + `/admin/tracking/orders/${id}`, data)
    .then((res) => {
      afterPerform(true, "Tracking ");
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false, "Tracking ");
    });
};
export const updateProduct = (id, data, afterPerform) => {
  axios
    .put(url + `/admin/products/${id}`, data)
    .then((res) => {
      afterPerform(true);
    })
    .catch((err) => {
      afterPerform(false);
    });
};
export const completeRefund = (data, afterPerform) => {
  axios
    .post(url + `/admin/accountant/complete-refunds`, data)
    .then((res) => {
      afterPerform(true, "Refund ");
    })
    .catch((err) => {
      afterPerform(false, "Refund ");
    });
};
export const cancelPendingWithDeposit = (id, afterPerform) => {
  axios
    .get(url + `/admin/cancel-pending-with-deposit/${id}`)
    .then((res) => {
      afterPerform(true, "Order ");
    })
    .catch((err) => {
      afterPerform(false, getError(err));
    });
};
export const deleteTracking = (id, afterPerform) => {
  axios
    .delete(url + `/admin/tracking/orders/${id}`)
    .then((res) => {
      console.log(res.data);
      afterPerform(true, "Tracking ");
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false, "Tracking ");
    });
};
export const closeTicket = (id, afterPerform) => {
  axios
    .get(url + `/admin/support/close-ticket/${id}`)
    .then((res) => {
      afterPerform(true);
    })
    .catch((err) => {
      afterPerform(false, getError(err));
    });
};

export const updateCustomer = (id, data, page, setData, afterPerform) => {
  axios
    .post(url + `/admin/customers/${id}`, data)
    .then((res) => {
      getCustomers(page, setData);
      afterPerform(true);
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false);
    });
};

export const updateCustomerCrm = (id, data, afterPerform) => {
  axios
    .post(url + `/admin/customers/${id}`, data)
    .then((res) => {
      afterPerform(true);
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false);
    });
};

export const updatePriorityCustomer = (id, data, afterPerform) => {
  axios
    .put(url + `/admin/priority/customers/${id}`, data)
    .then((res) => {
      afterPerform(true);
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false);
    });
};
export const deletePriorityCustomer = (id, afterPerform) => {
  axios
    .delete(url + `/admin/priority/customers/${id}`)
    .then((res) => {
      afterPerform(true);
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false);
    });
};

export const updateAdminUserPermission = (
  data,
  page,
  setData,
  afterPerform
) => {
  axios
    .post(url + `/admin/assign-staff-role`, data)
    .then((res) => {
      console.log(res.data);
      getUsers(page, setData);
      afterPerform(true);
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false);
    });
};
export const debitCreditTransaction = (
  data,
  filter,
  setCustomer,
  afterPerform
) => {
  axios
    .post(url + `/admin/create-balance-transaction`, data)
    .then((res) => {
      getCustomers(0, setCustomer, () => {}, filter);
      afterPerform(true);
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false);
    });
};

export const addAdminUser = (data, page, setData, afterPerform) => {
  axios
    .post(url + `/admin/create-admin`, data)
    .then((res) => {
      getUsers(page, setData);
      afterPerform(true);
    })
    .catch((err) => {
      afterPerform(false);
    });
};
export const addCustomer = (data, page, setData, afterPerform) => {
  axios
    .post(url + `/admin/customers`, data)
    .then((res) => {
      getCustomers(page, setData);
      afterPerform(true);
    })
    .catch((err) => {
      afterPerform(false);
    });
};
export const addSeminarUser = (data, afterPerform) => {
  axios
    .post(url + `/admin/seminar-users`, data)
    .then((res) => {
      afterPerform(true);
    })
    .catch((err) => {
      afterPerform(false);
    });
};
export const addFieldVisit = (data, afterPerform) => {
  axios
    .post(url + `/admin/field-visits`, data)
    .then((res) => {
      afterPerform(true, "");
    })
    .catch((err) => {
      afterPerform(false, getError(err));
    });
};
export const addPotentialCustomer = (data, afterPerform) => {
  axios
    .post(url + `/admin/marketing/potential-customers`, data)
    .then((res) => {
      afterPerform(true, "");
    })
    .catch((err) => {
      afterPerform(false, getError(err));
    });
};
export const addToCustomerCampaign = (data, afterPerform) => {
  axios
    .post(url + `/admin/marketing/customer-campaign`, data)
    .then((res) => {
      afterPerform(true, "");
    })
    .catch((err) => {
      afterPerform(false, getError(err));
    });
};
export const addAgent = (data, afterPerform) => {
  axios
    .post(url + `/admin/agent/agents`, data)
    .then((res) => {
      afterPerform(true, "");
    })
    .catch((err) => {
      afterPerform(false, getError(err));
    });
};
export const addZone = (data, afterPerform) => {
  axios
    .post(url + `/admin/agent/zones`, data)
    .then((res) => {
      afterPerform(true, "");
    })
    .catch((err) => {
      afterPerform(false, getError(err));
    });
};
export const addTarget = (data, afterPerform) => {
  axios
    .post(url + `/admin/marketing/targets`, data)
    .then((res) => {
      afterPerform(true);
    })
    .catch((err) => {
      afterPerform(false);
    });
};
export const addOfficeVisit = (data, afterPerform) => {
  axios
    .post(url + `/admin/office-visits`, data)
    .then((res) => {
      afterPerform(true);
    })
    .catch((err) => {
      afterPerform(false);
    });
};
export const updateSeminarUser = (id, data, afterPerform) => {
  axios
    .put(url + `/admin/seminar-users/${id}`, data)
    .then((res) => {
      afterPerform(true);
    })
    .catch((err) => {
      afterPerform(false);
    });
};
export const updateFieldVisit = (id, data, afterPerform) => {
  axios
    .post(url + `/admin/field-visits/${id}`, data)
    .then((res) => {
      afterPerform(true, "");
    })
    .catch((err) => {
      afterPerform(false, getError(err));
    });
};
export const updatePotentialCustomer = (id, data, afterPerform) => {
  axios
    .post(url + `/admin/marketing/potential-customers/${id}`, data)
    .then((res) => {
      afterPerform(true, "");
    })
    .catch((err) => {
      afterPerform(false, getError(err));
    });
};
export const updateCampaignCustomer = (id, data, afterPerform) => {
  axios
    .post(url + `/admin/marketing/customer-campaign/${id}`, data)
    .then((res) => {
      afterPerform(true, "");
    })
    .catch((err) => {
      afterPerform(false, getError(err));
    });
};
export const updateZone = (id, data, afterPerform) => {
  axios
    .put(url + `/admin/agent/zones/${id}`, data)
    .then((res) => {
      afterPerform(true, "");
    })
    .catch((err) => {
      afterPerform(false, getError(err));
    });
};
export const updateTarget = (id, data, afterPerform) => {
  axios
    .put(url + `/admin/marketing/targets/${id}`, data)
    .then((res) => {
      afterPerform(true);
    })
    .catch((err) => {
      afterPerform(false);
    });
};
export const updateOfficeVisit = (id, data, afterPerform) => {
  axios
    .put(url + `/admin/office-visits/${id}`, data)
    .then((res) => {
      afterPerform(true);
    })
    .catch((err) => {
      afterPerform(false);
    });
};
export const addPriorityCustomer = (data, afterPerform) => {
  axios
    .post(url + `/admin/priority/customers`, data)
    .then((res) => {
      afterPerform(true);
    })
    .catch((err) => {
      afterPerform(false);
    });
};
export const addOrderFee = (data, setData, afterPerform) => {
  axios
    .post(url + `/admin/order-fee`, data)
    .then((res) => {
      getOrderById(data.order_id, setData, () => {});
      afterPerform(true);
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false);
    });
};
export const revokeAdminAccess = (data, afterPerform) => {
  axios
    .post(url + `/admin/revoke-access`, data)
    .then((res) => {
      afterPerform(true);
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false);
    });
};

export const addUserMeta = (data, afterPerform) => {
  axios
    .post(url + `/admin/user-meta`, data)
    .then((res) => {
      afterPerform(true);
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false);
    });
};
export const addCashRefundNote = (data, afterPerform) => {
  axios
    .post(url + `/admin/accountant/cash-refund`, data)
    .then((res) => {
      afterPerform(true);
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false);
    });
};
export const updateUserMeta = (id, data, afterPerform) => {
  axios
    .put(url + `/admin/user-meta/${id}`, data)
    .then((res) => {
      afterPerform(true);
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false);
    });
};

export const deleteUserMeta = (id, afterPerform) => {
  axios
    .delete(url + `/admin/user-meta/${id}`)
    .then((res) => {
      afterPerform(true);
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false);
    });
};

export const applyCoupon = (data, afterPerform) => {
  axios
    .post(url + `/admin/coupon/apply`, data)
    .then((res) => {
      afterPerform(true);
    })
    .catch((err) => {
      afterPerform(false);
    });
};
export const sendCustomerMessage = (data, afterPerform) => {
  axios
    .post(url + `/admin/send-sms`, data)
    .then((res) => {
      afterPerform(true);
    })
    .catch((err) => {
      afterPerform(false);
    });
};
export const deleteOrderFee = (id, order_id, setData, afterPerform) => {
  axios
    .delete(url + `/admin/order-fee/${id}`)
    .then((res) => {
      getOrderById(order_id, setData, () => {});
      afterPerform(true);
    })
    .catch((err) => {
      afterPerform(false);
    });
};
export const updateOrderFee = (id, order_id, data, setData, afterPerform) => {
  axios
    .put(url + `/admin/order-fee/${id}`, data)
    .then((res) => {
      getOrderById(order_id, setData, () => {});
      afterPerform(true);
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false);
    });
};
export const updateOrderItem = (id, order_id, data, setData, afterPerform) => {
  axios
    .post(url + `/admin/update-order-item/${id}`, data)
    .then((res) => {
      getOrderById(order_id, setData, () => {});
      afterPerform(true);
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false);
    });
};
export const addCoupon = (data, page, setData, afterPerform) => {
  axios
    .post(url + `/admin/coupon`, data)
    .then((res) => {
      getCoupons(page, setData);
      afterPerform(true);
    })
    .catch((err) => {
      afterPerform(false);
    });
};
export const addCampaigns = (data, page, setData, afterPerform) => {
  axios
    .post(url + `/admin/campaigns`, data)
    .then((res) => {
      getCampaigns(page, setData);
      afterPerform(true);
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false);
    });
};

export const addSeminar = (data, afterPerform) => {
  axios
    .post(url + `/admin/seminars`, data)
    .then((res) => {
      afterPerform(true);
    })
    .catch((err) => {
      afterPerform(false);
    });
};

export const addToCampaigns = (data, afterPerform) => {
  axios
    .post(url + `/admin/add-to-campaign`, data)
    .then((res) => {
      afterPerform(true);
    })
    .catch((err) => {
      afterPerform(false);
    });
};
export const addFee = (data, page, setData, afterPerform) => {
  axios
    .post(url + `/admin/fee`, data)
    .then((res) => {
      getFees(page, setData);
      afterPerform(true);
    })
    .catch((err) => {
      afterPerform(false);
    });
};
export const addCampaignCategory = (data, afterPerform) => {
  axios
    .post(url + `/admin/campaign-categories`, data)
    .then((res) => {
      afterPerform(true);
    })
    .catch((err) => {
      afterPerform(false);
    });
};
export const assignOrderPurchaseManager = (data, afterPerform) => {
  axios
    .post(url + `/admin/purchase/assign-manager`, data)
    .then((res) => {
      afterPerform(true);
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false);
    });
};
export const addOrderNote = (data, setData, afterPerform) => {
  axios
    .post(url + `/admin/order-note`, data)
    .then((res) => {
      getOrderById(data.order_id, setData, () => {});
      afterPerform(true);
    })
    .catch((err) => {
      afterPerform(false);
    });
};
export const addAccountantPayment = (data, setData, afterPerform) => {
  axios
    .post(url + `/admin/accountant/add-payment`, data)
    .then((res) => {
      getOrderById(data.order_id, setData, () => {});
      afterPerform(true);
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false);
    });
};
export const addSupportCategory = (data, page, setData, afterPerform) => {
  axios
    .post(url + `/admin/support/category`, data)
    .then((res) => {
      getSupportCategories(page, setData);
      afterPerform(true);
    })
    .catch((err) => {
      afterPerform(false);
    });
};
export const addTicket = (data, afterPerform) => {
  axios
    .post(url + `/admin/support/tickets`, data)
    .then((res) => {
      afterPerform(true);
    })
    .catch((err) => {
      afterPerform(false);
    });
};
export const updateCoupon = (id, data, page, setData, afterPerform) => {
  axios
    .put(url + `/admin/coupon/${id}`, data)
    .then((res) => {
      getCoupons(page, setData);
      afterPerform(true);
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false);
    });
};
export const updateCampaign = (id, data, page, setData, afterPerform) => {
  axios
    .put(url + `/admin/campaigns/${id}`, data)
    .then((res) => {
      getCampaigns(page, setData);
      afterPerform(true);
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false);
    });
};
export const updateSeminar = (id, data, afterPerform) => {
  axios
    .put(url + `/admin/seminars/${id}`, data)
    .then((res) => {
      afterPerform(true);
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false);
    });
};
export const deleteCampaign = (id, page, setData, afterPerform) => {
  axios
    .delete(url + `/admin/campaigns/${id}`)
    .then((res) => {
      getCampaigns(page, setData);
      afterPerform(true);
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false);
    });
};

export const updateCampaignProductCron = (id, afterPerform) => {
  axios
    .get(
      url +
        `/cron/update-campaign-product/${id}?secret=0ef942b0dba8e7f721ac63a3ef31c1411ff56105266b3128a3cac7f6b5eed10a1ea913fda29d37fb`
    )
    .then((res) => {
      afterPerform(true);
    })
    .catch((err) => {
      afterPerform(false, getError(err));
    });
};
export const autoImportDailyProductCron = (afterPerform) => {
  axios
    .get(
      url +
        `/cron/auto-import-campaign?secret=lfkdsjs9843yhfb92834rhbbv82743fbJjbdsiufNI7HJbVGGjhd98324y98K`
    )
    .then((res) => {
      afterPerform(true);
    })
    .catch((err) => {
      afterPerform(false, getError(err));
    });
};

export const deleteCampaignProduct = (id, afterPerform) => {
  axios
    .delete(url + `/admin/remove-product/${id}`)
    .then((res) => {
      afterPerform(true);
    })
    .catch((err) => {
      afterPerform(false);
    });
};
export const updateAdminOrder = (id, data, setData, afterPerform) => {
  axios
    .put(url + `/admin/orders/${id}`, data)
    .then((res) => {
      getOrderById(id, setData, () => {});
      afterPerform(true);
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false);
    });
};
export const userSwitching = (data, setLoading) => {
  axios
    .post(url + `/admin/switch-user`, data)
    .then((res) => {
      console.log(res.data);
      setLoading(false);
      let data = JSON.stringify(res.data.success);
      let base64 = btoa(data);
      window.open("https://wholesalecart.com/?agltoken=" + base64);
    })
    .catch((err) => {
      console.log(err.response);
      setLoading(false);
    });
};
export const updatePurchaseOrder = (id, data, setData, afterPerform) => {
  axios
    .post(url + `/admin/purchase/update-orders/${id}`, data)
    .then((res) => {
      getOrderById(id, setData, () => {});
      afterPerform(true);
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false);
    });
};
export const updateAccountOrder = (id, data, setData, afterPerform) => {
  axios
    .post(url + `/admin/accountant/order-update`, data)
    .then((res) => {
      getOrderById(id, setData, () => {});
      afterPerform(true);
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false);
    });
};
export const completePurchase = (id, data, setData, afterPerform) => {
  axios
    .post(url + `/admin/purchase/complete-purchase/`, data)
    .then((res) => {
      getOrderById(id, setData, () => {});
      afterPerform(true);
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false);
    });
};
export const cashCounterPayment = (data, afterPerform) => {
  axios
    .post(url + `/admin/accountant/cash-counter`, data)
    .then((res) => {
      afterPerform(true);
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false);
    });
};
export const completeSorting = (id, data, setData, afterPerform) => {
  axios
    .post(url + `/admin/sorting/complete/`, data)
    .then((res) => {
      getOrderById(id, setData, () => {});
      afterPerform(true);
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false);
    });
};
export const completeSortingFromShipmentHub = (data, afterPerform) => {
  axios
    .post(url + `/admin/sorting/complete/`, data)
    .then((res) => {
      afterPerform(true);
    })
    .catch((err) => {
      afterPerform(false);
    });
};

export const updateFee = (id, data, page, setData, afterPerform) => {
  axios
    .put(url + `/admin/fee/${id}`, data)
    .then((res) => {
      getFees(page, setData);
      afterPerform(true);
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false);
    });
};
export const updateCampaignCategory = (id, data, afterPerform) => {
  axios
    .put(url + `/admin/campaign-categories/${id}`, data)
    .then((res) => {
      console.log(res);
      afterPerform(true);
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false);
    });
};
export const updateSupportCategory = (
  id,
  data,
  page,
  setData,
  afterPerform
) => {
  axios
    .put(url + `/admin/support/category/${id}`, data)
    .then((res) => {
      getSupportCategories(page, setData);
      afterPerform(true);
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false);
    });
};
export const updateTicket = (id, data, afterPerform) => {
  axios
    .post(url + `/admin/support/tickets/${id}`, data)
    .then((res) => {
      afterPerform(true);
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false);
    });
};
export const updateRolePermission = (id, data, afterPerform) => {
  axios
    .put(url + `/admin/roles/${id}`, data)
    .then((res) => {
      afterPerform(true);
    })
    .catch((err) => {
      console.log(err.response);
      afterPerform(false);
    });
};
export const getMyProfile = (setData) => {
  axios
    .get(url + `/admin/profile`)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData(null);
    });
};

export const updateMyProfile = (data, setData, afterAsync) => {
  axios
    .post(url + `/admin/update-own-profile`, data)
    .then((res) => {
      getMyProfile(setData);
      afterAsync(true);
    })
    .catch((err) => {
      console.log(err.response);
      afterAsync(false);
    });
};
export const getReport = (filter, setData) => {
  axios
    .get(url + `/admin/reports?${filter}`)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData(null);
    });
};
export const getCrmReport = (filter, setData) => {
  axios
    .get(url + `/admin/crm/admin-overview?${filter}`)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData(null);
    });
};

export const getCrmOrderReport = (filter, setData) => {
  axios
    .get(url + `/admin/crm/order-reports?${filter}`)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData(null);
    });
};
export const getAgentOrderReport = (filter, setData) => {
  axios
    .get(url + `/admin/agent/order-stats?${filter}`)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData(null);
    });
};

export const getOrderCompleteTimeReport = (filter, setData) => {
  axios
    .get(url + `/admin/report/order-completed-time`)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData(null);
    });
};
export const getDailyCustomerReport = (filter, setData) => {
  axios
    .post(url + `/admin/report/daily-registered-customers?${filter}`)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData(null);
    });
};
export const getYearlyCustomerReport = (filter, setData) => {
  axios
    .post(url + `/admin/report/monthly-registered-customers`, filter)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData(null);
    });
};
export const getTopCustomerReport = (filter, setData) => {
  axios
    .post(url + `/admin/report/top-customers`, filter)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData(null);
    });
};
export const getPurchasingStatsReport = (filter, setData) => {
  axios
    .post(url + `/admin/report/purchase-details-stats`, filter)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData(null);
    });
};
export const getDeliveryReport = (filter, setData) => {
  let filters = filter ? "?" + new URLSearchParams(filter).toString() : "";
  axios
    .get(url + `/admin/report/order-sorting-stats`)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData(null);
    });
};

export const getCrmIncentives = (filter, setData) => {
  let filters = filter ? "?" + new URLSearchParams(filter).toString() : "";
  axios
    .get(url + `/admin/crm/incentive` + filters)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData(null);
    });
};

export const getDeliveryTimeReport = (filter, setData) => {
  let filters = filter ? "?" + new URLSearchParams(filter).toString() : "";
  axios
    .get(url + `/admin/report/order-timeline-stats` + filters)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData(null);
    });
};
export const getCustomerOrderTimelineReport = (filter, setData) => {
  let filters = filter ? "?" + new URLSearchParams(filter).toString() : "";
  axios
    .get(url + `/admin/report/customer-order-timeline` + filters)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData(null);
    });
};
export const getCaseRecords = (setData, id) => {
  axios
    .get(url + `/admin/cs/case-record/${id}`)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData(null);
    });
};
export const getCaseCategories = (setData) => {
  axios
    .get(url + `/admin/cs/case-type/`)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData(null);
    });
};
export const getAdminCsCalls = (setData, filter) => {
  let filters = filter ? "?" + new URLSearchParams(filter).toString() : "";
  axios
    .get(url + `/admin/cs/calls` + filters)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData(null);
    });
};

export const getCrmCallLogs = (setData, filter) => {
  let filters = filter ? "?" + new URLSearchParams(filter).toString() : "";
  axios
    .get(url + `/admin/crm/call-logs` + filters)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData(null);
    });
};

export const getAgentOrders = (setData, filter) => {
  let filters = filter ? "?" + new URLSearchParams(filter).toString() : "";
  axios
    .get(url + `/admin/agent/orders` + filters)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData(null);
    });
};

export const updateCaseCategories = (id, data, afterPerform) => {
  axios
    .put(url + `/admin/cs/case-type/${id}`, data)
    .then((res) => {
      afterPerform(true);
    })
    .catch((err) => {
      afterPerform(false, getError(err));
    });
};
export const getProfitReport = (filter, setData) => {
  axios
    .get(url + `/admin/report/profit-report` + filter)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData(null);
    });
};
export const getSlabs = (filter, setData) => {
  axios
    .get(url + `/admin/slabs` + filter)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData(null);
    });
};
export const getNewCustomerReport = (page, filter, setData) => {
  console.log(filter);
  axios
    .post(url + `/admin/report/new-customers?page=${page}`, filter)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData(null);
    });
};
export const getUpcomingCustomerReport = (page, setData) => {
  axios
    .post(url + `/admin/report/upcoming-customers?page=${page}`)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      console.log(err.response);
      setData(null);
    });
};
export const getInactiveCustomerReport = (page, filter, setData) => {
  console.log(filter);
  axios
    .post(url + `/admin/report/inactive-customers?page=${page}`, filter)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData(null);
    });
};
