import { Popover } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { isPermitted } from "../utils/PermissionManager";
export default function PhoneNumber({ phone, popup, hidden }) {
  let secretPhone = phone ? phone : "";
  const roles = useSelector((state) => state.roles.roles);

  let ext = 5;

  if (
    secretPhone &&
    secretPhone.length >= 11 &&
    !isPermitted(roles, ["admin"])
  ) {
    secretPhone = secretPhone.slice(0, 4) + "****" + secretPhone.slice(8);
    if (hidden) {
      secretPhone = hidden;
    }
  }

  if (popup) {
    return (
      <Popover content={phone}>
        <a href={`tel:${ext + phone}`}>{secretPhone}</a>
      </Popover>
    );
  } else {
    return <a href={`tel:${ext + phone}`}>{secretPhone}</a>;
  }
}
