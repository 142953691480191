import React from "react";
import { Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setAdminRole } from "../Actions/authAction";
const { Option } = Select;

export default function RoleSwitching() {
  const roles = useSelector((state) => state.roles.roles);
  const dispatch = useDispatch();
  return (
    <Select
      defaultValue={roles[0]}
      style={{
        width: 120,
        textTransform: "capitalize",
      }}
      onChange={(value) => dispatch(setAdminRole([value, "switching"]))}
    >
      <Option value="admin">Admin</Option>
      <Option value="purchasing">Purchasing</Option>
      <Option value="marketing">Marketing</Option>
      <Option value="delivery">Delivery</Option>
      <Option value="tracking">Tracking</Option>
      <Option value="accounts">Accounts</Option>
      <Option value="store">Store</Option>
      <Option value="chro">Chro</Option>
      <Option value="store_manager">Store Manager</Option>
      <Option value="chief_account">Chief Account</Option>
      <Option value="customer_care">Customer Care</Option>
      <Option value="chief_marketing">Chief Marketing</Option>
      <Option value="digital_marketing">Digital Marketing</Option>
      <Option value="chief_purchasing">Chief Purchasing</Option>
      <Option value="crm">CRM</Option>
      <Option value="cro">CRO</Option>
    </Select>
  );
}
