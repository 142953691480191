import React, { useState, useEffect } from "react";
import {
  Table,
  Typography,
  Button,
  Tag,
  Input,
  Select,
  Empty,
  Card,
} from "antd";
import { Link } from "react-router-dom";
import Spinner from "../components/Spinner";
import { useHistory } from "react-router";
import {
  CheckCircleOutlined,
  SyncOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  ClockCircleOutlined,
  MinusCircleOutlined,
  ScanOutlined,
} from "@ant-design/icons";
import {
  getCrmManagers,
  getCrmShipmentOrders,
  getDeliveryOrders,
  getReceivedCartonList,
  getShipmentList,
  getShipmentOrders,
} from "../api/api";
import { getMetaValue } from "../utils/GetMeta";
import { useParams } from "react-router";
import PhoneNumber from "../components/PhoneNumber";
import { isPermitted } from "../utils/PermissionManager";
import { useSelector } from "react-redux";
import CustomerBadge from "../components/CustomerBadge";
import moment from "moment";

const { Column } = Table;
const { Text } = Typography;
const { Search } = Input;
const { Option } = Select;

export default function CrmShipments() {
  const [orders, setOrders] = useState(null);
  const [totalWeight, setTotalWeight] = useState(0);
  const [totalOrders, setTotalOrders] = useState(0);
  const [totalSortedOrders, setTotalSortedOrders] = useState(0);
  const [filtered, setFiltered] = useState(null);
  const [cartonStatus, setCartonStatus] = useState([]);
  const [shipmentList, setShipmentList] = useState(null);
  const [searchKey, setSearchKey] = useState("");
  const [main, setMain] = useState(null);
  const { id } = useParams();
  const [selectedShipment, setSelectedShipment] = useState(null);
  const [manager, setManager] = useState(false);
  const [manager_id, setManager_Id] = useState(false);
  const roles = useSelector((state) => state.roles.roles);
  const history = useHistory();
  const page = history.location.search.slice(6)
    ? parseInt(history.location.search.slice(6))
    : 1;

  useEffect(() => {
    window.scrollTo({ top: 0 });
    getShipmentList(setShipmentList);
  }, [page]);

  useEffect(() => {
    getCrmManagers(null, setManager);
  }, []);

  useEffect(() => {
    if (id) {
      setSelectedShipment(id);
    }
  }, [id]);

  useEffect(() => {
    if (selectedShipment) {
      setFiltered(null);
      getReceivedCartonList(selectedShipment, setCartonStatus);
      if (manager_id) {
        getCrmShipmentOrders(
          { shipment_no: selectedShipment, crm_manager_id: manager_id },
          setOrders
        );
      } else {
        getCrmShipmentOrders({ shipment_no: selectedShipment }, setOrders);
      }
    }
  }, [selectedShipment, manager_id]);

  useEffect(() => {
    let finalArray = [];
    if (orders) {
      let mweight = 0;
      let morders = 0;
      let sorted = 0;
      Object.entries(orders).forEach((el) => {
        // console.log(el);
        let data = {
          phone: el[0],
          name: el[1][0].user_name,
          uid: el[1][0].user_id,
          id: el[1][0].user_id,
          badge: el[1][0].user_wsc_badge,
          join_date: el[1][0].user_created_at,
          orders: el[1],
        };

        morders = morders + el[1].length;

        el[1].forEach((elem) => {
          // console.log(elem);
          if (elem.weight) mweight = mweight + elem.weight;
          if (elem.status_id > 17) {
            sorted++;
          }
        });

        finalArray.push(data);
      });
      finalArray.sort((a, b) => {
        return a.orders.length - b.orders.length;
      });
      setMain(finalArray);
      if (
        history.location.search &&
        history.location.search.includes("customer")
      ) {
        let arr = history.location.search.split("=");
        if (arr.length === 2) {
          let customer_name = decodeURI(arr[1]);
          setSearchKey(customer_name);
          setFiltered(
            finalArray.filter((el) =>
              el.name.toLowerCase().includes(customer_name.toLowerCase())
            )
          );
        }
      } else {
        setFiltered(finalArray);
      }

      setTotalWeight(mweight);
      setTotalOrders(morders);
      setTotalSortedOrders(sorted);
    }
  }, [orders, history.location.search]);

  useEffect(() => {
    if (main) {
      if (searchKey) {
        if (parseInt(searchKey)) {
          setFiltered(main.filter((el) => el.phone.includes(searchKey)));
        } else {
          setFiltered(
            main.filter((el) =>
              el.name.toLowerCase().includes(searchKey.toLowerCase())
            )
          );
        }
      } else {
        setFiltered(main);
      }
    }
  }, [searchKey, main]);

  const getColor = (order) => {
    let carton = getMetaValue(order, "shipbaz_order_scns");
    let arrived = false;
    let received =
      cartonStatus && cartonStatus.received ? cartonStatus.received : [];
    received.forEach((element) => {
      if (carton.includes(element)) {
        if (carton.split(",").length > 1) {
          let array = carton.split(",");
          let temArrived = true;
          array.forEach((el) => {
            if (received.indexOf(el) < 0) {
              temArrived = false;
            }
          });
          if (temArrived) {
            arrived = true;
          }
        } else {
          arrived = true;
        }
      }
    });

    if (arrived) {
      if (order.status_id === 17) {
        return (
          <Tag color="#108ee9" style={{ marginBottom: "0.5rem" }}>
            {order.order_code + " : " + carton}
          </Tag>
        );
      } else if (order.status_id < 17) {
        return (
          <Tag color="blue" style={{ marginBottom: "0.5rem" }}>
            {order.order_code + " : " + carton}
          </Tag>
        );
      } else if (order.status_id === 18 || order.status_id === 19) {
        return (
          <Tag color="green" style={{ marginBottom: "0.5rem" }}>
            🗸 {order.order_code + " : " + carton}
          </Tag>
        );
      } else if (order.status_id > 19) {
        return (
          <Tag color="#228b22" style={{ marginBottom: "0.5rem" }}>
            🗸🗸 {order.order_code + " : " + carton}
          </Tag>
        );
      }
    } else {
      if (order.status_id > 17) {
        return (
          <Tag color="#228b22" style={{ marginBottom: "0.5rem" }}>
            {order.order_code + " : " + carton}
          </Tag>
        );
      } else {
        return (
          <Tag color="red" style={{ marginBottom: "0.5rem" }}>
            {order.order_code + " : "}
            {carton.split(",").map((el) => {
              if (received.indexOf(el) >= 0) {
                return (
                  <span style={{ color: "dodgerblue", margin: "0 .125rem" }}>
                    {el},
                  </span>
                );
              } else {
                return <span style={{ margin: "0 .125rem" }}>{el},</span>;
              }
            })}
          </Tag>
        );
      }
    }
  };

  return (
    <div>
      <div
        style={{
          marginBottom: "1.5rem",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Select
            placeholder="Select shipment"
            allowClear
            value={selectedShipment}
            style={{ width: "200px", marginRight: "1rem" }}
            onSelect={(e) => {
              history.push(`/my-crm/shipments/${e}`);
            }}
          >
            {shipmentList &&
              shipmentList.map((p, k) => (
                <Option
                  value={p.meta_value}
                  key={p.meta_value}
                  style={{ textTransform: "capitalize" }}
                >
                  {p.meta_value}
                </Option>
              ))}
          </Select>
          <Search
            value={searchKey}
            placeholder="Customer Name / Phone Number"
            onChange={(e) => {
              setSearchKey(e.target.value);
            }}
            allowClear
            enterButton="Search"
            style={{ width: "360px", marginRight: "1rem" }}
          />
          <Button type="primary" onClick={() => window.print()}>
            Print
          </Button>
        </div>
        <div>
          <Tag color="red">Not Received</Tag>
          <Tag color="blue">Not Scanned</Tag>
          <Tag color="#108ee9" icon={<ScanOutlined />}>
            Scanned
          </Tag>
          <Tag color="green" icon={<CheckCircleOutlined />}>
            Sorting Completed
          </Tag>
          <Tag color="#228b22" style={{ marginBottom: "0.5rem" }}>
            🗸🗸 Delivery Done
          </Tag>
        </div>
      </div>
      <div
        style={{
          marginTop: "1rem",
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          marginBottom: "1rem",
        }}
      >
        <div>
          {isPermitted(roles, ["admin", "cro"]) && (
            <Select
              placeholder="Select user"
              onSelect={(e) => {
                setManager_Id(e);
              }}
              style={{ width: "220px" }}
            >
              {manager &&
                manager.data &&
                manager.data.map((p, k) => (
                  <Select.Option value={p.id} key={p.id}>
                    {p.name}
                  </Select.Option>
                ))}
            </Select>
          )}
        </div>

        <div>
          <Tag
            style={{
              marginBottom: "0.5rem",
              padding: ".5rem",
              marginRight: "0.75rem",
              marginLeft: "0.75rem",
            }}
          >
            Total Orders: {totalOrders}
          </Tag>
          <Tag
            style={{
              marginBottom: "0.5rem",
              padding: ".5rem",
              marginRight: "0.75rem",
            }}
          >
            Total Sorted Orders: {totalSortedOrders}
          </Tag>
          <Tag
            style={{
              marginBottom: "0.5rem",
              padding: ".5rem",
              marginRight: "0.75rem",
            }}
          >
            Total Unsorted Orders: {totalOrders - totalSortedOrders}
          </Tag>
          <Tag
            style={{
              marginBottom: "0.5rem",
              padding: ".5rem",
              marginRight: "0.75rem",
            }}
          >
            Total Weight: {parseFloat(totalWeight).toFixed(2)} Kg
          </Tag>
        </div>
      </div>
      <div className="section-to-print">
        <div className="printOnly noMarginPadding">
          <h1>SHIPMENT NUMBER: {selectedShipment}</h1>
        </div>
        {filtered ? (
          <div>
            <Table
              dataSource={filtered}
              rowKey={(record) => record.phone}
              pagination={false}
              bordered
            >
              <Column
                title="Serial"
                key="user_id"
                render={(text, record, index) => <span>{index + 1}</span>}
              />
              <Column
                title="Customer"
                key="user_id"
                width={"300px"}
                render={(text, record, index) => (
                  <div>
                    <CustomerBadge
                      name={
                        moment(record.join_date).isAfter(moment("2022-05-15"))
                          ? record.name + " ★ "
                          : record.name
                      }
                      badge={record.badge}
                      id={record.id}
                    />
                  </div>
                )}
              />
              <Column
                title="Customer Phone"
                align="center"
                key="phone"
                render={(text, record) => (
                  <div>
                    <Text style={{ cursor: "pointer" }}>
                      {<PhoneNumber phone={record.phone} />}
                    </Text>
                  </div>
                )}
              />
              <Column
                title="Order IDs"
                key="order_ids"
                align="center"
                render={(text, record) => (
                  <div>
                    <div>
                      {record.orders
                        ? record.orders.map((p, k) => (
                            <a
                              href={`/order/${p.order_code}`}
                              key={p.order_code}
                              rel="noreferrer"
                              target="_blank"
                            >
                              {getColor(p)}
                            </a>
                          ))
                        : ""}
                    </div>
                  </div>
                )}
              />
            </Table>
          </div>
        ) : (
          <div>
            {!selectedShipment ? (
              <Card>
                <Empty description="Select Shipment" />
              </Card>
            ) : (
              <Spinner />
            )}
          </div>
        )}
      </div>
    </div>
  );
}
