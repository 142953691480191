import React, { useState, useEffect } from "react";
import {
  Table,
  Typography,
  Button,
  Tag,
  Drawer,
  Popover,
  Descriptions,
  Space,
  Modal,
  Form,
  notification,
  Row,
  Col,
  Input,
} from "antd";
import { Link } from "react-router-dom";
import Spinner from "../components/Spinner";
import {
  addUserMeta,
  deleteUserMeta,
  getPurchasingOrdersByPage,
} from "../api/api";
import { ThunderboltFilled } from "@ant-design/icons";
import PurchaseOrderList from "./PurchaseOrderList";
import moment from "moment";
import Status from "./Status";
import PhoneNumber from "./PhoneNumber";
import CustomerBadge from "./CustomerBadge";
import { rmb_rate } from "../utils/Config";
const { Column } = Table;
const { Text } = Typography;

export default function PurchaseCustomerList({
  customers,
  users,
  type,
  pageNum,
  tab,
  prevFilter,
  fromCrm,
  setRecalculate,
  sort,
}) {
  const [orders, setOrders] = useState(null);
  const [filter, setFilter] = useState(false);
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState(false);
  const [purchaseNote, setPurchaseNote] = useState(false);
  const [loading, setLoading] = useState(false);

  const [from3] = Form.useForm();

  console.log(prevFilter);

  useEffect(() => {
    if (filter && filter.phone) {
      setOrders(null);
      if (prevFilter && prevFilter.from && prevFilter.to) {
        filter.from = prevFilter.from;
        filter.to = prevFilter.to;
      }
      getPurchasingOrdersByPage(0, setOrders, () => {}, filter);
    }
  }, [filter, prevFilter]);

  const getOrderCost = (orders) => {
    let tCost = 0;
    orders.forEach((p) => {
      tCost = tCost + (p.sub_total - 0.1935 * p.sub_total) / rmb_rate;
    });

    return tCost;
  };

  const getPurchaseNote = (user) => {
    let note = user?.user_metas?.find(
      (el) => el.meta_key === "crm_purchase_note"
    )?.meta_value;

    return note;
  };

  const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
      message: message,
      description: description,
    });
  };

  const getOneAndNoVariation = (items) => {
    let buyAble = true;
    items?.forEach((el) => {
      if (el.variation) {
        let varitation = JSON.parse(el.variation);
        let length = Object.keys(varitation).length;
        if (length > 1) {
          buyAble = false;
        }
      }
    });
    return buyAble;
  };

  const getUserMeta = (metaList, key) => {
    if (metaList && key) {
      let found = metaList.find((el) => el.meta_key === key);

      if (found) {
        return found.meta_value;
      } else {
        return null;
      }
    }
  };

  const getSortedByPurchasingCost = (mCustomers) => {
    let tempCustomer = [...mCustomers];
    tempCustomer.sort(function (a, b) {
      let ax = parseFloat(getOrderCost(a.orders)).toFixed(0);
      let bx = parseFloat(getOrderCost(b.orders)).toFixed(0);
      return bx - ax;
    });
    return tempCustomer;
  };

  return (
    <div>
      <div>
        {customers ? (
          <div>
            <Table
              dataSource={
                sort ? getSortedByPurchasingCost(customers) : customers
              }
              rowKey={(record) => record.user.id}
              rowSelection={{
                selectedRowKeys: [selected],
              }}
              pagination={false}
              bordered
            >
              <Column
                title="Serial"
                key="name"
                render={(text, record, index) => (
                  <div>
                    <span>{index + 1}</span>
                  </div>
                )}
              />
              <Column
                title="Name"
                key="name"
                render={(text, record) => (
                  <div style={{ minWidth: "160px" }}>
                    <CustomerBadge
                      name={record.user.name}
                      badge={record.user.wsc_badge}
                      id={record.user.id}
                    />
                  </div>
                )}
              />
              <Column
                title="Phone"
                key="phone"
                align="center"
                render={(text, record) => (
                  <div>
                    <Text style={{ cursor: "pointer" }}>
                      {
                        <PhoneNumber
                          phone={
                            record.user.phone
                              ? record.user.phone
                              : record.emergency_phone
                              ? record.emergency_phone
                              : "--"
                          }
                        />
                      }
                    </Text>
                  </div>
                )}
              />
              <Column
                title="CRM Manager"
                key="name"
                align="center"
                render={(text, record) => (
                  <div style={{ minWidth: "160px" }}>
                    {record.user?.crm_manager ? (
                      <span>{record.user?.crm_manager?.name}</span>
                    ) : (
                      "-"
                    )}
                    <div>
                      {record?.user.user_metas &&
                        getUserMeta(
                          record?.user.user_metas,
                          "crm_purchase_note"
                        ) && (
                          <div style={{ marginTop: "0.5rem" }}>
                            <Popover
                              style={{ width: "500px" }}
                              content={
                                <div
                                  style={{ width: "500px", maxWidth: "90%" }}
                                >
                                  <span>
                                    {getUserMeta(
                                      record?.user.user_metas,
                                      "crm_purchase_note"
                                    )}
                                  </span>
                                </div>
                              }
                            >
                              {<Tag color="volcano">Note</Tag>}
                            </Popover>
                          </div>
                        )}
                    </div>
                  </div>
                )}
              />
              <Column
                title="Min"
                key="min"
                align="center"
                render={(text, record) => (
                  <div>
                    <Text
                      style={{
                        textTransform: "capitalize",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {record.min} Days
                    </Text>
                  </div>
                )}
              />
              <Column
                title="Orders"
                key="orders"
                align="center"
                render={(text, record) => (
                  <div>
                    {record.orders.map((p, k) => (
                      <a
                        href={`/order/${p.order_code}`}
                        key={p.order_code}
                        rel="noreferrer"
                        target="_blank"
                      >
                        <Popover
                          placement="left"
                          content={
                            <div style={{ width: "400px", padding: "0.5rem" }}>
                              <Descriptions column={1}>
                                <Descriptions.Item label="Order Placed">
                                  {
                                    <Status
                                      name={moment(
                                        new Date(p.created_at)
                                      ).format("DD/MM/YY hh:mm A")}
                                    />
                                  }
                                </Descriptions.Item>
                                <Descriptions.Item label="Paid At">
                                  {
                                    <Status
                                      name={moment(new Date(p.paid_at)).format(
                                        "DD/MM/YY hh:mm A"
                                      )}
                                    />
                                  }
                                </Descriptions.Item>
                                <Descriptions.Item label="Order">
                                  {<Status name={p.order_code} />}
                                </Descriptions.Item>
                                <Descriptions.Item label="Source">
                                  {<Status name={p.source} />}
                                </Descriptions.Item>
                                <Descriptions.Item label="Status">
                                  {<Status name={p.status.name} />}
                                </Descriptions.Item>
                                <Descriptions.Item label="Subtotal">
                                  {p.sub_total}
                                </Descriptions.Item>
                                <Descriptions.Item label="Deposit">
                                  {p.deposit_amount}
                                </Descriptions.Item>
                                <Descriptions.Item label="Shipping Date">
                                  {p.shipping_date}
                                </Descriptions.Item>
                                <Descriptions.Item label="Shipping Rate">
                                  {p.shipping_rate}
                                </Descriptions.Item>
                                <Descriptions.Item label="Manager">
                                  {p.manager ? p.manager.name : "--"}
                                </Descriptions.Item>
                              </Descriptions>
                            </div>
                          }
                        >
                          <Tag
                            icon={
                              <span
                                style={{
                                  padding: "0.075rem 0.375rem",
                                  backgroundColor: "white",
                                  marginRight: "0.5rem",
                                  borderRadius: "8px",
                                  color: "black",
                                  fontSize: "12px",
                                  fontWeight: 500,
                                }}
                              >
                                ¥{" "}
                                {parseFloat(
                                  (p.sub_total - 0.1935 * p.sub_total) /
                                    rmb_rate
                                ).toFixed(0)}
                              </span>
                            }
                            color={
                              p.status.name.toLowerCase() === "need repurchase"
                                ? "rgb(120, 6, 80)"
                                : p.source === "1688"
                                ? "#edd48f"
                                : "#FF4D74"
                            }
                            style={{
                              padding: "0.275rem 0.475rem",
                              marginRight: "0.5rem",
                              fontSize: "13px",
                              color:
                                p.status.name.toLowerCase() ===
                                  "need repurchase" || p.source === "alibaba"
                                  ? "white"
                                  : "black",
                              marginBottom: ".575rem",
                            }}
                          >
                            {p.order_code ? p.order_code : p.order_id}
                            {p.manager && (
                              <span
                                style={{
                                  padding: "0.075rem 0.375rem",
                                  backgroundColor: "white",
                                  marginRight: "0.5rem",
                                  borderRadius: "8px",
                                  color: "black",
                                  fontSize: "12px",
                                  fontWeight: 500,
                                  marginLeft: 6,
                                }}
                              >
                                {p.manager.name}
                              </span>
                            )}
                            {getOneAndNoVariation(p.order_items) &&
                            p.source === "1688" ? (
                              <ThunderboltFilled
                                style={{ marginLeft: "0.5rem", color: "black" }}
                              />
                            ) : (
                              ""
                            )}
                          </Tag>
                        </Popover>
                      </a>
                    ))}
                  </div>
                )}
              />
              <Column
                title="Purchasing Cost"
                key="tCost"
                align="center"
                render={(text, record) => (
                  <Space>
                    <Tag
                      color={"volcano"}
                      style={{
                        textTransform: "capitalize",
                        whiteSpace: "nowrap",
                      }}
                    >
                      ¥ {parseFloat(getOrderCost(record.orders)).toFixed(0)}
                    </Tag>
                    <Tag
                      color={"magenta"}
                      style={{
                        textTransform: "capitalize",
                        whiteSpace: "nowrap",
                      }}
                    >
                      ৳{" "}
                      {parseFloat(
                        getOrderCost(record.orders) * rmb_rate
                      ).toFixed(0)}
                    </Tag>
                  </Space>
                )}
              />

              {type === "cpm" && (
                <Column
                  title="Manage"
                  key="manage"
                  align="center"
                  render={(text, record) => (
                    <div>
                      <Button
                        type="primary"
                        onClick={() => {
                          setSelected(record.user.id);
                          setVisible(true);
                          setFilter({ phone: record.user.phone });
                        }}
                      >
                        Manage
                      </Button>
                    </div>
                  )}
                />
              )}
              {fromCrm && (
                <Column
                  title="Purchase Note"
                  key="manage"
                  align="center"
                  render={(text, record) => (
                    <div>{getPurchaseNote(record.user)}</div>
                  )}
                />
              )}
              {fromCrm && (
                <Column
                  title="Note"
                  key="manage"
                  align="center"
                  render={(text, record) => (
                    <div>
                      {getPurchaseNote(record.user) ? (
                        <Button
                          type="danger"
                          onClick={() => {
                            setPurchaseNote(record.user);
                          }}
                        >
                          Delete
                        </Button>
                      ) : (
                        <Button
                          type="primary"
                          onClick={() => {
                            setPurchaseNote(record.user);
                          }}
                        >
                          Add Note
                        </Button>
                      )}
                    </div>
                  )}
                />
              )}
            </Table>

            <Modal
              title="Customer Purchase Note"
              visible={purchaseNote}
              onCancel={() => {
                setPurchaseNote(null);
              }}
              footer={null}
            >
              <Form
                name="basic"
                layout="vertical"
                form={from3}
                onFinish={(values) => {
                  if (purchaseNote) {
                    setLoading(true);
                    let data = {
                      user_id: purchaseNote?.id,
                      meta_key: "crm_purchase_note",
                      meta_value: values.note,
                    };
                    console.log(data);
                    addUserMeta(data, (result) => {
                      setLoading(false);
                      if (result) {
                        from3.resetFields();
                        setPurchaseNote(null);
                        if (setRecalculate) {
                          setRecalculate(Math.random());
                        }

                        openNotificationWithIcon(
                          "success",
                          "Success",
                          "Note updated successfully"
                        );
                      } else {
                        openNotificationWithIcon(
                          "error",
                          "Failed",
                          "Note update update failed"
                        );
                      }
                    });
                  }
                }}
                onFinishFailed={() => {}}
              >
                {(!purchaseNote?.user_metas ||
                  purchaseNote?.user_metas?.filter(
                    (el) => el.meta_key === "crm_purchase_note"
                  )?.length === 0) && (
                  <Row gutter={[16, 0]}>
                    <Col xs={{ span: 24 }}>
                      <Form.Item
                        name="note"
                        label={`Note`}
                        rules={[
                          {
                            required: true,
                            message: "This field is required !",
                          },
                        ]}
                      >
                        <Input.TextArea rows={4} placeholder="Note" />
                      </Form.Item>
                    </Col>
                  </Row>
                )}
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {purchaseNote?.user_metas?.filter(
                    (el) => el.meta_key === "crm_purchase_note"
                  )?.length > 0 ? (
                    <div>
                      <p>Delete crm purchase note ?</p>
                      <Button
                        onClick={() => {
                          let id = purchaseNote?.user_metas?.filter(
                            (el) => el.meta_key === "crm_purchase_note"
                          )[0].id;
                          deleteUserMeta(id, (result) => {
                            setLoading(false);
                            if (result) {
                              setPurchaseNote(null);
                              if (setRecalculate) {
                                setRecalculate(Math.random());
                              }
                              openNotificationWithIcon(
                                "success",
                                "Success",
                                "updated successfully"
                              );
                            } else {
                              openNotificationWithIcon(
                                "error",
                                "Failed",
                                "update update failed"
                              );
                            }
                          });
                        }}
                        type="danger"
                      >
                        Delete
                      </Button>
                    </div>
                  ) : (
                    <div style={{ display: "flex" }}>
                      <Form.Item>
                        <Button
                          type="primary"
                          htmlType="submit"
                          loading={loading}
                        >
                          {"Save"}
                        </Button>
                      </Form.Item>
                      <Button
                        onClick={() => {
                          setPurchaseNote(null);
                        }}
                        style={{ marginLeft: "16px" }}
                      >
                        Return
                      </Button>
                    </div>
                  )}
                </div>
              </Form>
            </Modal>

            <Drawer
              title={"Assign Manager"}
              visible={visible}
              width={"70%"}
              onClose={() => {
                setVisible(false);
                setOrders(null);
                setFilter(null);
              }}
            >
              {orders && orders.data && users && users.data ? (
                <PurchaseOrderList
                  orders={orders}
                  users={users}
                  type="cpm"
                  pageNum={1}
                  tab={tab}
                />
              ) : (
                <Spinner />
              )}
            </Drawer>
          </div>
        ) : (
          <Spinner />
        )}
      </div>
    </div>
  );
}
