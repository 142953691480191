import React, { useEffect, useState } from "react";
import {
  Table,
  Space,
  Button,
  Typography,
  Tag,
  Descriptions,
  Drawer,
  Input,
  Row,
  Col,
  Select,
  Card,
  Switch,
  Popconfirm,
  notification,
} from "antd";
import { Link, useHistory } from "react-router-dom";
import {
  cancelPendingWithDeposit,
  getAgentOrders,
  getAgents,
  getOrdersByPage,
} from "../api/api";
import Spinner from "../components/Spinner";
import moment from "moment";
import { DollarOutlined } from "@ant-design/icons";
import { status } from "../utils/Status";
import { useSelector } from "react-redux";
import Status from "../components/Status";
import { isPermitted } from "../utils/PermissionManager";
import CustomerBadge from "../components/CustomerBadge";

const { Column } = Table;
const { Option } = Select;
const { Text } = Typography;
const { Search } = Input;

export default function AgentOrders() {
  const [filter, setFilter] = useState(null);
  const [order, setOrder] = useState(null);
  const [orderItem, setOrderItem] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(false);
  const [withDeposit, setWithDeposit] = useState(false);
  const [orderStatus, setOrderStatus] = useState("");
  const [recalculate, setRecalculate] = useState(5);
  const history = useHistory();
  const roles = useSelector((state) => state.roles.roles);
  const page = history.location.search.slice(6)
    ? parseInt(history.location.search.slice(6))
    : 1;

  const [manager, setManager] = useState(false);

  useEffect(() => {
    getAgents(null, setManager);
  }, []);
  useEffect(() => {
    setLoading(true);
    let mFilter = filter ? { ...filter, ...{ page: page } } : { page: page };
    window.scrollTo({ top: 0, behavior: "smooth" });
    getAgentOrders(setOrder, mFilter);
  }, [page, filter, recalculate]);

  console.log(order);

  const getStatusDisabled = (name) => {
    if (isPermitted(roles, ["marketing"])) {
      return true;
    }
    if (isPermitted(roles, ["chro"])) {
      return false;
    }
    if (isPermitted(roles, ["customer_care"])) {
      if (name.toLowerCase() === "pending payment") {
        return false;
      } else if (name.toLowerCase() === "refunded") {
        return false;
      } else {
        return true;
      }
    }

    return false;
  };

  const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
      message: message,
      description: description,
    });
  };

  const afterPerform = (result, message) => {
    if (result) {
      setRecalculate(Math.random());
      openNotificationWithIcon("success", "Success", "updated successfully");
    } else {
      openNotificationWithIcon(
        "error",
        "Failed",
        message ? message : "Failed to update"
      );
    }
  };

  return (
    <div>
      <Card style={{ marginBottom: ".5rem" }} size="small">
        <Row gutter={[16, 16]}>
          <Col md={{ span: 6 }}>
            <Text style={{ fontSize: "16px" }}>Search By Customer Phone</Text>
            <Search
              placeholder="Customer Phone Number"
              onSearch={(value) => {
                history.push(`/orders?page=1`);
                if (value) {
                  if (parseInt(value) && value.length >= 10) {
                    let prev = filter ? { ...filter } : {};
                    prev.phone = value;
                    setFilter(prev);
                  } else {
                    let prev = filter ? { ...filter } : {};
                    prev.order_id = value;
                    setFilter(prev);
                  }
                } else {
                  setFilter(null);
                }
              }}
              allowClear
              enterButton
              style={{
                width: "100%",
                marginTop: "0.5rem",
              }}
            />
          </Col>

          <Col md={{ span: 6 }}>
            <Text style={{ fontSize: "16px" }}>Select Order Status</Text>
            <Select
              placeholder="Select Order Status"
              style={{ width: "100%", marginTop: "0.5rem" }}
              onSelect={(val) => {
                history.push(`/orders?page=1`);
                setWithDeposit(false);
                setOrderStatus(val);
                if (val) {
                  let prev = filter ? { ...filter } : {};
                  prev.status_id = val;
                  setFilter(prev);
                } else {
                  let prev =
                    filter && filter.phone ? { phone: filter.phone } : null;
                  setFilter(prev);
                }
              }}
            >
              <Option
                key={100}
                value={null}
                disabled={
                  !isPermitted(roles, ["chro"]) &&
                  isPermitted(roles, ["marketing", "customer_care"])
                }
              >
                All
              </Option>
              {status.map((p, k) => (
                <Option
                  key={p.id}
                  value={p.id}
                  disabled={getStatusDisabled(p.name)}
                >
                  {p.name}
                </Option>
              ))}
            </Select>
            {orderStatus === 1 && (
              <div style={{ marginTop: "0.75rem" }}>
                <Text style={{ marginRight: "0.5rem" }}>With Deposit Only</Text>
                <Switch
                  checked={withDeposit}
                  onChange={(e) => {
                    setWithDeposit(e);
                  }}
                />
              </div>
            )}
          </Col>
          <Col md={{ span: 6 }}>
            <Text style={{ fontSize: "16px" }}>Search By Order ID</Text>
            <Search
              placeholder="Order ID"
              onSearch={(value) => {
                history.push(`/orders?page=1`);
                if (value) {
                  let prev = filter ? { ...filter } : {};
                  prev.order_id = value;
                  setFilter(prev);
                } else {
                  let prev =
                    filter && filter.phone ? { phone: filter.phone } : null;
                  setFilter(prev);
                }
              }}
              allowClear
              enterButton
              style={{
                width: "100%",
                marginTop: "0.5rem",
              }}
            />
          </Col>
          <Col md={{ span: 6 }}>
            <h4>Select Agent</h4>
            {isPermitted(roles, ["admin", "cro"]) && (
              <Select
                placeholder="Select agent"
                onSelect={(e) => {
                  let prev = {};
                  prev.agent_id = e;
                  setFilter(prev);
                }}
                style={{ width: "100%" }}
              >
                {manager &&
                  manager.data &&
                  manager.data.map((p, k) => (
                    <Select.Option value={p.id} key={p.id}>
                      {p.name}
                    </Select.Option>
                  ))}
              </Select>
            )}
          </Col>
        </Row>
      </Card>
      {order && order.data ? (
        <Table
          dataSource={order.data}
          bordered
          rowSelection={{
            selectedRowKeys: [selected],
          }}
          rowKey={(record) => record.id}
          pagination={{
            current: page,
            pageSize: order.meta && order.meta.per_page,
            total: order.meta && order.meta.total,
            showSizeChanger: false,
          }}
          onChange={(page, pageSize) => {
            history.push(`/agent/orders?page=${page.current}`);
          }}
        >
          <Column
            title="Order ID"
            dataIndex="id"
            key="id"
            render={(text, record, index) => (
              <div onClick={() => setSelected(record.id)}>
                <a
                  href={`/order/${record.order_code}`}
                  rel="noreferrer"
                  target="_blank"
                >
                  #{record.order_code ? record.order_code : record.id}
                </a>
              </div>
            )}
          />
          <Column
            title="Date"
            dataIndex="date"
            render={(text, record, index) => (
              <Text style={{ fontSize: "13px" }}>
                {moment(new Date(record.created_at)).format(
                  "DD/MM/YYYY, hh:mm A"
                )}
              </Text>
            )}
          />
          <Column
            title="User"
            dataIndex="user"
            render={(text, record, index) => (
              <Link to={`/customer/${record.user_id}`}>
                <CustomerBadge
                  name={record.user_name}
                  id={record.user_id}
                  badge={
                    record.user?.wsc_badge === "new"
                      ? record.user?.wsc_badge
                      : null
                  }
                  column
                />
              </Link>
            )}
          />

          <Column
            title="User Phone"
            align="center"
            dataIndex="user_phone"
            render={(text, record, index) => <Text>{record.user_phone}</Text>}
          />
          <Column
            title="CRM Manager"
            dataIndex="user"
            align="center"
            render={(text, record, index) => (
              <div>
                {record.user?.crm_manager ? (
                  <span>{record.user?.crm_manager.name}</span>
                ) : (
                  "-"
                )}
              </div>
            )}
          />
          {/* <Column
            title="Source"
            dataIndex="source"
            key="source"
            align="center"
            render={(text, record) => (
              <Status name={record.source} type="source" />
            )}
          /> */}
          <Column
            title="Grand Total"
            align="center"
            key="grand_total"
            render={(text, record) => <Text>৳ {record.grand_total}</Text>}
          />
          <Column
            title="Deposit"
            align="center"
            key="deposit_amount"
            render={(text, record) => <Text>৳ {record.deposit_amount}</Text>}
          />
          <Column
            title="Payment"
            key="payment_method"
            align="center"
            render={(text, record) => (
              <div>
                {record.payments.length > 0
                  ? record.payments.map((p, k) => (
                      <Status
                        key={p.id}
                        icon={<DollarOutlined />}
                        onClick={() => setOrderItem(record)}
                        name={p.payment_method}
                        type="payment_method"
                      />
                    ))
                  : "--"}
              </div>
            )}
          />
          <Column
            title="Shipping Date"
            align="center"
            key="shipping_date"
            render={(text, record) => (
              <Text style={{ fontSize: "13px" }}>{record.shipping_date}</Text>
            )}
          />
          <Column
            title="Status"
            key="status"
            align="center"
            render={(text, record) => (
              <Status name={record.status.name} type="order_status" />
            )}
          />
          <Column
            title="Action"
            key="action"
            render={(text, record) => (
              <Space onClick={() => setSelected(record.id)}>
                <a
                  href={`/order/${record.order_code}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button type="primary">Manage</Button>
                </a>
                {orderStatus === 1 && (
                  <Popconfirm
                    placement="topLeft"
                    title={`Confirm Cancel`}
                    onConfirm={() => {
                      cancelPendingWithDeposit(record.id, afterPerform);
                    }}
                    okText="Yes"
                    cancelText="Cancel"
                    okButtonProps={{ loading: loading }}
                  >
                    <Button
                      type="danger"
                      style={{ marginRight: "1rem" }}
                      onClick={() => {}}
                    >
                      Cancel
                    </Button>
                  </Popconfirm>
                )}
              </Space>
            )}
          />
        </Table>
      ) : (
        <div>
          <Spinner />
        </div>
      )}
      <Drawer
        title={
          orderItem
            ? `Transactions #${
                orderItem.order_code ? orderItem.order_code : orderItem.id
              }`
            : ""
        }
        visible={orderItem}
        width={"40%"}
        // bodyStyle={{ backgroundColor: "#eee" }}
        onClose={() => {
          setOrderItem(null);
        }}
      >
        {orderItem &&
          orderItem.payments.map((p, k) => (
            <Card
              size="small"
              style={{ marginBottom: "1rem" }}
              bordered={false}
            >
              <Descriptions bordered column={2} key={p.id}>
                <Descriptions.Item label="Payment Method">
                  <Status
                    key={p.id}
                    icon={<DollarOutlined />}
                    name={p.payment_method}
                    type="payment_method"
                  />
                </Descriptions.Item>
                <Descriptions.Item label="Payment Date">
                  {moment(p.created_at).format("DD/MMM/YYYY, hh:mm A")}
                </Descriptions.Item>
                <Descriptions.Item label="Order ID">
                  <Button type="link" size="small">
                    #{orderItem.order_code}
                  </Button>
                </Descriptions.Item>
                <Descriptions.Item label="Amount">
                  <Tag color="red">৳ {p.amount}</Tag>
                </Descriptions.Item>
                {p.txn_id && (
                  <Descriptions.Item label="TXN ID" span={2}>
                    {p.txn_id}
                  </Descriptions.Item>
                )}
                {p.bank_txn_id && (
                  <Descriptions.Item label="Bank TXN ID">
                    {p.txn_id}
                  </Descriptions.Item>
                )}
                <Descriptions.Item label="Status">
                  <Tag color="red" style={{ textTransform: "capitalize" }}>
                    {p.status}
                  </Tag>
                </Descriptions.Item>
                <Descriptions.Item label="Note">
                  {p.note ? p.note : "-"}
                </Descriptions.Item>
              </Descriptions>
            </Card>
          ))}
      </Drawer>
    </div>
  );
}
