import React, { useEffect, useState } from "react";
import { Table, Button, Typography, Tag, Popover, Card, Modal } from "antd";
import { Link, useHistory } from "react-router-dom";
import { getAdminCsCalls } from "../api/api";
import moment from "moment";
import Spinner from "../components/Spinner";
import CallFeedback from "./CallFeedback";
import PhoneNumber from "./PhoneNumber";
const { Column } = Table;
const { Text } = Typography;

export default function CallLogs({ user_id, order_id, phone, userData }) {
  const [data, setData] = useState(null);
  const [callData, setCallData] = useState(null);
  const [reload, setReload] = useState(69);
  const history = useHistory();
  const page = history.location.search.slice(6)
    ? parseInt(history.location.search.slice(6))
    : 1;

  console.log(user_id);

  useEffect(() => {
    // window.scrollTo({ top: 0 });
    setData(null);
    let filter = {};
    if (user_id) {
      filter.user_id = user_id;
    }
    if (order_id) {
      filter.order_id = order_id;
    }
    if (phone) {
      filter.phone = phone;
    }
    filter.per_page = 300;
    getAdminCsCalls(setData, filter);
  }, [user_id, order_id, phone, reload]);

  let content = (
    <>
      {data && data.data ? (
        <div>
          <Table
            dataSource={data.data}
            rowKey={(record) => record.id}
            pagination={false}
            bordered
          >
            <Column
              title="Serial"
              align="center"
              dataIndex="serial"
              render={(text, record, index) => (
                <Text>{(page - 1) * 200 + (index + 1)}</Text>
              )}
            />
            <Column
              title="Date"
              align="center"
              dataIndex="date"
              render={(text, record) => (
                <span>
                  {moment(new Date(record.created_at)).format(
                    "DD/MM/YYYY hh:mm A"
                  )}
                </span>
              )}
            />
            <Column
              title="Customer"
              align="center"
              dataIndex="customer"
              render={(text, record) => (
                <a
                  href={`/customer/${record.user.id}`}
                  target={"_blank"}
                  rel="noreferrer"
                >
                  {record.user.name}
                </a>
              )}
            />
            <Column
              title="Caller"
              align="center"
              dataIndex="amount"
              render={(text, record) => (
                <Text>{record.caller ? record.caller.name : "-"}</Text>
              )}
            />
            <Column
              title="Call Time"
              align="center"
              dataIndex="amount"
              render={(text, record) => (
                <Text>
                  {moment(new Date(record.created_at)).format(
                    "DD/MM/YY hh:mm A"
                  )}
                </Text>
              )}
            />
            <Column
              title="Order"
              align="center"
              dataIndex="order"
              render={(text, record) => (
                <Text>
                  {record.order_code ? record.order_code : record.order_id}
                </Text>
              )}
            />
            <Column
              title="Feedback"
              align="center"
              dataIndex="amount"
              render={(text, record) => (
                <div>
                  {record.feedback && (
                    <Tag
                      style={{ textTransform: "capitalize" }}
                      color={
                        record.call_feedback === "neutral"
                          ? "#CF9521"
                          : record.call_feedback === "positive"
                          ? "#237804"
                          : "#a8071a"
                      }
                    >
                      {record.feedback}
                    </Tag>
                  )}
                </div>
              )}
            />
            <Column
              title="Summary"
              width={"20%"}
              align="center"
              dataIndex="amount"
              render={(text, record) => (
                <Popover
                  content={
                    <div style={{ maxWidth: "400px" }}>
                      <Text style={{ fontSize: "13px" }}>{record.summary}</Text>
                    </div>
                  }
                  title="Message"
                >
                  <Text style={{ fontSize: "13px" }}>
                    {record.summary?.slice(0, 30)}
                  </Text>
                </Popover>
              )}
            />
          </Table>
        </div>
      ) : (
        <div>
          <Spinner />
        </div>
      )}
    </>
  );

  return (
    <div>
      {userData ? (
        <Card
          title={
            <div style={{ display: "flex", alignItems: "center" }}>
              <PhoneNumber phone={userData.phone} popup hidden="Call now" />
            </div>
          }
          extra={
            <div>
              {userData && (
                <Button
                  type="primary"
                  onClick={() => {
                    let temp = { ...userData };
                    if (order_id) {
                      temp.order_id = order_id;
                    }
                    setCallData(temp);
                  }}
                >
                  Add Call Log
                </Button>
              )}
            </div>
          }
        >
          {content}
        </Card>
      ) : (
        <div>{content}</div>
      )}
      <Modal
        title={"Feedback"}
        visible={callData}
        width={"600px"}
        onCancel={() => {
          setCallData(null);
        }}
        footer={null}
      >
        <CallFeedback
          data={callData}
          afterFunction={() => {
            setCallData(null);
            setReload(Math.random());
          }}
        />
      </Modal>
    </div>
  );
}
