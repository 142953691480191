import React, { useEffect, useState } from "react";
import {
  Table,
  Button,
  Typography,
  Tag,
  Drawer,
  Form,
  notification,
  Row,
  Col,
  Input,
  InputNumber,
  Upload,
  message,
  Image,
  Modal,
  Select,
  Space,
} from "antd";
import { Link, useHistory } from "react-router-dom";
import {
  getCrmManagers,
  getMyCrmCustomers,
  getProcessingCustomers,
  getUsers,
  updateCustomer,
  updateCustomerCrm,
} from "../api/api";
import moment from "moment";
import Spinner from "../components/Spinner";
import { isPermitted } from "../utils/PermissionManager";
import { useSelector } from "react-redux";
import PhoneNumber from "../components/PhoneNumber";
import queryString from "query-string";
import CustomerBadge from "../components/CustomerBadge";
import CallFeedback from "../components/CallFeedback";
import CallLogs from "../components/CallLogs";
import Status from "../components/Status";
const { Column } = Table;
const { Text } = Typography;
const { Search } = Input;

export default function ProcessingCustomers() {
  const [users, setUsers] = useState(null);
  const [filter, setFilter] = useState({ badge: "all" });
  const [callData, setCallData] = useState(null);
  const [callLog, setCallLog] = useState(null);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(5);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [form] = Form.useForm();
  const [drawer, setDrawer] = useState(0);
  const [userData, setUserData] = useState(null);

  const history = useHistory();

  const [manager, setManager] = useState(false);
  const roles = useSelector((state) => state.roles.roles);

  useEffect(() => {
    getCrmManagers(null, setManager);
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setUsers(null);
    setLoading(true);
    let mFilter = {};

    if (!isPermitted(roles, ["admin", "chro"])) {
      mFilter.mylist = 1;
    }

    getProcessingCustomers(mFilter, setUsers);
  }, [roles, reload]);

  const onUpdateForm = (values) => {
    setLoadingBtn(true);
    let formdata = new FormData();
    if (values.business_street)
      formdata.append("business_street", values.business_street);
    if (values.business_name)
      formdata.append("business_name", values.business_name);
    if (values.business_city)
      formdata.append("business_city", values.business_city);
    if (values.product_categories && values.product_categories.length > 0) {
      values.product_categories.forEach((el) => {
        formdata.append("product_categories[]", el);
      });
    }
    if (values.is_potential)
      formdata.append("is_potential", values.is_potential);
    if (values.business_district)
      formdata.append("business_district", values.business_district);
    if (values.business_type)
      formdata.append("business_type", values.business_type);
    if (values.referer) formdata.append("referer", values.referer);

    if (values.emergency_phone)
      formdata.append("emergency_phone", values.emergency_phone);

    updateCustomerCrm(userData.id, formdata, afterPerform);
  };

  const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
      message: message,
      description: description,
    });
  };

  const onFailedSubmit = (error) => {
    openNotificationWithIcon(
      "error",
      "Failed to submit",
      "You have missed some important information. Please fill those up to complete action."
    );
  };

  const afterPerform = (result) => {
    setLoading(false);
    setDrawer(0);
    setLoadingBtn(false);
    form.resetFields();
    setUserData(null);
    if (result) {
      setReload(Math.random());
      openNotificationWithIcon(
        "success",
        "Success",
        "Profile updated successfully"
      );
    } else {
      openNotificationWithIcon("error", "Failed", "Profile update failed");
    }
  };

  return (
    <div>
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "1rem",
            background: "#fff",
            padding: "16px 24px",
            alignItems: "center",
            borderRadius: 4,
          }}
        >
          <span style={{ fontSize: "16px" }}>Processing Customers</span>
          <div></div>
        </div>
        {users ? (
          <Table
            dataSource={users}
            rowKey={(record) => record.phone}
            // pagination={{
            //   pageSize: 280,
            // }}
            pagination={false}
          >
            <Column
              title="Serial"
              align="center"
              dataIndex="phone"
              render={(text, record, index) => (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Text>{index + 1}</Text>
                </div>
              )}
            />
            <Column
              title="Name"
              dataIndex="name"
              width={"300px"}
              align="center"
              render={(text, record, index) => <Text>{record.name}</Text>}
            />
            <Column
              title="Phone"
              align="center"
              dataIndex="phone"
              render={(text, record, index) => (
                <PhoneNumber phone={record.phone} popup={true} />
              )}
            />
            <Column
              title="Total Processing Order"
              align="center"
              dataIndex="phone"
              render={(text, record, index) => (
                <Tag color="volcano">
                  {record.processing_order_count
                    ? record.processing_order_count
                    : 0}
                </Tag>
              )}
            />
            <Column
              title="Purchased Orders"
              key="orders"
              align="center"
              width="20%"
              render={(text, record, index) => (
                <div>
                  {record.purchased.map((p, k) => (
                    <a
                      href={`/order/${p}`}
                      key={p.order_id}
                      rel="noreferrer"
                      target="_blank"
                    >
                      <Tag
                        color="#EDD48F"
                        style={{
                          padding: "0.275rem 0.475rem",
                          marginRight: "0.5rem",
                          fontSize: "13px",
                          color: "black",
                          marginBottom: ".425rem",
                        }}
                      >
                        {p}
                      </Tag>
                    </a>
                  ))}
                </div>
              )}
            />
            <Column
              title="Not Purchased Orders"
              key="orders"
              align="center"
              width="20%"
              render={(text, record, index) => (
                <div>
                  {record.not_purchased.map((p, k) => (
                    <a
                      href={`/order/${p}`}
                      key={p.order_id}
                      rel="noreferrer"
                      target="_blank"
                    >
                      <Tag
                        color="#EDD48F"
                        style={{
                          padding: "0.275rem 0.475rem",
                          marginRight: "0.5rem",
                          fontSize: "13px",
                          color: "black",
                          marginBottom: ".425rem",
                        }}
                      >
                        {p}
                      </Tag>
                    </a>
                  ))}
                </div>
              )}
            />
            <Column
              title="Refund Processing"
              key="orders"
              align="center"
              width="20%"
              render={(text, record, index) => (
                <div>
                  {record.refund_processing.map((p, k) => (
                    <a
                      href={`/order/${p}`}
                      key={p.order_id}
                      rel="noreferrer"
                      target="_blank"
                    >
                      <Tag
                        color="#EDD48F"
                        style={{
                          padding: "0.275rem 0.475rem",
                          marginRight: "0.5rem",
                          fontSize: "13px",
                          color: "black",
                          marginBottom: ".425rem",
                        }}
                      >
                        {p}
                      </Tag>
                    </a>
                  ))}
                </div>
              )}
            />
          </Table>
        ) : (
          <div>
            <Spinner />
          </div>
        )}
      </div>
    </div>
  );
}
