import React, { useState, useEffect } from "react";
import {
  Table,
  Typography,
  Button,
  Tag,
  Input,
  Row,
  Col,
  Card,
  DatePicker,
  Select,
} from "antd";
import { Link } from "react-router-dom";
import Spinner from "../components/Spinner";
import { useHistory } from "react-router";
import {
  getAgentOrderReport,
  getAgents,
  getCrmManagers,
  getCrmOrderReport,
  getDeliveryOrders,
  getReport,
  getUsers,
} from "../api/api";
import { DollarOutlined } from "@ant-design/icons";
import moment from "moment";
import { useSelector } from "react-redux";
import { isPermitted } from "../utils/PermissionManager";
const { Column } = Table;
const { Text } = Typography;
const { Search } = Input;

export default function AgentOrderReport() {
  const [data, setData] = useState(null);
  const [filter, setFilter] = useState(
    `year=${new Date().getFullYear()}&month=${new Date().getMonth() + 1}`
  );
  const [userFilter, setUserFilter] = useState(null);
  const [selected, setSelected] = useState(1);
  const [totalOrders, setTotalOrders] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);

  const [manager, setManager] = useState(false);
  const roles = useSelector((state) => state.roles.roles);

  useEffect(() => {
    getAgents(null, setManager);
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0 });
    let tempFilter = filter;
    if (userFilter) {
      tempFilter = tempFilter + `&agent_id=${userFilter}`;
    }
    getAgentOrderReport(tempFilter, setData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, userFilter]);

  // useEffect(() => {
  //   if (data) {
  //     let orders = 0;
  //     let amount = 0;
  //     data.forEach((element) => {
  //       orders = orders + element.orders;
  //       amount = amount + parseInt(element.amount);
  //     });
  //     setTotalAmount(amount);
  //     setTotalOrders(orders);
  //   }
  // }, [data]);

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: "#fff",
          padding: "1rem",
          marginBottom: "1rem",
          borderRadius: 4,
        }}
      >
        <span style={{ fontSize: "16px" }}>Agent Order Reports</span>
        {isPermitted(roles, ["admin", "cro"]) && (
          <Select
            placeholder="Select agent"
            onSelect={(e) => {
              setUserFilter(e);
            }}
            style={{ width: "200px" }}
          >
            {manager &&
              manager.data &&
              manager.data.map((p, k) => (
                <Select.Option value={p.id} key={p.id}>
                  {p.name}
                </Select.Option>
              ))}
          </Select>
        )}
      </div>
      <div style={{ display: "flex", marginBottom: "1rem" }} className="tagTab">
        <Tag
          style={{
            padding: "0.5rem 1rem",
            color: "black",
            cursor: "pointer",
            fontSize: "14px",
          }}
          onClick={() => {
            let date = new Date();
            setSelected(1);
            setFilter(
              `year=${date.getFullYear()}&month=${date.getMonth() + 1}`
            );
          }}
          color={selected === 1 ? "rgb(237, 212, 143)" : "white"}
        >
          This Month
        </Tag>
        <Tag
          style={{
            padding: "0.5rem 1rem",
            color: "black",
            cursor: "pointer",
            fontSize: "14px",
          }}
          color={selected === 2 ? "rgb(237, 212, 143)" : "white"}
          onClick={() => {
            setSelected(2);
            let date = new Date();
            let year = date.getFullYear();
            let month = date.getMonth();
            if (month === 0) {
              year = year - 1;
              month = 12;
            }
            setFilter(`year=${year}&month=${month}`);
          }}
        >
          Last Month
        </Tag>
        <Tag
          style={{
            padding: "0.5rem 1rem",
            color: "black",
            cursor: "pointer",
            fontSize: "14px",
          }}
          color={selected === 3 ? "rgb(237, 212, 143)" : "white"}
          onClick={() => {
            setSelected(3);
            let date = new Date();
            let year = date.getFullYear();
            setFilter(`year=${year}`);
          }}
        >
          This Year
        </Tag>
        <Tag
          style={{
            padding: "0.5rem 1rem",
            color: "black",
            cursor: "pointer",
            fontSize: "14px",
          }}
          color={selected === 4 ? "rgb(237, 212, 143)" : "white"}
          onClick={() => {
            setSelected(4);
            let date = new Date();
            let year = date.getFullYear() - 1;
            setFilter(`year=${year}`);
          }}
        >
          Last Year
        </Tag>
        <DatePicker
          picker="month"
          style={{ marginLeft: "1rem", width: "300px" }}
          format="MMMM/YYYY"
          onChange={(e) => {
            setSelected(5);
            let date = new Date(e);
            setFilter(
              `year=${date.getFullYear()}&month=${date.getMonth() + 1}`
            );
          }}
        />
      </div>
      <div style={{ display: "flex", marginBottom: "1rem" }}>
        {data && data.stats && (
          <>
            <div
              style={{
                cursor: "pointer",
                textTransform: "capitalize",
                marginRight: "0.5rem",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                color: "black",
                padding: "1rem",
                backgroundColor: "white",
                width: "256px",
              }}
              color="white"
            >
              <h3>Total Orders</h3>
              <div style={{}}>
                <Tag
                  color={"magenta"}
                  style={{ fontSize: "12px", fontWeight: 500 }}
                >
                  {parseInt(data.stats.total_order_count).toLocaleString()}
                </Tag>
                <Tag
                  color={"volcano"}
                  style={{ fontSize: "12px", fontWeight: 500 }}
                >
                  ৳ {parseInt(data.stats.total_order_amount).toLocaleString()}
                </Tag>
              </div>
            </div>
            <div
              style={{
                cursor: "pointer",
                textTransform: "capitalize",
                marginRight: "0.5rem",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                color: "black",
                padding: "1rem",
                backgroundColor: "white",
                width: "256px",
              }}
              color="white"
            >
              <h3>Total Paid Orders</h3>
              <div style={{}}>
                <Tag
                  color={"magenta"}
                  style={{ fontSize: "12px", fontWeight: 500 }}
                >
                  {parseInt(data.stats.total_paid_order_count).toLocaleString()}
                </Tag>
                <Tag
                  color={"volcano"}
                  style={{ fontSize: "12px", fontWeight: 500 }}
                >
                  ৳{" "}
                  {parseInt(
                    data.stats.total_paid_order_amount
                  ).toLocaleString()}
                </Tag>
              </div>
            </div>
            <div
              style={{
                cursor: "pointer",
                textTransform: "capitalize",
                marginRight: "0.5rem",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                color: "black",
                padding: "1rem",
                backgroundColor: "white",
                width: "256px",
              }}
              color="white"
            >
              <h3>Refunded Orders</h3>
              <div style={{}}>
                <Tag
                  color={"black"}
                  style={{ fontSize: "12px", fontWeight: 500 }}
                >
                  {parseInt(data.stats.total_refunded_count).toLocaleString()}
                </Tag>
                <Tag
                  color={"#cf1322"}
                  style={{ fontSize: "12px", fontWeight: 500 }}
                >
                  ৳{" "}
                  {parseInt(data.stats.total_refunded_amount).toLocaleString()}
                </Tag>
              </div>
            </div>
            <div
              style={{
                cursor: "pointer",
                textTransform: "capitalize",
                marginRight: "0.5rem",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                color: "black",
                padding: "1rem",
                backgroundColor: "white",
                width: "256px",
              }}
              color="white"
            >
              <h3>Final Orders</h3>
              <div style={{}}>
                <Tag
                  color={"black"}
                  style={{ fontSize: "12px", fontWeight: 500 }}
                >
                  {parseInt(data.stats.final_order_count).toLocaleString()}
                </Tag>
                <Tag
                  color={"#237804"}
                  style={{ fontSize: "12px", fontWeight: 500 }}
                >
                  ৳ {parseInt(data.stats.final_order_amount).toLocaleString()}
                </Tag>
              </div>
            </div>
            <div
              style={{
                cursor: "pointer",
                textTransform: "capitalize",
                marginRight: "0.5rem",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                color: "black",
                padding: "1rem",
                backgroundColor: "white",
                width: "256px",
              }}
              color="white"
            >
              <h3>Average Order</h3>
              <div style={{}}>
                <Tag
                  color={"magenta"}
                  style={{ fontSize: "12px", fontWeight: 500 }}
                >
                  {data.result && data.result.length > 0
                    ? parseInt(
                        data.stats.final_order_count / data.result.length
                      )
                    : "-"}
                </Tag>
                <Tag
                  color={"volcano"}
                  style={{ fontSize: "12px", fontWeight: 500 }}
                >
                  ৳{" "}
                  {data.result && data.result.length > 0
                    ? parseInt(
                        data.stats.final_order_amount / data.result.length
                      ).toLocaleString()
                    : "-"}
                </Tag>
              </div>
            </div>
          </>
        )}
      </div>
      <div>
        {data && data.result ? (
          <div>
            <Table
              dataSource={data.result}
              rowKey={(record) => record.date}
              pagination={false}
              bordered
            >
              <Column
                title="Date"
                dataIndex="date"
                render={(text, record, index) => (
                  <Text>
                    {record.key.length > 2
                      ? moment(new Date(record.key)).format("DD/MM/YYYY")
                      : moment()
                          .month(parseInt(record.key) - 1)
                          .format("MMMM")}
                  </Text>
                )}
              />
              <Column
                title="Order"
                key="order"
                render={(text, record) => (
                  <div style={{}}>
                    <Tag
                      color={"magenta"}
                      style={{ fontSize: "12px", fontWeight: 500 }}
                    >
                      {parseInt(record.order_count)}
                    </Tag>
                    <Tag
                      color={"volcano"}
                      style={{ fontSize: "12px", fontWeight: 500 }}
                    >
                      ৳ {parseInt(record.order_amount).toLocaleString()}
                    </Tag>
                  </div>
                )}
              />
              <Column
                title="Unpaid"
                key="order"
                render={(text, record) => (
                  <div style={{}}>
                    <Tag
                      color={"magenta"}
                      style={{ fontSize: "12px", fontWeight: 500 }}
                    >
                      {parseInt(record.unpaid_count)}
                    </Tag>
                    <Tag
                      color={"volcano"}
                      style={{ fontSize: "12px", fontWeight: 500 }}
                    >
                      ৳ {parseInt(record.unpaid_amount).toLocaleString()}
                    </Tag>
                  </div>
                )}
              />
              <Column
                title="Deposit Amount"
                key="order"
                render={(text, record) => (
                  <div style={{}}>
                    <Text>
                      ৳ {parseInt(record.deposit_amount).toLocaleString()}
                    </Text>
                  </div>
                )}
              />

              <Column
                title="Discount"
                key="order"
                render={(text, record) => (
                  <div style={{}}>
                    <Text style={{ fontSize: "12px", fontWeight: 500 }}>
                      ৳ {parseInt(record.discount_amount).toLocaleString()}
                    </Text>
                  </div>
                )}
              />
              <Column
                title="Total Orders"
                key="order"
                render={(text, record) => (
                  <div style={{}}>
                    <Tag
                      color={"magenta"}
                      style={{ fontSize: "12px", fontWeight: 500 }}
                    >
                      {parseInt(record.paid_count)}
                    </Tag>
                    <Tag
                      color={"#237804"}
                      style={{ fontSize: "12px", fontWeight: 500 }}
                    >
                      ৳ {parseInt(record.paid_order_amount).toLocaleString()}
                    </Tag>
                  </div>
                )}
              />
            </Table>
          </div>
        ) : (
          <Spinner />
        )}
      </div>
    </div>
  );
}
