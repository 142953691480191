import React, { Suspense, lazy, useState, useEffect } from "react";
import "./App.less";
import { Button, Card, Empty, Layout, Modal, Tag } from "antd";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";
import store from "./store";
import { Provider } from "react-redux";
import Login from "./pages/Login";
import AdminRoute from "./routes/AdminRoute";
import setAuthToken from "./utils/setAuthToken";
import { setAdmin, setAdminRole } from "./Actions/authAction";
import SideBar from "./layouts/SideBar";
import Navbar from "./layouts/NavBar";
import { isPermitted } from "./utils/PermissionManager";
import CrmTopCustomers from "./pages/CrmTopCustomers";
import CrmPurchase from "./pages/CrmPurchase";
import CrmReport from "./pages/CrmReport";
import CrmShipments from "./pages/CrmShipments";
import AgentOrderReport from "./pages/AgentReport";
import AgentOrders from "./pages/AgentOrders";
import ProcessingCustomers from "./pages/ProcessingCustomers";
import axios from "axios";
import url from "./utils/url";
import moment from "moment";

const PotentialCustomer = lazy(() => import("./pages/PotentialCustomer"));
const CustomerCampaign = lazy(() => import("./pages/CustomerCampaign"));
const AdvanceHub = lazy(() => import("./pages/AdvanceHub"));
const CrmCustomers = lazy(() => import("./pages/CrmCustomers"));
const CrmOrders = lazy(() => import("./pages/CrmOrders"));
const CrmOrderReport = lazy(() => import("./pages/CrmOrderReport"));
const CrmTickets = lazy(() => import("./pages/CrmTickets"));
const CrmIncentives = lazy(() => import("./pages/CrmIncentives"));
const CrmOverview = lazy(() => import("./pages/CrmOverview"));
const CrmCallLogs = lazy(() => import("./pages/CrmCallLogs"));

const Setting = lazy(() => import("./pages/Setting"));
const CustomerOrderTimelineReport = lazy(() =>
  import("./pages/CustomerOrderTimelineReport")
);
const SortingReport = lazy(() => import("./pages/SortingReport"));
const AgentCommissions = lazy(() => import("./pages/AgentCommissions"));
const CheckList = lazy(() => import("./pages/CheckList"));
const OrderProcessReport = lazy(() => import("./pages/OrderProcessReport"));
const CustomersCrm = lazy(() => import("./pages/CustomersCrm"));
const OfficeVisits = lazy(() => import("./pages/OfficeVisits"));
const Targets = lazy(() => import("./pages/Targets"));
const MarketingLogs = lazy(() => import("./pages/MarketingLogs"));
const TargetStatus = lazy(() => import("./pages/TargetStatus"));
const Zones = lazy(() => import("./pages/Zones"));
const Agents = lazy(() => import("./pages/Agents"));
const AgentBalance = lazy(() => import("./pages/AgentBalance"));

const Slab = lazy(() => import("./pages/Slab"));
const Seminars = lazy(() => import("./pages/Seminars"));
const Seminar = lazy(() => import("./pages/Seminar"));
const FieldVisits = lazy(() => import("./pages/FieldVisits"));

const PurchasingSummary = lazy(() => import("./components/PurchasingSummary"));
const PriorityCustomer = lazy(() => import("./pages/PriorityCustomer"));
const Cases = lazy(() => import("./pages/Cases"));
const ShipmentCarton = lazy(() => import("./pages/ShipmentCartons"));
const ShipmentBulkCarton = lazy(() => import("./pages/ShipmentBulkCartons"));
const ManageTicket = lazy(() => import("./pages/ManageTicket"));
const Products = lazy(() => import("./pages/Products"));
const CustomerReport = lazy(() => import("./pages/CustomerReport"));
const PrintInvoice = lazy(() => import("./pages/PrintInvoice"));
const ProfitReport = lazy(() => import("./pages/ProfitReport"));
const Case = lazy(() => import("./pages/Case"));
const CampaignCategory = lazy(() => import("./pages/CampaignCategories"));
const CampaignCategoryProduct = lazy(() =>
  import("./pages/CampaignCategoryProducts")
);
const ShipmentHub = lazy(() => import("./pages/Shipment"));
const ShipmentOrders = lazy(() => import("./pages/ShipmentOrders"));
const Campaign = lazy(() => import("./pages/Campaigns"));
const CampaignProduct = lazy(() => import("./pages/CampaignProducts"));
const Invoice = lazy(() => import("./pages/Invoice"));
const Refunds = lazy(() => import("./pages/Refund"));
const CashCounter = lazy(() => import("./pages/CashCounter"));
const CourierHub = lazy(() => import("./pages/CourierHub"));
const Transactions = lazy(() => import("./pages/Transactions"));
const Report = lazy(() => import("./pages/Report"));
const Dashboard = lazy(() => import("./pages/Dashboard"));
const Orders = lazy(() => import("./pages/Orders"));
const ManageOrder = lazy(() => import("./pages/ManageOrder"));
const Users = lazy(() => import("./pages/Users"));
const Profile = lazy(() => import("./pages/Profile"));
const Coupon = lazy(() => import("./pages/Coupon"));
const SupportCategory = lazy(() => import("./pages/SupportCategory"));
const Fee = lazy(() => import("./pages/Fee"));
const Purchasing = lazy(() => import("./pages/Purchasing"));
const Roles = lazy(() => import("./pages/Roles"));
const Tickets = lazy(() => import("./pages/Tickets"));
const Customers = lazy(() => import("./pages/Customers"));
const Delivery = lazy(() => import("./pages/Delivery"));
const CustomerDetails = lazy(() => import("./pages/CustomerDetails"));
const Tracking = lazy(() => import("./pages/Tracking"));
const DeliveryHubComplete = lazy(() => import("./pages/DeliveryHubComplete"));
const Balance = lazy(() => import("./pages/Balance"));
const Sorting = lazy(() => import("./pages/Sorting"));
const BankSlip = lazy(() => import("./pages/BankSlip"));

if (localStorage.agl) {
  setAuthToken(localStorage.agl);
  store.dispatch(setAdmin(true));
}

if (localStorage.profile) {
  let profile = JSON.parse(localStorage.profile);
  store.dispatch(setAdminRole(profile.roles));
}

function App() {
  const [show, setshow] = useState(false);
  const [ipGuard, setIpGuard] = useState(false);
  const [trigger, settrigger] = useState(false);

  const roles = store.getState().roles ? store.getState().roles.roles : [];

  return (
    <Provider store={store}>
      <Router>
        <Suspense fallback={<div className="pageProgresss"></div>}>
          <div className="App">
            <Layout>
              <SideBar state={{ show, setshow }} />
              <Layout>
                <Navbar state={{ settrigger, setshow, show }} />
                {!ipGuard ? (
                  <Switch location={window.location}>
                    <Route
                      exact
                      path="/"
                      render={() => (
                        <AdminRoute
                          state={{ show, trigger }}
                          Component={<Dashboard />}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/orders"
                      render={() => (
                        <AdminRoute
                          state={{ show, trigger }}
                          Component={<Orders />}
                        />
                      )}
                    />
                    {isPermitted(roles, [
                      "admin",
                      "delivery",
                      "store_manager",
                      "store",
                    ]) && (
                      <Route
                        exact
                        path="/sorting"
                        render={() => (
                          <AdminRoute
                            state={{ show, trigger }}
                            Component={<Sorting />}
                          />
                        )}
                      />
                    )}
                    <Route
                      exact
                      path="/order/:id"
                      render={() => (
                        <AdminRoute
                          state={{ show, trigger }}
                          Component={<ManageOrder />}
                        />
                      )}
                    />
                    {isPermitted(roles, [
                      "admin",
                      "marketing",
                      "purchasing",
                      "crm",
                    ]) && (
                      <Route
                        exact
                        path="/case/:id?"
                        render={() => (
                          <AdminRoute
                            state={{ show, trigger }}
                            Component={<Case />}
                          />
                        )}
                      />
                    )}
                    {isPermitted(roles, ["admin"]) && (
                      <Route
                        exact
                        path="/settings"
                        render={() => (
                          <AdminRoute
                            state={{ show, trigger }}
                            Component={<Setting />}
                          />
                        )}
                      />
                    )}
                    {isPermitted(roles, [
                      "admin",
                      "marketing",
                      "purchasing",
                      "crm",
                    ]) && (
                      <Route
                        exact
                        path="/cases"
                        render={() => (
                          <AdminRoute
                            state={{ show, trigger }}
                            Component={<Cases />}
                          />
                        )}
                      />
                    )}
                    {isPermitted(roles, [
                      "admin",
                      "delivery",
                      "store_manager",
                      "store",
                      "purchasing",
                      "accounts",
                      "tracking",
                      "marketing",
                      "crm",
                      "chro",
                    ]) && (
                      <Route
                        exact
                        path="/customer/:id"
                        render={() => (
                          <AdminRoute
                            state={{ show, trigger }}
                            Component={<CustomerDetails />}
                          />
                        )}
                      />
                    )}
                    {isPermitted(roles, ["admin"]) && (
                      <Route
                        exact
                        path="/admins"
                        render={() => (
                          <AdminRoute
                            state={{ show, trigger }}
                            Component={<Users />}
                          />
                        )}
                      />
                    )}
                    {isPermitted(roles, ["admin", "accounts"]) && (
                      <Route
                        exact
                        path="/balance"
                        render={() => (
                          <AdminRoute
                            state={{ show, trigger }}
                            Component={<Balance />}
                          />
                        )}
                      />
                    )}
                    <Route
                      exact
                      path="/potential-customers"
                      render={() => (
                        <AdminRoute
                          state={{ show, trigger }}
                          Component={<PotentialCustomer />}
                        />
                      )}
                    />
                    {isPermitted(roles, ["admin", "marketing", "chro"]) && (
                      <Route
                        exact
                        path="/customer-campaign"
                        render={() => (
                          <AdminRoute
                            state={{ show, trigger }}
                            Component={<CustomerCampaign />}
                          />
                        )}
                      />
                    )}
                    {isPermitted(roles, [
                      "admin",
                      "delivery",
                      "store_manager",
                      "store",
                    ]) && (
                      <Route
                        exact
                        path="/delivery-complete/:uid/:sid"
                        render={() => (
                          <AdminRoute
                            state={{ show, trigger }}
                            Component={<DeliveryHubComplete />}
                          />
                        )}
                      />
                    )}
                    {/* {isPermitted(roles, ["admin", "purch"]) && ( */}
                    <Route
                      exact
                      path="/tickets"
                      render={() => (
                        <AdminRoute
                          state={{ show, trigger }}
                          Component={<Tickets />}
                        />
                      )}
                    />
                    {/* )} */}
                    {/* {isPermitted(roles, ["admin"]) && ( */}
                    <Route
                      exact
                      path="/ticket/:id"
                      render={() => (
                        <AdminRoute
                          state={{ show, trigger }}
                          Component={<ManageTicket />}
                        />
                      )}
                    />
                    {/* )} */}
                    {isPermitted(roles, ["admin", "chro"]) && (
                      <Route
                        exact
                        path="/order-report"
                        render={() => (
                          <AdminRoute
                            state={{ show, trigger }}
                            Component={<Report />}
                          />
                        )}
                      />
                    )}
                    {isPermitted(roles, ["admin", "chro"]) && (
                      <Route
                        exact
                        path="/sorting-report"
                        render={() => (
                          <AdminRoute
                            state={{ show, trigger }}
                            Component={<SortingReport />}
                          />
                        )}
                      />
                    )}
                    {isPermitted(roles, ["admin", "chro"]) && (
                      <Route
                        exact
                        path="/order-process-report"
                        render={() => (
                          <AdminRoute
                            state={{ show, trigger }}
                            Component={<OrderProcessReport />}
                          />
                        )}
                      />
                    )}
                    {isPermitted(roles, ["admin", "chro"]) && (
                      <Route
                        exact
                        path="/customer-order-timeline-report"
                        render={() => (
                          <AdminRoute
                            state={{ show, trigger }}
                            Component={<CustomerOrderTimelineReport />}
                          />
                        )}
                      />
                    )}
                    {isPermitted(roles, ["admin", "chro"]) && (
                      <Route
                        exact
                        path="/purchasing-report"
                        render={() => (
                          <AdminRoute
                            state={{ show, trigger }}
                            Component={<PurchasingSummary />}
                          />
                        )}
                      />
                    )}
                    {isPermitted(roles, ["admin", "crm", "cro", "chro"]) && (
                      <Route
                        exact
                        path="/crm-call"
                        render={() => (
                          <AdminRoute
                            state={{ show, trigger }}
                            Component={<CustomersCrm />}
                          />
                        )}
                      />
                    )}
                    {isPermitted(roles, ["admin", "chro", "cro"]) && (
                      <Route
                        exact
                        path="/crm-report"
                        render={() => (
                          <AdminRoute
                            state={{ show, trigger }}
                            Component={<CrmReport />}
                          />
                        )}
                      />
                    )}
                    {isPermitted(roles, ["admin", "chro", "cro", "crm"]) && (
                      <Route
                        exact
                        path="/my-crm/shipments/:id?"
                        render={() => (
                          <AdminRoute
                            state={{ show, trigger }}
                            Component={<CrmShipments />}
                          />
                        )}
                      />
                    )}
                    {isPermitted(roles, ["admin"]) && (
                      <Route
                        exact
                        path="/customer-report"
                        render={() => (
                          <AdminRoute
                            state={{ show, trigger }}
                            Component={<CustomerReport />}
                          />
                        )}
                      />
                    )}
                    {isPermitted(roles, ["admin"]) && (
                      <Route
                        exact
                        path="/profit-report"
                        render={() => (
                          <AdminRoute
                            state={{ show, trigger }}
                            Component={<ProfitReport />}
                          />
                        )}
                      />
                    )}
                    {isPermitted(roles, ["admin", "marketing", "crm"]) && (
                      <Route
                        exact
                        path="/slab"
                        render={() => (
                          <AdminRoute
                            state={{ show, trigger }}
                            Component={<Slab />}
                          />
                        )}
                      />
                    )}
                    <Route
                      exact
                      path="/profile"
                      render={() => (
                        <AdminRoute
                          state={{ show, trigger }}
                          Component={<Profile />}
                        />
                      )}
                    />
                    {isPermitted(roles, [
                      "admin",
                      "delivery",
                      "store_manager",
                      "store",
                      "accounts",
                    ]) && (
                      <Route
                        exact
                        path="/courier"
                        render={() => (
                          <AdminRoute
                            state={{ show, trigger }}
                            Component={<CourierHub />}
                          />
                        )}
                      />
                    )}
                    {isPermitted(roles, ["admin", "accounts"]) && (
                      <Route
                        exact
                        path="/transactions"
                        render={() => (
                          <AdminRoute
                            state={{ show, trigger }}
                            Component={<Transactions />}
                          />
                        )}
                      />
                    )}
                    {isPermitted(roles, [
                      "admin",
                      "accounts",
                      "store_manager",
                    ]) && (
                      <Route
                        exact
                        path="/cash-counter"
                        render={() => (
                          <AdminRoute
                            state={{ show, trigger }}
                            Component={<CashCounter />}
                          />
                        )}
                      />
                    )}
                    {isPermitted(roles, [
                      "admin",
                      "accounts",
                      "chro",
                      "crm",
                    ]) && (
                      <Route
                        exact
                        path="/refund"
                        render={() => (
                          <AdminRoute
                            state={{ show, trigger }}
                            Component={<Refunds />}
                          />
                        )}
                      />
                    )}
                    {isPermitted(roles, ["admin", "marketing"]) && (
                      <Route
                        exact
                        path="/coupon"
                        render={() => (
                          <AdminRoute
                            state={{ show, trigger }}
                            Component={<Coupon />}
                          />
                        )}
                      />
                    )}
                    <Route
                      exact
                      path="/processing-customers"
                      render={() => (
                        <AdminRoute
                          state={{ show, trigger }}
                          Component={<ProcessingCustomers />}
                        />
                      )}
                    />
                    {isPermitted(roles, ["admin", "marketing"]) && (
                      <Route
                        exact
                        path="/campaigns"
                        render={() => (
                          <AdminRoute
                            state={{ show, trigger }}
                            Component={<Campaign />}
                          />
                        )}
                      />
                    )}
                    {isPermitted(roles, ["admin", "marketing"]) && (
                      <Route
                        exact
                        path="/seminars"
                        render={() => (
                          <AdminRoute
                            state={{ show, trigger }}
                            Component={<Seminars />}
                          />
                        )}
                      />
                    )}
                    {isPermitted(roles, ["admin", "marketing"]) && (
                      <Route
                        exact
                        path="/seminar/:id"
                        render={() => (
                          <AdminRoute
                            state={{ show, trigger }}
                            Component={<Seminar />}
                          />
                        )}
                      />
                    )}
                    {isPermitted(roles, ["admin", "marketing"]) && (
                      <Route
                        exact
                        path="/targets"
                        render={() => (
                          <AdminRoute
                            state={{ show, trigger }}
                            Component={<Targets />}
                          />
                        )}
                      />
                    )}
                    {isPermitted(roles, ["admin", "marketing"]) && (
                      <Route
                        exact
                        path="/marketing-logs"
                        render={() => (
                          <AdminRoute
                            state={{ show, trigger }}
                            Component={<MarketingLogs />}
                          />
                        )}
                      />
                    )}
                    {isPermitted(roles, ["admin", "marketing"]) && (
                      <Route
                        exact
                        path="/marketing-target-status"
                        render={() => (
                          <AdminRoute
                            state={{ show, trigger }}
                            Component={<TargetStatus />}
                          />
                        )}
                      />
                    )}
                    {isPermitted(roles, ["admin", "chro"]) && (
                      <Route
                        exact
                        path="/agent/zones"
                        render={() => (
                          <AdminRoute
                            state={{ show, trigger }}
                            Component={<Zones />}
                          />
                        )}
                      />
                    )}
                    {isPermitted(roles, ["admin", "chro", "accounts"]) && (
                      <Route
                        exact
                        path="/agent/commission-withdraw"
                        render={() => (
                          <AdminRoute
                            state={{ show, trigger }}
                            Component={<AgentCommissions />}
                          />
                        )}
                      />
                    )}
                    {isPermitted(roles, [
                      "admin",
                      "purchasing",
                      "tracking",
                    ]) && (
                      <Route
                        exact
                        path="/order-checklist"
                        render={() => (
                          <AdminRoute
                            state={{ show, trigger }}
                            Component={<CheckList />}
                          />
                        )}
                      />
                    )}
                    {isPermitted(roles, ["admin", "chro"]) && (
                      <Route
                        exact
                        path="/agent"
                        render={() => (
                          <AdminRoute
                            state={{ show, trigger }}
                            Component={<Agents />}
                          />
                        )}
                      />
                    )}
                    {isPermitted(roles, ["admin", "chro", "accounts"]) && (
                      <Route
                        exact
                        path="/agent/balance"
                        render={() => (
                          <AdminRoute
                            state={{ show, trigger }}
                            Component={<AgentBalance />}
                          />
                        )}
                      />
                    )}
                    {isPermitted(roles, ["admin", "marketing"]) && (
                      <Route
                        exact
                        path="/field-visits"
                        render={() => (
                          <AdminRoute
                            state={{ show, trigger }}
                            Component={<FieldVisits />}
                          />
                        )}
                      />
                    )}
                    {isPermitted(roles, ["admin", "marketing"]) && (
                      <Route
                        exact
                        path="/office-visits"
                        render={() => (
                          <AdminRoute
                            state={{ show, trigger }}
                            Component={<OfficeVisits />}
                          />
                        )}
                      />
                    )}
                    {isPermitted(roles, ["admin", "marketing"]) && (
                      <Route
                        exact
                        path="/campaign-category/:id"
                        render={() => (
                          <AdminRoute
                            state={{ show, trigger }}
                            Component={<CampaignCategory />}
                          />
                        )}
                      />
                    )}
                    {isPermitted(roles, [
                      "admin",
                      "marketing",
                      "digital_marketing",
                    ]) && (
                      <Route
                        exact
                        path="/campaign-category-products/:cid/:id"
                        render={() => (
                          <AdminRoute
                            state={{ show, trigger }}
                            Component={<CampaignCategoryProduct />}
                          />
                        )}
                      />
                    )}
                    {isPermitted(roles, [
                      "admin",
                      "marketing",
                      "digital_marketing",
                    ]) && (
                      <Route
                        exact
                        path="/campaigns/:id"
                        render={() => (
                          <AdminRoute
                            state={{ show, trigger }}
                            Component={<CampaignProduct />}
                          />
                        )}
                      />
                    )}
                    <Route
                      exact
                      path="/invoice/:id"
                      render={() => (
                        <AdminRoute
                          state={{ show, trigger }}
                          Component={<Invoice />}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/master-invoice/:id"
                      render={() => (
                        <AdminRoute
                          state={{ show, trigger }}
                          Component={<PrintInvoice />}
                        />
                      )}
                    />
                    {isPermitted(roles, ["admin", "chro"]) && (
                      <Route
                        exact
                        path="/support-category"
                        render={() => (
                          <AdminRoute
                            state={{ show, trigger }}
                            Component={<SupportCategory />}
                          />
                        )}
                      />
                    )}
                    {isPermitted(roles, ["admin", "accounts"]) && (
                      <Route
                        exact
                        path="/bank-slip"
                        render={() => (
                          <AdminRoute
                            state={{ show, trigger }}
                            Component={<BankSlip />}
                          />
                        )}
                      />
                    )}
                    {isPermitted(roles, ["admin"]) && (
                      <Route
                        exact
                        path="/fee"
                        render={() => (
                          <AdminRoute
                            state={{ show, trigger }}
                            Component={<Fee />}
                          />
                        )}
                      />
                    )}
                    <Route
                      exact
                      path="/customers"
                      render={() => (
                        <AdminRoute
                          state={{ show, trigger }}
                          Component={<Customers />}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/my-crm"
                      render={() => (
                        <AdminRoute
                          state={{ show, trigger }}
                          Component={<CrmOverview />}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/my-crm/customers"
                      render={() => (
                        <AdminRoute
                          state={{ show, trigger }}
                          Component={<CrmCustomers />}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/my-crm/top-customers"
                      render={() => (
                        <AdminRoute
                          state={{ show, trigger }}
                          Component={<CrmTopCustomers />}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/my-crm/purchase"
                      render={() => (
                        <AdminRoute
                          state={{ show, trigger }}
                          Component={<CrmPurchase />}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/my-crm/incentives"
                      render={() => (
                        <AdminRoute
                          state={{ show, trigger }}
                          Component={<CrmIncentives />}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/my-crm/orders"
                      render={() => (
                        <AdminRoute
                          state={{ show, trigger }}
                          Component={<CrmOrders />}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/my-crm/order-report"
                      render={() => (
                        <AdminRoute
                          state={{ show, trigger }}
                          Component={<CrmOrderReport />}
                        />
                      )}
                    />{" "}
                    <Route
                      exact
                      path="/agent/order-report"
                      render={() => (
                        <AdminRoute
                          state={{ show, trigger }}
                          Component={<AgentOrderReport />}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/agent/orders"
                      render={() => (
                        <AdminRoute
                          state={{ show, trigger }}
                          Component={<AgentOrders />}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/my-crm/tickets"
                      render={() => (
                        <AdminRoute
                          state={{ show, trigger }}
                          Component={<CrmTickets />}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/my-crm/call-logs"
                      render={() => (
                        <AdminRoute
                          state={{ show, trigger }}
                          Component={<CrmCallLogs />}
                        />
                      )}
                    />
                    {isPermitted(roles, [
                      "admin",
                      "delivery",
                      "store_manager",
                      "store",
                      "purchasing",
                      "accounts",
                      "tracking",
                      "marketing",
                      "crm",
                    ]) && (
                      <Route
                        exact
                        path="/priority-customers"
                        render={() => (
                          <AdminRoute
                            state={{ show, trigger }}
                            Component={<PriorityCustomer />}
                          />
                        )}
                      />
                    )}
                    {isPermitted(roles, [
                      "admin",
                      // "delivery",
                      // "store_manager",
                      // "store",
                      // "purchasing",
                      // "accounts",
                      // "tracking",
                      // "marketing",
                      // "customer_care",
                    ]) && (
                      <Route
                        exact
                        path="/products"
                        render={() => (
                          <AdminRoute
                            state={{ show, trigger }}
                            Component={<Products />}
                          />
                        )}
                      />
                    )}
                    {isPermitted(roles, ["admin"]) && (
                      <Route
                        exact
                        path="/roles"
                        render={() => (
                          <AdminRoute
                            state={{ show, trigger }}
                            Component={<Roles />}
                          />
                        )}
                      />
                    )}
                    {isPermitted(roles, ["admin", "purchasing"]) && (
                      <Route
                        exact
                        path="/purchasing"
                        render={() => (
                          <AdminRoute
                            state={{ show, trigger }}
                            Component={<Purchasing />}
                          />
                        )}
                      />
                    )}
                    {isPermitted(roles, [
                      "admin",
                      "tracking",
                      "purchasing",
                    ]) && (
                      <Route
                        exact
                        path="/tracking"
                        render={() => (
                          <AdminRoute
                            state={{ show, trigger }}
                            Component={<Tracking />}
                          />
                        )}
                      />
                    )}
                    {isPermitted(roles, [
                      "admin",
                      "delivery",
                      "store_manager",
                      "store",
                    ]) && (
                      <Route
                        exact
                        path="/invoice"
                        render={() => (
                          <AdminRoute
                            state={{ show, trigger }}
                            Component={<Delivery />}
                          />
                        )}
                      />
                    )}
                    {isPermitted(roles, [
                      "admin",
                      "delivery",
                      "store_manager",
                      "store",
                      "crm",
                      "tracking",
                    ]) && (
                      <Route
                        exact
                        path="/shipment/:id?"
                        render={() => (
                          <AdminRoute
                            state={{ show, trigger }}
                            Component={<ShipmentHub />}
                          />
                        )}
                      />
                    )}
                    {isPermitted(roles, [
                      "admin",
                      "delivery",
                      "store_manager",
                      "store",
                    ]) && (
                      <Route
                        exact
                        path="/shipment-carton/:id"
                        render={() => (
                          <AdminRoute
                            state={{ show, trigger }}
                            Component={<ShipmentCarton />}
                          />
                        )}
                      />
                    )}
                    {isPermitted(roles, [
                      "admin",
                      "delivery",
                      "store_manager",
                      "store",
                    ]) && (
                      <Route
                        exact
                        path="/shipment-bulk-carton/:id"
                        render={() => (
                          <AdminRoute
                            state={{ show, trigger }}
                            Component={<ShipmentBulkCarton />}
                          />
                        )}
                      />
                    )}
                    {isPermitted(roles, [
                      "admin",
                      "delivery",
                      "store_manager",
                      "store",
                    ]) && (
                      <Route
                        exact
                        path="/shipment-orders/:sid/:cid"
                        render={() => (
                          <AdminRoute
                            state={{ show, trigger }}
                            Component={<ShipmentOrders />}
                          />
                        )}
                      />
                    )}
                    {isPermitted(roles, ["admin"]) && (
                      <Route
                        exact
                        path="/advance-hub"
                        render={() => (
                          <AdminRoute
                            state={{ show, trigger }}
                            Component={<AdvanceHub />}
                          />
                        )}
                      />
                    )}
                    <Route exact path="/login" render={() => <Login />} />
                  </Switch>
                ) : (
                  <AdminRoute
                    state={{ show, trigger }}
                    Component={
                      <Card
                        style={{ height: "calc(100vh - 92px)" }}
                        bodyStyle={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {" "}
                        <Empty
                          image={require("./assets/ip.svg").default}
                          imageStyle={{
                            height: 200,
                          }}
                          description={
                            <p
                              style={{ fontSize: "20px", marginTop: "1.5rem" }}
                            >
                              IP GUARD ACTIVATED
                            </p>
                          }
                        />{" "}
                      </Card>
                    }
                  />
                )}
                {/* {<BottomBar state={{ show, trigger }} />} */}
              </Layout>
            </Layout>
          </div>
        </Suspense>
      </Router>
    </Provider>
  );
}

export default App;
