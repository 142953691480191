export const isPermitted = (permissions, allowed, exclusive, route) => {
  let perm = false;
  allowed.forEach((element) => {
    if (permissions.includes(element)) {
      perm = true;
    }
  });

  // Rabiul Exclusive Permission for all field visits
  if (exclusive && exclusive === 16710 && route === "all_field_visit") {
    perm = true;
  }
  if (exclusive && exclusive === 16688 && route === "potential_customer") {
    perm = true;
  }

  return perm;
};
