import { unserialize } from "php-serialize";

export const getMetaValue = (
  order,
  meta_key,
  isSerialized,
  modified_meta_name
) => {
  let meta = order.meta;
  if (modified_meta_name) {
    meta = order.order_metas;
  }
  if (meta) {
    let value = "";
    let index = meta.findIndex((el) => el.meta_key === meta_key);
    if (index >= 0) {
      if (isSerialized) {
        return unserialize(meta[index].meta_value);
      } else {
        value = meta[index].meta_value;
      }
    }
    return value;
  } else {
    return "";
  }
};
