import { Button, Card, Col, DatePicker, Row, Select } from "antd";
import Search from "antd/lib/input/Search";
import Text from "antd/lib/typography/Text";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getCrmManagers, getCrmPurchase, getUsers } from "../api/api";
import PurchaseCustomerList from "../components/PurchasingCustomerList";
import PurchasingStats from "../components/PurchasingStats";
import { isPermitted } from "../utils/PermissionManager";
export default function CrmPurchase() {
  const [data, setData] = useState(null);
  const [filter, setFilter] = useState(null);
  const [reload, setReload] = useState(5);
  useEffect(() => {
    getCrmPurchase(filter, setData);
  }, [filter, reload]);

  const [manager, setManager] = useState(false);
  const roles = useSelector((state) => state.roles.roles);

  useEffect(() => {
    getCrmManagers(null, setManager);
  }, []);

  const serverDateFormat = "YYYY-MM-DD";

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "1rem",
          background: "#fff",
          padding: "16px 24px",
          alignItems: "center",
          borderRadius: 4,
        }}
      >
        <span style={{ fontSize: "16px" }}>Top CRM Customers</span>
        <div>
          {isPermitted(roles, ["admin", "cro"]) && (
            <div>
              <Select
                placeholder="Select user"
                onSelect={(e) => {
                  let mFilter = filter ? { ...filter } : {};
                  mFilter.crm_manager_id = e;
                  setFilter(mFilter);
                }}
                style={{ width: "200px", marginRight: "8px" }}
              >
                {manager &&
                  manager.data &&
                  manager.data.map((p, k) => (
                    <Select.Option value={p.id} key={p.id}>
                      {p.name}
                    </Select.Option>
                  ))}
              </Select>
              <Button
                type="danger"
                onClick={() => {
                  setFilter(null);
                }}
              >
                Reset
              </Button>
            </div>
          )}
        </div>
      </div>
      <Card style={{ marginBottom: "0.5rem" }} size="small">
        <Row gutter={[16, 16]}>
          <Col md={{ span: 6 }}>
            <Text style={{ fontSize: "16px" }}>Search By Customer Phone</Text>
            <Search
              placeholder="Customer Phone Number"
              onSearch={(value) => {
                if (value) {
                  let prev = filter ? { ...filter } : {};
                  prev.phone = value;
                  setFilter(prev);
                } else {
                  let prev = filter ? { ...filter } : {};
                  setFilter(prev);
                }
              }}
              allowClear
              enterButton
              style={{
                width: "100%",
                marginBottom: "1rem",
                marginTop: "0.5rem",
              }}
            />
          </Col>
          <Col md={{ span: 6 }}>
            <Text style={{ fontSize: "16px" }}>Search By Order ID</Text>
            <Search
              placeholder="Order ID"
              onSearch={(value) => {
                if (value) {
                  let prev = filter ? { ...filter } : {};
                  prev.order_code = value;
                  setFilter(prev);
                } else {
                  let prev = filter ? { ...filter } : {};
                  setFilter(prev);
                }
              }}
              allowClear
              enterButton
              style={{
                width: "100%",
                marginBottom: "1rem",
                marginTop: "0.5rem",
              }}
            />
          </Col>
          <Col md={{ span: 6 }}>
            <Text style={{ fontSize: "16px" }}>Date Range</Text>
            <br />
            <DatePicker.RangePicker
              style={{ marginTop: "0.5rem", width: "100%" }}
              format="DD/MM/YYYY"
              onChange={(e) => {
                let fromDate = new Date(e[0]);
                let toDate = new Date(e[1]);
                let mFilter = { ...filter };
                mFilter.from = moment(fromDate).format(serverDateFormat);
                mFilter.to = moment(toDate).format(serverDateFormat);
                setFilter(mFilter);
              }}
            />
          </Col>
        </Row>
      </Card>
      <PurchasingStats filter={filter} crm={true} />
      {data && (
        <PurchaseCustomerList
          customers={data}
          users={manager}
          type={isPermitted(roles, ["admin"]) ? "cpm" : ""}
          fromCrm={true}
          pageNum={1}
          tab={1}
          setRecalculate={setReload}
        />
      )}
    </div>
  );
}
