import React, { useState, useEffect } from "react";
import { Table, Typography, Tag, DatePicker } from "antd";
import Spinner from "../components/Spinner";
import { getCrmReport } from "../api/api";
import moment from "moment";
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  FormOutlined,
} from "@ant-design/icons";
const { Column } = Table;
const { Text } = Typography;

export default function CrmReport() {
  const [data, setData] = useState(null);
  const [filter, setFilter] = useState(
    `year=${new Date().getFullYear()}&month=${new Date().getMonth() + 1}`
  );
  const [selected, setSelected] = useState(1);

  useEffect(() => {
    window.scrollTo({ top: 0 });
    getCrmReport(filter, setData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  return (
    <div>
      <div style={{ display: "flex", marginBottom: "1rem" }} className="tagTab">
        <Tag
          style={{
            padding: "0.5rem 1rem",
            color: "black",
            cursor: "pointer",
            fontSize: "14px",
          }}
          onClick={() => {
            let date = new Date();
            setSelected(1);
            setFilter(
              `year=${date.getFullYear()}&month=${date.getMonth() + 1}`
            );
          }}
          color={selected === 1 ? "rgb(237, 212, 143)" : "white"}
        >
          This Month
        </Tag>
        <Tag
          style={{
            padding: "0.5rem 1rem",
            color: "black",
            cursor: "pointer",
            fontSize: "14px",
          }}
          color={selected === 2 ? "rgb(237, 212, 143)" : "white"}
          onClick={() => {
            setSelected(2);
            let date = new Date();
            let year = date.getFullYear();
            let month = date.getMonth();
            if (month === 0) {
              year = year - 1;
              month = 12;
            }
            setFilter(`year=${year}&month=${month}`);
          }}
        >
          Last Month
        </Tag>
        <Tag
          style={{
            padding: "0.5rem 1rem",
            color: "black",
            cursor: "pointer",
            fontSize: "14px",
          }}
          color={selected === 3 ? "rgb(237, 212, 143)" : "white"}
          onClick={() => {
            setSelected(3);
            let date = new Date();
            let year = date.getFullYear();
            setFilter(`year=${year}`);
          }}
        >
          This Year
        </Tag>
        <Tag
          style={{
            padding: "0.5rem 1rem",
            color: "black",
            cursor: "pointer",
            fontSize: "14px",
          }}
          color={selected === 4 ? "rgb(237, 212, 143)" : "white"}
          onClick={() => {
            setSelected(4);
            let date = new Date();
            let year = date.getFullYear() - 1;
            setFilter(`year=${year}`);
          }}
        >
          Last Year
        </Tag>
        <DatePicker
          picker="month"
          style={{ marginLeft: "1rem", width: "300px" }}
          format="MMMM/YYYY"
          onChange={(e) => {
            setSelected(5);
            let date = new Date(e);
            setFilter(
              `year=${date.getFullYear()}&month=${date.getMonth() + 1}`
            );
          }}
        />
      </div>
      <div>
        {data ? (
          <div>
            <Table
              dataSource={data.filter((el) => el.target !== null)}
              rowKey={(record) => record.filled?.crm_manager_id}
              pagination={false}
              bordered
            >
              <Column
                title="Month"
                dataIndex="date"
                render={(text, record, index) => (
                  <Text>
                    {moment.monthsShort(record.target?.month - 1) +
                      ", " +
                      record.target?.year}
                  </Text>
                )}
              />
              <Column
                title="Employee"
                dataIndex="date"
                align="center"
                render={(text, record, index) => (
                  <Text>{record.target?.admin?.name}</Text>
                )}
              />
              <Column
                title="Target"
                key="order"
                align="center"
                render={(text, record) => (
                  <div style={{}}>
                    <Tag
                      color={"magenta"}
                      style={{ fontSize: "12px", fontWeight: 500 }}
                    >
                      ৳ {parseInt(record.target.amount)?.toLocaleString()}
                    </Tag>
                  </div>
                )}
              />
              <Column
                title="Filled"
                key="order"
                align="center"
                render={(text, record) => (
                  <div style={{}}>
                    <Tag
                      color={"green"}
                      style={{ fontSize: "12px", fontWeight: 500 }}
                    >
                      ৳{" "}
                      {parseInt(
                        record.target?.filled_amount
                          ? record.target.filled_amount
                          : record.filled?.sub_total
                          ? record.filled?.sub_total
                          : 0
                      )?.toLocaleString()}
                    </Tag>
                  </div>
                )}
              />
              <Column
                title="Remaining"
                key="order"
                align="center"
                render={(text, record) => (
                  <div style={{}}>
                    <Tag
                      color={"volcano"}
                      style={{ fontSize: "12px", fontWeight: 500 }}
                    >
                      ৳{" "}
                      {parseInt(
                        parseInt(record.target.amount) -
                          parseInt(
                            record.target?.filled_amount
                              ? record.target.filled_amount
                              : record.filled?.sub_total
                              ? record.filled?.sub_total
                              : 0
                          )
                      )?.toLocaleString()}
                    </Tag>
                  </div>
                )}
              />

              <Column
                title="Order Count"
                key="order"
                align="center"
                render={(text, record) => (
                  <div style={{}}>
                    <Tag
                      color={"volcano"}
                      style={{ fontSize: "12px", fontWeight: 500 }}
                    >
                      {parseInt(
                        record.filled?.order_count
                          ? record.filled?.order_count
                          : 0
                      )}
                    </Tag>
                  </div>
                )}
              />
              <Column
                title="All Cus."
                key="order"
                align="center"
                render={(text, record) => (
                  <div style={{}}>
                    <Text
                      color={"#237804"}
                      style={{ fontSize: "14px", fontWeight: 500 }}
                    >
                      {parseInt(
                        record.active_user_count + record.inactive_user_count
                      )?.toLocaleString()}
                    </Text>
                  </div>
                )}
              />
              <Column
                title="Active Cus."
                key="order"
                align="center"
                render={(text, record) => (
                  <div style={{}}>
                    <Text
                      color={"#237804"}
                      style={{ fontSize: "14px", fontWeight: 500 }}
                    >
                      {parseInt(
                        record.active_user_count ? record.active_user_count : 0
                      )?.toLocaleString()}
                    </Text>
                  </div>
                )}
              />
              <Column
                title="Inactive Cus."
                key="order"
                align="center"
                render={(text, record) => (
                  <div style={{}}>
                    <Text
                      color={"#888"}
                      style={{ fontSize: "14px", fontWeight: 500 }}
                    >
                      {parseInt(
                        record.inactive_user_count
                          ? record.inactive_user_count
                          : 0
                      )?.toLocaleString()}
                    </Text>
                  </div>
                )}
              />
              <Column
                title="Disconnected Cus."
                key="order"
                align="center"
                render={(text, record) => (
                  <div style={{}}>
                    <Text
                      color={"#000"}
                      style={{ fontSize: "14px", fontWeight: 500 }}
                    >
                      {parseInt(
                        record.disconnected_user_count
                          ? record.disconnected_user_count
                          : 0
                      )?.toLocaleString()}
                    </Text>
                  </div>
                )}
              />
              <Column
                title="Incentive"
                key="order"
                align="center"
                render={(text, record) => (
                  <div style={{}}>
                    {record.filled?.incentive && (
                      <Text style={{ fontSize: "12px", fontWeight: 500 }}>
                        {parseInt(
                          record.filled?.incentive
                            ? record.filled?.incentive
                            : 0
                        )}
                      </Text>
                    )}
                  </div>
                )}
              />
              <Column
                title="Incentive Given"
                key="order"
                align="center"
                render={(text, record) => (
                  <div>
                    {record.filled.incentive_given ? (
                      <CheckCircleOutlined
                        style={{ color: "green", fontSize: "18px" }}
                      />
                    ) : (
                      <ClockCircleOutlined
                        style={{ color: "orangered", fontSize: "18px" }}
                      />
                    )}
                  </div>
                )}
              />
            </Table>
          </div>
        ) : (
          <Spinner />
        )}
      </div>
    </div>
  );
}
